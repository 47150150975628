import { SystemCategory } from 'api/system/monitoringTypes';

const userRoutes = {
  account: (account_id: string) => `/api/v1/accounts/${account_id}/`,
  authCopyAck: (agreement_id: string) => `/api/v1/agreements/${agreement_id}/auth_copy_ack/`,
  addAgreement: '/api/v1/platform/add_agreement/',
  addWorkflows: '/api/v1/platform/add_agreements_by_workflow_id/',
  agreement: (agreement_id: string) => `/api/v1/agreements/${agreement_id}/`,
  generateTpvUrl: (agreement_id: string) => `/api/v1/answernet/agreements/${agreement_id}/get_url`,
  getTpvPreviewUrls: (agreement_id: string) =>
    `/api/v1/answernet/agreements/${agreement_id}/get_preview_urls`,
  getCurrentTpvStatus: (agreement_id: string) =>
    `/api/v1/answernet/agreements/${agreement_id}/status`,
  agreements: '/api/v1/agreements/',
  agreementStatistics: '/api/v1/agreements/statistics/',
  backfillWorkflows: '/api/v1/agreements/backfill_workflows/',
  changeEmail: '/api/v1/account/change_email/',
  changePassword: '/api/v1/account/change_password/',
  claimToken: '/api/v1/account/claim_token/',
  claimUserAccount: '/api/v1/account/claim_user_account/',
  contract: (agreement_id: string) => `/api/v1/agreements/${agreement_id}/contract/`,
  energySummary: (system_id: string) => `/api/v1/systems/${system_id}/solar/energy/summary/`,
  faq: (faq_id: string) => `/api/v1/faqs/${faq_id}/`,
  faqTag: (faq_id: string) => `/api/v1/faqs/${faq_id}/tags/`,
  faqs: '/api/v1/faqs/',
  featureFlags: '/api/v1/feature_flags/',
  featureFlag: (featureFlagId: string) => `/api/v1/feature_flags/${featureFlagId}/`,
  forgotPassword: '/api/v1/account/forgot_password/',
  embeddedSigningUrl: '/api/v1/docusign/ach_enrollment_form/get_embedded_signing_url',
  homeownerContractId: (homeowner_contract_id: string) =>
    `/api/v1/agreements?search=${homeowner_contract_id}`,
  impersonate: '/api/v1/account/impersonate/',
  invite: '/api/v1/users/invite/',
  login: '/api/v1/account/login/',
  logout: '/api/v1/account/logout/',
  me: '/api/v1/users/me/',
  powerFlow: (system_id: string) => `/api/v1/systems/${system_id}/monitoring/power_flow/`,
  solarPower: (system_id: string) => `/api/v1/systems/${system_id}/solar/power/`,
  powerWithAllocation: (system_id: string, category: SystemCategory) =>
    `/api/v1/systems/${system_id}/${category}/power/`,
  solarEnergy: (system_id: string) => `/api/v1/systems/${system_id}/solar/energy/`,
  energyWithAllocation: (system_id: string, category: SystemCategory) =>
    `/api/v1/systems/${system_id}/${category}/energy/`,
  homeEnergy: (system_id: string) => `/api/v1/systems/${system_id}/home/energy/`,
  gridEnergy: (system_id: string) => `/api/v1/systems/${system_id}/grid/energy/`,
  batteryEnergy: (system_id: string) => `/api/v1/systems/${system_id}/battery/energy/`,
  vppDetails: (system_id: string) => `/api/v1/systems/${system_id}/vpp_details/`,
  siteTimezone: (system_id: string) => `/api/v1/systems/${system_id}/site_timezone/`,
  resetPassword: '/api/v1/account/reset_password/',
  sendClaimAccountEmail: '/api/v1/users/send_claim_account_email/',
  system: (system_id: string) => `/api/v1/systems/${system_id}/`,
  uploadAuthCopyAckCsv: '/api/v1/upload_auth_copy_ack_csv/',
  userStatistics: '/api/v1/users/statistics/',
  tags: '/api/v1/tags/',
  unimpersonate: '/api/v1/account/unimpersonate/',
  user: (user_id: string) => `/api/v1/users/${user_id}/`,
  users: '/api/v1/users/',
  verifyInviteToken: '/api/v1/account/verify_token/',
  welcomeChecklist: (agreement_id: string) =>
    `/api/v1/agreements/${agreement_id}/welcome_checklist/`,
  workflowId: (workflow_id: string) => `/api/v1/agreements?search=${workflow_id}`,
  banners: '/api/v1/messaging/banners',
  userComment: '/api/v1/support/user_comment/',
  sendAccessCode: '/api/v1/account/send_access_code/',
  verifyAccessCode: '/api/v1/account/verify_access_code/',
  setPasswordWithAccessCode: '/api/v1/account/set_password_with_access_code/',
  transferAccounts: (account_id: string) => `/api/v1/accounts/${account_id}/transfer/`,
  inboxMessages: (user_id: string) => `/api/v1/users/${user_id}/inbox_messages/`,
  inboxMessage: (user_id: string, message_id: string) =>
    `/api/v1/users/${user_id}/inbox_messages/${message_id}/`,
  markAllMessagesRead: (user_id: string) =>
    `/api/v1/users/${user_id}/inbox_messages/mark_all_as_read/`,
};

const oauthRoutes = {
  authorize: '/oauth2/authorize/',
  checkAuthorizeParams: '/oauth2/check_authorize_params/',
};

export const routes = {
  ...userRoutes,
  ...oauthRoutes,
};

export default routes;
