import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  VppCardTotalEarnedTextWrapper,
  VppCardTotalEarnedValue,
  VppCardTotalEarnedValueSuffix,
} from './VppCardTotalEarnedText.styles';

export default function VppCardTotalEarnedText({ value }: { value: string }) {
  const { t } = useTranslation();
  return (
    <VppCardTotalEarnedTextWrapper>
      <VppCardTotalEarnedValue as="h2">${value}</VppCardTotalEarnedValue>

      <VppCardTotalEarnedValueSuffix as="h5">
        {t('overview.virtualPowerPlantCard.earned')}
      </VppCardTotalEarnedValueSuffix>
    </VppCardTotalEarnedTextWrapper>
  );
}
