import React from 'react';
import { useTheme } from 'styled-components';

import { TabControlsWrapper, TabStyled, TabText } from './TabControls.styles';
import { IconProps } from '../icons/types';

export type Tab = {
  label: string;
  Icon: React.FC<IconProps & { filled: boolean }>;
  styleVariant: 'blue' | 'green' | 'magenta' | 'dusk';
  isActive: boolean;
  onClick: () => void;
};

type Props = {
  tabs: Tab[];
};

export function TabControls({ tabs }: Props) {
  const theme = useTheme();

  return (
    <TabControlsWrapper>
      {tabs.map((tab) => {
        const { Icon, label, isActive, styleVariant, onClick } = tab;
        return (
          <TabStyled
            key={label}
            $styleVariant={styleVariant}
            $isActive={isActive}
            onClick={onClick}
          >
            <Icon
              color={
                isActive
                  ? theme.colors.backgroundPrimaryWhite
                  : theme.colors.backgroundAccentBlueStrongestSecondary1100
              }
              filled={isActive}
            />
            <TabText as="p" $isActive={isActive}>
              {label}
            </TabText>
          </TabStyled>
        );
      })}
    </TabControlsWrapper>
  );
}

export default TabControls;
