import styled from "styled-components";
import {Text} from "shared/components/Text/Text";

export const YAxisLabelStyled = styled.div`
    align-self: flex-end;
`

export const YAxisLabelTitle = styled(Text)<{$color: string}>`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${({$color}) => $color };
`

export const YAxisLabelSubTitle = styled(Text)`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    color: ${({theme}) => theme.colors.textSecondarySubtleGrey80 };
`