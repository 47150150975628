import styled from 'styled-components';

export const MenuBar = styled.div``;

export const LayoutContent = styled.div<{ $isAuthScreen?: boolean }>`
  ${({ theme, $isAuthScreen }) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 16px 0;
    background: ${
      $isAuthScreen ? theme.colors.backgroundSecondaryGrey05 : theme.colors.cardElevationLowWhite
    };

    @media screen and (min-width: ${theme.breakpoints.small}) {
      padding: 26px 24px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      padding: 34px 32px 0;
      border-radius: 24px 0 0 24px;
      box-shadow: ${theme.elevations.mid};
    }

    @media screen and (min-width: ${theme.breakpoints.large}) {
      padding: 44px 48px ${$isAuthScreen ? '24px' : '0'};
    }
  `}
`;

export const LayoutWrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  min-height: 100vh;
  height: 100%;
  padding: 0;
  max-width: 1920px;
  margin-right: auto;
  @media (width < ${theme.breakpoints.small}) {
    flex-direction: column;
    ${MenuBar} {
      height: 66px;
      min-width: 100%;
      order: 999;
    }
  }
  @media (${theme.breakpoints.small} <= width < ${theme.breakpoints.medium}) {
    flex-direction: column;
    ${MenuBar} {
      height: 74px;
      min-width: 100%;
      order: 999;
    }
  }
  @media (${theme.breakpoints.medium} <= width < ${theme.breakpoints.large}) {
    ${MenuBar} {
      min-width: 204px;
      min-height: 100%;
    }
  }
  @media (${theme.breakpoints.large} <= width) {
    ${MenuBar} {
      width: 220px;
      min-height: 100%;
    }
  }
    `}
`;

export const LayoutContentInner = styled.div<{ $isAuthScreen?: boolean }>`
  max-width: ${({ $isAuthScreen }) => ($isAuthScreen ? 'unset' : '1124px')};
`;
