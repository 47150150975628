import Text, { FONT_FAMILY } from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';

export const CHART_WRAPPER_MAX_WIDTH = 734;
export const TOOLTIP_CONTAINER_PADDING = 8;

export const ChartWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    padding: 16px;
    margin: 0 -1rem; // accounts for 1rem padding on body tag. needs to be full width
    background: ${theme.colors.backgroundSecondaryGrey05};
    box-shadow: ${theme.elevations.extraSunken};
    gap: 16px;
    
    @media screen and (min-width: ${ theme.breakpoints.small}) {
    padding: 24px;
    border-radius: 24px;
    margin: 0;
    gap: 24px;
    }

    @media screen and (min-width: ${ theme.breakpoints.large}) {
      max-width: ${CHART_WRAPPER_MAX_WIDTH}px;
    }
  `}
`;

export const SpinnerContainer = styled.div`
  ${({ theme }) => `
    display: flex;

    @media (width >= ${theme.breakpoints.medium}) {
      width: 768px;
      height: 400px;
    }
    @media (${theme.breakpoints.small} <= width < ${theme.breakpoints.medium}) {
      width: 676px;
      height: 345px;
    }
    @media (width < ${theme.breakpoints.small}) {
      width: 90vw;
      height: 250px;
    }
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitle = styled.p`
  ${({ theme }) => `
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.textPrimarySecondary1100};
    margin-left: 9px;
    font-family: ${FONT_FAMILY};
  `}
`;

export const ToolTipkW = styled(Text)<{$color?: string}>`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
  color: ${({theme, $color}) => $color || theme.colors.chartChartBlueSecondary2100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const TooltipTime = styled(ToolTipkW)`
  ${({ theme }) => `
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.colors.textSecondarySubtleGrey80};
  `}
`;

export const ToolTipContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    height: 56px;
    @media (width < ${theme.breakpoints.medium}) {
      height: 52px;
    }
  `}
`;

export const ToolTipTextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: ${TOOLTIP_CONTAINER_PADDING}px;
    width: fit-content;
    height: fit-content;
    background: ${theme.colors.cardElevationMidWhite};
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    box-shadow: ${theme.elevations.high};
    border-radius: 8px;

    @media (width <= ${theme.breakpoints.medium}) {
      ${TooltipTime} {
        font-size: 12px;
        line-height: 16px;
      }
    }
  `}
`;

export const Line = styled.line`
  ${({ theme }) => `
    stroke: ${theme.colors.componentStrongEmphasisGrey};
    stroke-linecap: round;
  `}
`;
