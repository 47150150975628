import { Sizes } from 'shared/design-system/theme/grid';
import { TooltipPosition } from '../components/Charts/Tooltip/ToolTip';
import {
  CHART_WRAPPER_MAX_WIDTH,
  TOOLTIP_CONTAINER_PADDING,
} from '../components/Charts/Chart.styles';
import { LABEL_VALUE_CONTAINER_GAP } from '../components/Charts/Tooltip/ToolTip.styles';

interface TooltipPositionProps {
  screenSize: Sizes;
  dataPosition: TooltipPosition;
  chartType: 'area' | 'bar';
  hasLabels: boolean;
  tooltipCharWidth: number;
}

type CoordinateModifiers = Record<Sizes, TooltipPosition>;

export const TOOLTIP_STANDARD_CHARS = 7;

export const getTooltipPosition = ({
  screenSize,
  dataPosition,
  chartType,
  hasLabels,
  tooltipCharWidth,
}: TooltipPositionProps): TooltipPosition => {
  const { x: currentX, y: currentY } = dataPosition;

  /** Determine default tooltip positioning */
  const defaultPosition = chartType === 'area' ? { x: -108, y: -30 } : { x: -116, y: -30 };

  /**
   * Tooltip is repositioned depending on how close it gets to a particular
   * edge of the graph (x = 0, y = 0 is the top left corner)
   */
  const maxCoordinates: CoordinateModifiers =
    chartType === 'area'
      ? {
          xl: { x: 808, y: 374 },
          lg: { x: 808, y: 374 },
          md: { x: 685, y: 320 },
          sm: { x: 285, y: 215 },
        }
      : {
          xl: { x: 808, y: 374 },
          lg: { x: 808, y: 374 },
          md: { x: 690, y: 350 },
          sm: { x: 326, y: 224 },
        };

  /** Determine how far to nudge the tooltip box when it approaches a chart boundary */
  const boundaryOffsetValues: CoordinateModifiers =
    chartType === 'area'
      ? {
          xl: { x: 128, y: 20 },
          lg: { x: 128, y: 20 },
          md: { x: 128, y: 15 },
          sm: { x: 128, y: 15 },
        }
      : {
          xl: { x: 134, y: 20 },
          lg: { x: 134, y: 20 },
          md: { x: 134, y: 15 },
          sm: { x: 134, y: 15 },
        };

  const tooltipPosition = {
    x: currentX,
    y: currentY ? currentY + defaultPosition.y : currentY,
  };

  const halfOfChart = screenSize === 'sm' ? 150 : CHART_WRAPPER_MAX_WIDTH / 2;
  let tooltipAndVerticalLineSpacing = 9;

  tooltipPosition.x += tooltipAndVerticalLineSpacing * 2;

  const estimatedTooltipWidth =
    TOOLTIP_CONTAINER_PADDING * 2 +
    LABEL_VALUE_CONTAINER_GAP[screenSize] +
    tooltipCharWidth * TOOLTIP_STANDARD_CHARS;

  // flip the tooltip when we get halfway to the chart
  if (currentX > halfOfChart) {
    if (hasLabels) {
      tooltipAndVerticalLineSpacing = screenSize === 'lg' || screenSize === 'xl' ? 40 : 16;
    }
    tooltipPosition.x = currentX - estimatedTooltipWidth - tooltipAndVerticalLineSpacing;
  }

  // tooltip moves above or below normal position if too close to y-axis chart boundary
  if (tooltipPosition.y) {
    const yLowThreshold = chartType === 'area' ? 30 : 50;

    if (tooltipPosition.y < yLowThreshold) {
      tooltipPosition.y += boundaryOffsetValues[screenSize].y;
    } else if (tooltipPosition.y > maxCoordinates[screenSize].y - 50) {
      tooltipPosition.y -= boundaryOffsetValues[screenSize].y;

      if (tooltipPosition.y > 330 && screenSize !== 'sm') {
        tooltipPosition.y = 330;
      }

      if (tooltipPosition.y > 150 && screenSize === 'sm') {
        tooltipPosition.y = 150;
      }
    }
  }

  return { ...tooltipPosition };
};

export default getTooltipPosition;
