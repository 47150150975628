import { SystemCategory } from 'api/system/monitoringTypes';
import { SystemType } from 'api/types';

export const getCategoriesEnabled = ({
  battery,
  grid,
  home,
  solar,
  system,
}: {
  battery: boolean;
  grid: boolean;
  home: boolean;
  solar: boolean;
  system?: Partial<SystemType> | null;
}): SystemCategory[] => {
  if (!system) {
    return [];
  }
  const categories = [];
  if (solar && system.has_solar_monitoring) {
    categories.push(SystemCategory.SOLAR);
  }
  if (battery && system.has_battery_monitoring) {
    categories.push(SystemCategory.BATTERY);
  }
  if (grid && system.has_grid_monitoring) {
    categories.push(SystemCategory.GRID);
  }
  if (home && system.has_ct && system.has_home_monitoring) {
    categories.push(SystemCategory.HOME);
  }
  return categories;
};

export default getCategoriesEnabled;
