import { TTheme } from '../theme';
import { TTabs, TTabStyleVariantsKey } from './TTabs';

// RETURNS ALL STYLES RELATED TO TAB STYLE VARIANT
export const getTabStyleVariantValues = (
  theme: TTheme,
  themeTabs: (theme: TTheme) => TTabs,
  variant: TTabStyleVariantsKey,
  state: 'active' | 'inactive',
) => ({
  ...themeTabs(theme).variants[variant][state],
  ...themeTabs(theme).variants[variant],
});

export default getTabStyleVariantValues;
