import React from 'react';
import Button from 'shared/components/Button/Button';
import { FooterStyled } from './MessageCenterFooter.styles';

type FooterProps = {
  buttonText: string;
  handleButtonClick: () => void;
  scrolledToBottom: boolean;
};

export function MessageCenterFooter({
  buttonText,
  handleButtonClick,
  scrolledToBottom,
}: FooterProps) {
  return (
    <FooterStyled $scrolledToBottom={scrolledToBottom}>
      <Button styleVariant="secondary" onClick={handleButtonClick} isFullWidth label={buttonText} />
    </FooterStyled>
  );
}
export default MessageCenterFooter;
