import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useBreakpoints } from 'hooks/useWindowSize';
import { DateRange } from 'api/system/utils/DateRange';
import { SystemCategory } from 'api/system/monitoringTypes';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { ChartDataType } from 'modules/types';
import { PowerUnit, SystemType } from 'api/types';
import { useGetPowerWithAllocation } from 'api/system';
import { AnyChartDataProvider } from 'modules/system/utils/ChartDataProviders/AnyChartDataProvider';
import { AreaChart } from './AreaChart';
import { SpinnerContainer } from '../Chart.styles';
import { ChartDataError, DisplayChartError } from '../../ChartDataError/ChartDataError';
import { YAxisLabel } from '../YAxisLabel/YAxisLabel';

type Props = {
  dateRange: DateRange;
  system: SystemType;
  category: SystemCategory;
  dataProvider: AnyChartDataProvider;
  isTimezoneError: boolean;
  onHasChartError: (error: boolean) => void;
}

export function DayChart({ dateRange, system, category, dataProvider,isTimezoneError, onHasChartError }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const [yAxisUnit, setYAxisUnit] = useState<PowerUnit>('W');
  const [largestProductionValue, setLargestProductionValue] = useState<number | null>(null);
  
  const { t } = useTranslation();
  const theme = useTheme();
  const screenSize = useBreakpoints() || 'md';

  const { zoomLevel, startDate } = dateRange;
  const isChartDataEmpty = chartData && chartData.length === 0;
  
  const { data, isFetching, isError, error, refetch } = useGetPowerWithAllocation({systemId: system?.id, category, dateRange})

  useEffect(() => {
    if (!isFetching && data && Object.keys(data).length) {
      onHasChartError(false);
      const monitoringData = data.monitoring_data;

      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] = dataProvider.prepareData(
        monitoringData,
        dateRange,
      );
      setLargestProductionValue(_largestProductionValue || 0.0);
      setChartData(productionData);
      setYAxisUnit(_yAxisEnergyUnit as PowerUnit);
    }
  }, [startDate, zoomLevel, dataProvider, dateRange, isFetching, data, onHasChartError]);

  useEffect(() => {
    if (isError || isChartDataEmpty) {
      onHasChartError(true)
    }
  }, [isChartDataEmpty, isError, onHasChartError])

  let errorMessage: DisplayChartError | null =  null

  if (!isFetching ) {
    if (isChartDataEmpty || isTimezoneError ) {
      errorMessage = DisplayChartError.TRY_AGAIN;
    }
    
    if (isError) {
    if (error.response?.status === 531) {
      errorMessage = DisplayChartError.SITE_NOT_FOUND;
    } else if (error.response?.status === 400 || isChartDataEmpty) {
      errorMessage = DisplayChartError.TRY_AGAIN;
    } else {
      errorMessage = DisplayChartError.CONTACT_SUPPORT;
    }   
   }
  }
  
  const chartTheme = dataProvider.getTheme(theme.colors)
  const yAxisLabels = dataProvider.yAxisLabels && dataProvider.yAxisLabels(t);

  return (
    <>
    {errorMessage && (
      <ChartDataError error={errorMessage} onClickTryAgain={refetch}/>
      )}

      {!errorMessage &&
        (largestProductionValue !== null && !isFetching ? (
          <>
          {yAxisLabels && <YAxisLabel labels={yAxisLabels.top} titleColor={chartTheme.yAxisTitle} />}

          <AreaChart
            data={chartData}
            axes={{
              x: dataProvider.getXAxis(dateRange),
              y: dataProvider.getYAxis(system?.capacity || 0, yAxisUnit, screenSize),
            }}
            tooltipDateFormatter={dataProvider.tooltipDateFormatter}
            dateRange={dateRange}
            yAxisUnit={yAxisUnit}
            chartTheme={chartTheme}
            category={category}
            flyoutLabels={dataProvider.getFlyoutLabels}
          />
         
         {yAxisLabels && <YAxisLabel labels={yAxisLabels.bottom} titleColor={chartTheme.yAxisTitle} />}
          </>
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default DayChart;
