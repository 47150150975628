import styled from 'styled-components';

export const TableCellButtonStyled = styled.button`
  display: inline-block;
  position: relative;
  background: none;
  width: 100%;
  border: none;
  cursor: pointer;
`;

export default TableCellButtonStyled;
