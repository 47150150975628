/* eslint-disable class-methods-use-this */
import { differenceInCalendarDays } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { EnergyAllocationType, MonitoringDatum } from 'api/types';
import { AggregatedData } from 'api/system/types';
import { HomeChartDataProvider } from './HomeChartDataProvider';
import { MonthChartDataProvider } from '../MonthChartDataProvider';
import { AnyChartDataProvider } from '../AnyChartDataProvider';

export class HomeMonthChartDataProvider
  extends HomeChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 30.0;

  t: (key: string) => string;

  constructor(t: (key: string) => string) {
    super();
    this.t = t;
  }

  barWidth = MonthChartDataProvider.barWidth;

  shouldShowDateLabel = MonthChartDataProvider.shouldShowDateLabel;

  getDomain = (
    dateRange: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => {
    const monthOfFirstDate = dateRange.startDate.getMonth();
    const daysInMonth = new Date(
      dateRange.startDate.getFullYear(),
      monthOfFirstDate + 1,
      0,
    ).getDate();
    return {
      x: [0, daysInMonth - 1],
      y: [0, maximumValue || HomeMonthChartDataProvider.placeholderMaximumValue],
    };
  };

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData
      // data sanitization: backend has yet to filter out days with negative values
      .filter(({ value }) => value && value > 0.0)
      .forEach(({ value, time, unit }) => {
        if (!value) {
          return;
        }
        largestProductionValue = Math.max(largestProductionValue, value);
        if (value === largestProductionValue) {
          largestProductionUnit = unit;
        }
        const dateNumber = differenceInCalendarDays(time, dateRange.startDate);
        if (dateNumber >= 0) {
          dailyProduction.set(dateNumber, { time, y: value, unit });
        }
      });
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  tickXFormat = (day: number, range: DateRange) =>
    MonthChartDataProvider.tickXFormat(day, range, this.shouldShowDateLabel, this.t);

  domainPaddingX: PaddingType = MonthChartDataProvider.domainPaddingX;

  yTickLabelOffset = MonthChartDataProvider.yTickLabelOffset;

  getTickValuesXAxis = MonthChartDataProvider.getTickValuesXAxis;

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = HomeChartDataProvider.homeGetTickValuesYAxis;

  getYAxis = <T>(maximumValue: number | null, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = MonthChartDataProvider.tooltipDateFormatter;

  total = (t: (key: string) => string, allocation: EnergyAllocationType) => ({
    label: t('system.summary.categories.HOME.total_consumption'),
    value: allocation.consumption_total_wh,
  });
}

export default HomeMonthChartDataProvider;
