import React from 'react';

export function SparklesIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        opacity="0.4"
        d="M15 4.5C15 4.725 15.1406 4.92656 15.3516 5.00625L18 6L18.9938 8.64844C19.0734 8.85938 19.275 9 19.5 9C19.725 9 19.9266 8.85938 20.0062 8.64844L21 6L23.6484 5.00625C23.8594 4.92656 24 4.725 24 4.5C24 4.275 23.8594 4.07344 23.6484 3.99375L21 3L20.0062 0.351562C19.9266 0.140625 19.725 0 19.5 0C19.275 0 19.0734 0.140625 18.9938 0.351562L18 3L15.3516 3.99375C15.1406 4.07344 15 4.275 15 4.5ZM15 19.5C15 19.725 15.1406 19.9266 15.3516 20.0062L18 21L18.9938 23.6484C19.0734 23.8594 19.275 24 19.5 24C19.725 24 19.9266 23.8594 20.0062 23.6484L21 21L23.6484 20.0062C23.8594 19.9266 24 19.725 24 19.5C24 19.275 23.8594 19.0734 23.6484 18.9938L21 18L20.0062 15.3516C19.9266 15.1406 19.725 15 19.5 15C19.275 15 19.0734 15.1406 18.9938 15.3516L18 18L15.3516 18.9938C15.1406 19.0734 15 19.275 15 19.5Z"
        fill="#00B5B4"
      />
      <path
        id="Vector_2"
        d="M9.61406 3.43594C9.49219 3.16875 9.225 3 8.93438 3C8.64375 3 8.37656 3.16875 8.25469 3.43594L5.78438 8.78437L0.435938 11.25C0.16875 11.3719 0 11.6391 0 11.9344C0 12.2297 0.16875 12.4922 0.435938 12.6141L5.78438 15.0844L8.25 20.4281C8.37187 20.6953 8.63906 20.8641 8.92969 20.8641C9.22031 20.8641 9.4875 20.6953 9.60938 20.4281L12.0797 15.0797L17.4281 12.6094C17.6953 12.4875 17.8641 12.2203 17.8641 11.9297C17.8641 11.6391 17.6953 11.3719 17.4281 11.25L12.0844 8.78437L9.61406 3.43594Z"
        fill="#00B5B4"
      />
    </svg>
  );
}

export default SparklesIcon;
