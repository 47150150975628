import React, { useMemo, useState } from 'react';
import { resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js/types';
import { AxiosError } from 'axios';

import { updateAgreement, useGenerateTpvUrl, useGetTpvPreviewUrls } from 'api/agreement';
import { HomeownerTransferModal } from 'admin/modules/agreements/modals/HomeownerTransferModal/HomeownerTransferModal';
import { updateAccount, useTransferAccount } from 'api/accounts';
import {
  AccountType,
  AgreementPreviewLink,
  AgreementType,
  ErrorResponse,
  UserFormType,
} from 'api/types';
import { useUser } from 'hooks/useUser';
import { pages } from 'pages';
import { StatusPill } from 'shared/components/StatusPill/StatusPill';
import { getUser, useImpersonateUser, useResendClaimAccountEmail, useUpdateUser } from 'api/user';
import { removeLocalStoragePerSessionItems } from 'contexts/removeLocalStorageItems';
import { toast } from 'shared/components/Toast/Toast';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { Header } from 'shared/components/Header/Header';
import { Button } from 'shared/components/Button/Button';
import { EditUserModal, IFormInput } from '../components/EditUserModal/EditUserModal';
import {
  EmailHeader,
  CommunicationsTableWrapper,
  FlexColumn,
  RightContent,
  SectionTitle,
  TransferCards,
  UserProfileContent,
} from './UserProfile.styles';
import { AgreementsAccordion } from '../components/AgreementsAccordion/AgreementsAccordion';
import { EmailsTable } from '../components/EmailsTable/EmailsTable';
import { UserInfoCard } from '../components/UserInfoCard/UserInfoCard';
import { ActivityCard } from '../components/ActivityCard/ActivityCard';
import { HomeownerTransferCard } from '../components/HomeownerTransferCards/HomeownerTransferCard';
import { isImpersonateOnly } from '../../../../shared/utils/checkUserRole';
import { NotificationsTable } from '../components/NotificationsTable/NotificationsTable';

export function UserProfile() {
  const [editUser, setEditUser] = useState(false);
  const [transferAccount, setTransferAccount] = useState<AccountType | null>();

  const { userId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const { setUserResult } = useUser();

  const {
    data: user,
    isLoading,
    refetch: refetchUser,
  } = useQuery(['user', userId], () => getUser(userId!).then((response) => response.data), {
    enabled: Boolean(userId),
  });

  const { mutate: impersonate } = useImpersonateUser({
    onSuccess: (data) => {
      removeLocalStoragePerSessionItems();
      setUserResult({
        user: data.user,
        selectedAgreement: data.user?.agreements[0],
        impersonator: data.impersonator,
      });

      navigate(`/${pages.DASHBOARD}`);
    },
    onError: (error) => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: error.message,
        theme,
      });
    },
  });

  const { mutate: generateTpvUrl } = useGenerateTpvUrl({
    onSuccess: () => {
      refetchUser();

      toast({
        type: 'success',
        title: t('toast.success'),
        message: 'Third-party verification has been reset ',
        theme,
      });
    },
    onError: (error) => {
      const errorData = error.response?.data as ErrorResponse;

      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorData.detail
          ? JSON.stringify(errorData.detail)
          : t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const { mutateAsync: getTpvPreviewUrlsAsync } = useGetTpvPreviewUrls({
    onError: (error) => {
      const errorData = error.response?.data as ErrorResponse;

      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorData.detail
          ? JSON.stringify(errorData.detail)
          : t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: () => {
      refetchUser();

      if (editUser) {
        setEditUser(false);
      }

      toast({
        type: 'success',
        title: t('toast.success'),
        message: 'Successfully updated user',
        theme,
      });
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const { mutate: sendClaimAccountEmail } = useResendClaimAccountEmail({
    onSuccess: () => {
      refetchUser();

      toast({
        type: 'success',
        title: t('toast.success'),
        message: `Claim Account Email sent to ${user?.email}`,
        theme,
      });
    },
    onError: (error) => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: error.message,
        theme,
      });
    },
  });

  const { mutate } = useTransferAccount({
    onSuccess: (data) => {
      const newHomeOwnerId = data.users[0].id;

      const userPath = pages.USER.replace(':userId', newHomeOwnerId);

      setTransferAccount(null);

      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('admin.agreementTransferred'),
        theme,
      });

      navigate(resolvePath(userPath, `/${pages.ADMIN}`));
    },
    onError: (error) => {
      const { response, message: axiosMessage } = error;
      const responseData = response?.data;
      let errorMessage: string | undefined;

      if (Array.isArray(responseData)) {
        errorMessage = responseData[0] as string;
      } else if (typeof responseData === 'string') {
        errorMessage = responseData;
      } else if (responseData?.detail) {
        errorMessage = Array.isArray(responseData.detail)
          ? responseData.detail[0]
          : responseData.detail;
      }

      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorMessage || axiosMessage,
        theme,
      });
    },
  });

  const { userResult } = useUser();
  const isReadOnly = useMemo(() => isImpersonateOnly(userResult?.user?.role), [userResult]);

  if (isLoading) {
    return <LoadingSpinner isLoading />;
  }

  const handleImpersonateUser = () => {
    if (user?.id) {
      impersonate({ user_id: user.id });
    }
  };

  const handleChangeArchiveUser = () => {
    if (user) {
      const archived = !user.archived;
      updateUser({ user_id: user.id, body: { archived } });
    }
  };

  const handleChangeArchiveAgreement = (agreement: AgreementType) => {
    const archived = !agreement.archived;
    updateAgreement(agreement.id, { archived })
      .then(() => {
        refetchUser();
      })
      .catch((error: AxiosError) => {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: error.message,
          theme,
        });
      });
  };

  const handleResetAgreementTpv = (agreement: AgreementType) => {
    if (user) {
      generateTpvUrl({ agreement_id: agreement.id, user_id: user.id });
    }
  };

  const handleFetchAgreementTpvVideo = async (
    agreement: AgreementType,
  ): Promise<AgreementPreviewLink[]> => {
    const response = await getTpvPreviewUrlsAsync({ agreement_id: agreement.id });
    return response.preview_urls;
  };

  const handleChangeArchiveAccount = (account: AccountType) => {
    const archived = !account.archived;
    updateAccount(account.id, { archived })
      .then(() => {
        refetchUser();
      })
      .catch((error: AxiosError) => {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: error.message,
          theme,
        });
      });
  };

  const handleSendClaimAccountEmail = () => {
    if (user) {
      sendClaimAccountEmail({ email: user.email });
    }
  };

  const handleUpdate = (formData: Partial<IFormInput>) => {
    if (user) {
      updateUser({ user_id: user.id, body: formData });
    }
  };

  const handleClickTransferAgreement = (new_users: UserFormType[]) => {
    if (transferAccount) {
      mutate({ account_id: transferAccount.id, body: { new_users } });
    }
  };

  const agreements = user?.agreements;

  return (
    <>
      <div>
        <Header
          pageName="USER"
          title={`${user?.first_name} ${user?.last_name}`}
          routeBreadCrumbs={[
            {
              label: 'Agreements',
              link: resolvePath(pages.AGREEMENTS, `/${pages.ADMIN}`),
            },
            { label: 'User Profile' },
          ]}
          onImpersonateUser={handleImpersonateUser}
          onChangeArchive={handleChangeArchiveUser}
          archived={user?.archived}
          canArchiveUser={!isReadOnly}
        />

        {!isReadOnly && (
          <UserProfileContent>
            <FlexColumn>
              {agreements && (
                <FlexColumn>
                  <SectionTitle as="h2">Agreements</SectionTitle>
                  <AgreementsAccordion
                    userId={user?.id}
                    agreements={agreements}
                    onChangeArchiveAgreement={handleChangeArchiveAgreement}
                    onResetAgreementTpv={handleResetAgreementTpv}
                    onFetchPreviewVideoLinks={handleFetchAgreementTpvVideo}
                  />
                </FlexColumn>
              )}

              <FlexColumn>
                <EmailHeader>
                  <SectionTitle as="h2">Emails</SectionTitle>
                  <StatusPill
                    text={user?.claimed_account ? 'Claimed' : 'Not Claimed'}
                    bgColor={
                      user?.claimed_account
                        ? 'backgroundSystemSuccessPrimary220'
                        : 'backgroundSystemErrorFunction120'
                    }
                  />
                  <Button
                    label="Resend Email"
                    styleVariant="tertiary"
                    onClick={handleSendClaimAccountEmail}
                  />
                </EmailHeader>
                {user && (
                  <CommunicationsTableWrapper>
                    <EmailsTable emailList={user.sent_emails} />
                  </CommunicationsTableWrapper>
                )}
                <SectionTitle as="h2">Push Notifications</SectionTitle>
                {user && (
                  <CommunicationsTableWrapper>
                    <NotificationsTable notificationsList={user.sent_notifications} />
                  </CommunicationsTableWrapper>
                )}
              </FlexColumn>

              {agreements && (
                <FlexColumn>
                  <SectionTitle as="h2">Transfer or Archive An Account</SectionTitle>
                  <TransferCards>
                    {user?.accounts.map((account) => (
                      <HomeownerTransferCard
                        key={account.id}
                        account={account}
                        onClickHomeownerTransfer={(accountToTransfer) => {
                          setTransferAccount(accountToTransfer);
                        }}
                        onClickArchiveAccount={handleChangeArchiveAccount}
                      />
                    ))}
                  </TransferCards>
                </FlexColumn>
              )}
            </FlexColumn>

            <RightContent>
              {user && (
                <>
                  <UserInfoCard
                    user={user}
                    onEdit={() => {
                      setEditUser(true);
                    }}
                  />

                  <ActivityCard user={user} />
                </>
              )}
            </RightContent>
          </UserProfileContent>
        )}
      </div>
      {user && !isReadOnly && (
        <EditUserModal
          isOpen={editUser}
          defaultValues={{
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
            phone_number: user.phone_number as E164Number,
            address: user?.address,
          }}
          onSubmitUserForm={handleUpdate}
          onClose={() => {
            setEditUser(false);
          }}
        />
      )}
      {user && !isReadOnly && transferAccount && (
        <HomeownerTransferModal
          isOpen={Boolean(transferAccount)}
          transferUser={user}
          transferAccount={transferAccount}
          onClickTransferAgreement={handleClickTransferAgreement}
          onClose={() => {
            setTransferAccount(null);
          }}
        />
      )}
    </>
  );
}

export default UserProfile;
