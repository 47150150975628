import axios, { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';

import { routes } from '../../routes';

export type OAuth2AuthorizeRequestBody = {
  allow: boolean;
  client_id: string;
  client_secret: string;
  code_challenge: string;
  code_challenge_method: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
};

export type OAuth2AuthorizesResponseBody = { success_url: string };

type UseRelayOAuthAuthorizeResultBody = {
  onSuccess?: (data: OAuth2AuthorizesResponseBody) => void;
  onError?: (error: AxiosError) => void;
};

export const useRelayOAuthAuthorize = ({
  onSuccess,
  onError,
}: UseRelayOAuthAuthorizeResultBody): UseMutationResult<
  OAuth2AuthorizesResponseBody,
  AxiosError,
  OAuth2AuthorizeRequestBody
> =>
  useMutation(
    (body: OAuth2AuthorizeRequestBody) =>
      axios.post(routes.authorize, body).then((res) => res.data),
    {
      onSuccess,
      onError,
    },
  );
