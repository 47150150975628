import React from 'react';
import { useTheme } from 'styled-components';
import { IIconProps } from '../TIcon';

export function SystemIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.iconIconPrimarySecondary1100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M10.7998 14H8.39563V12.8C8.39563 12.5789 8.21673 12.4 7.99564 12.4C7.77454 12.4 7.59564 12.5789 7.59564 12.8V14H5.19992C4.97883 14 4.80018 14.1789 4.80018 14.4C4.80018 14.6211 4.97993 14.8 5.19992 14.8L10.7773 14.7958C10.9984 14.7958 11.1771 14.6169 11.1771 14.3958C11.1771 14.1747 11.0198 14 10.7998 14ZM15.9773 9.7375L14.8708 3.3375C14.7373 2.5655 14.0448 2 13.2323 2H2.76746C1.95547 2 1.26298 2.5655 1.12948 3.337L0.0229914 9.737C-0.145606 10.7125 0.634483 11.6 1.66022 11.6H14.3401C15.3648 11.6 16.1448 10.7125 15.9773 9.7375ZM6.37741 2.8H9.63486L9.96436 6.4025H6.04241L6.37741 2.8ZM4.83493 10.8H1.66022C1.39447 10.8 1.14748 10.688 0.983229 10.4928C0.896805 10.3901 0.759507 10.173 0.811356 9.87325L1.26935 7.24575H5.16492L4.83493 10.8ZM5.23992 6.4025H1.40772L1.91797 3.4525C1.98522 3.08325 2.34221 2.8 2.76746 2.8H5.57492L5.23992 6.4025ZM5.63992 10.8L5.97016 7.225H10.0376L10.3624 10.8H5.63992ZM10.4374 2.8H13.2323C13.6568 2.8 14.0138 3.08325 14.0813 3.47325L14.5915 6.42325H10.7891L10.4374 2.8ZM15.0173 10.4925C14.8523 10.6875 14.6048 10.8 14.3398 10.8H11.1648L10.8398 7.225H14.7298L15.1878 9.8525C15.2398 10.1725 15.1023 10.39 15.0173 10.4925Z"
        fill={color}
      />
    </svg>
  );
}

export default SystemIcon;
