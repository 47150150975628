import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { format, toZonedTime } from 'date-fns-tz';
import { isBefore, startOfDay } from 'date-fns';

import { useUser } from 'hooks/useUser';
import { perSessionAuthCopyAckModal } from 'constants/localStorageKeys';
import {
  MonitoringQueryKey,
  prefetchMonitoringQueries,
} from 'modules/system/utils/prefetchQueries';
import { AuthCopyAckModal } from 'shared/components/AuthCopyAckModal/AuthCopyAckModal';
import { getMonitoringSystemInfo } from 'modules/system/utils/getMonitoringSystemInfo';
import { SystemCategory } from 'api/system/monitoringTypes';
import { createDateRange, DateRangeStartDateBehavior } from 'api/system/utils/DateRange';
import { useGetSolarEnergyWithEnergyAllocation, useGetVppDetails } from 'api/system';
import { DateRangeZoomLevel } from 'api/types';
import { OverviewHeader } from '../components/OverviewHeader/OverviewHeader';
import { ManageSection } from '../components/ManageSection/ManageSection';
import { Container, EnergySectionWrapper, SystemWrapper } from './Overview.styles';
import { HouseGraphic } from '../components/HouseGraphic/HouseGraphic';
import { SystemCard } from '../components/SystemCard/SystemCard';
import { VppCard } from '../components/VppCard/VppCard';

export function Overview() {
  const { userResult, selectedAgreement, setSelectedAgreement } = useUser();
  const user = userResult?.user;
  const queryClient = useQueryClient();

  // get system with monitoring enabled
  const { system } = getMonitoringSystemInfo(selectedAgreement);
  const hasBattery = Boolean(system?.batteries?.length);

  const cachedTZObj = queryClient.getQueryData<{ timezone: string } | undefined>([
    MonitoringQueryKey.TIMEZONE,
    system?.id,
  ]);
  const timeZone = cachedTZObj?.timezone || system?.site_timezone || '';

  const zonedTime = toZonedTime(new Date(), timeZone);
  const midnightToday = startOfDay(zonedTime);
  const formatted = format(midnightToday, 'yyyy-MM-dd HH:mm:ssXXX', {
    timeZone,
  });
  const startDate = isBefore(new Date(formatted), midnightToday)
    ? midnightToday
    : new Date(formatted);

  const dateRange = createDateRange(
    startDate,
    DateRangeZoomLevel.DAY,
    timeZone,
    DateRangeStartDateBehavior.FROM_CALENDAR_UNIT,
  );

  const { data: solarEnergyAllocationData } = useGetSolarEnergyWithEnergyAllocation({
    systemId: system?.id,
    dateRange: dateRange ? { ...dateRange, zoomLevel: DateRangeZoomLevel.WEEK } : undefined, // Use WEEK for zoom level, so that `aggregation_level=day` is used
  });
  const { data: vppDetails } = useGetVppDetails({
    systemId: system ? system.id : '',
  });

  useEffect(() => {
    async function prefetchQueries() {
      if (system) await prefetchMonitoringQueries({ queryClient, system });
    }

    // run the monitoring query prefetch group if there's no timezone req already in the query cache
    if (!queryClient.getQueryData([MonitoringQueryKey.TIMEZONE, system?.id])) {
      prefetchQueries();
    }
  }, [queryClient, system]);

  const handleAgreementChange = (agreementId: string) => {
    if (agreementId !== selectedAgreement?.id) {
      const newAgreement = user?.agreements.find(({ id }) => id === agreementId);
      setSelectedAgreement(newAgreement);
    }
  };

  return (
    <>
      <Container>
        {user && selectedAgreement && (
          <>
            <OverviewHeader
              selectedAgreement={selectedAgreement}
              agreements={user.agreements}
              userAddress={user.address}
              onAgreementChange={handleAgreementChange}
            />
            <SystemWrapper>
              <HouseGraphic hasBattery={hasBattery} />
              <SystemCard
                system={system}
                category={SystemCategory.SOLAR}
                energyAllocation={solarEnergyAllocationData?.energy_allocation}
                monitoringData={solarEnergyAllocationData?.monitoring_data}
                routeToSystemPage
              />
            </SystemWrapper>
            <ManageSection agreement={selectedAgreement} />
            <EnergySectionWrapper>
              {vppDetails && <VppCard total_revenue={vppDetails.total_revenue.toString()} />}
            </EnergySectionWrapper>
          </>
        )}
      </Container>
      <AuthCopyAckModal modalKey={perSessionAuthCopyAckModal} />
    </>
  );
}

export default Overview;
