import React, { useEffect, useRef } from 'react';
import Markdown from 'react-markdown';

import { InboxMessageType } from 'api/types';
import { MarkdownBodyWrapper, DetailBodyWrapper, Subject } from './DetailBody.styles';
import { setBodyScrollingValues } from '../utils';

type Props = {
  message?: InboxMessageType;
  updateScrolling: (isScrolling: boolean) => void;
  updateScrolledToBottom: (scrolledToBottom: boolean) => void;
};

export function DetailBody({ message, updateScrolling, updateScrolledToBottom }: Props) {
  const bodyRef = useRef<HTMLDivElement>(null);
  updateScrolling(false);
  useEffect(() => {
    setBodyScrollingValues({
      isFetching: false,
      isFetchingNextPage: false,
      updateScrolledToBottom,
      updateScrolling,
      bodyRef,
    });
  }, [updateScrolling, updateScrolledToBottom]);
  return (
    <DetailBodyWrapper ref={bodyRef}>
      <Subject as="p">{message?.subject}</Subject>
      <MarkdownBodyWrapper as="p">
        <Markdown>{message?.markdown_body ? message.markdown_body : message?.body}</Markdown>
      </MarkdownBodyWrapper>
    </DetailBodyWrapper>
  );
}
export default DetailBody;
