/* eslint-disable class-methods-use-this */
import { addDays, format } from 'date-fns';
import { capitalize } from 'lodash';

import { DateRange } from 'api/system/utils/DateRange';
import { PaddingType } from 'modules/types';

export class WeekChartDataProvider {
  static numberOfTicks = 7;

  static placeholderMaximumValue = 16;

  static domainPaddingX: PaddingType = [75, 10];

  static yTickLabelOffset = {
    x: -64,
    y: 15,
  };

  static tickXFormat = (day: number, range: DateRange): string => {
    const date = addDays(range.startDate, day);
    return capitalize(format(date, 'eee'));
  };

  static getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(WeekChartDataProvider.numberOfTicks).keys());

  static tooltipDateFormatter = (date: Date): string =>
    capitalize(format(date, 'M/d').toLowerCase());
}

export default WeekChartDataProvider;
