/* eslint-disable class-methods-use-this */
import { isWithinInterval, subMinutes } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { MonitoringDatum, RawMonitoringDatumType, SystemType } from 'api/types';
import { ChartDataType } from 'modules/types';
import { AggregatedData } from 'api/system/types';

export class ChartDataProvider {
  system?: SystemType;

  constructor(system?: SystemType) {
    this.system = system;
  }

  /** Parsing dates like this pretends every timestamp we get back from the system monitoring information happened in our local timezone */
  parseDates<T>(rawData: RawMonitoringDatumType<T>[]): MonitoringDatum<T>[] {
    return rawData?.map((data) => ({
      ...data,
      time: new Date(data.timestamp),
    }));
  }

  /** Filters requested data to only include information from the requested times */
  filterData<T>(monitoringData: MonitoringDatum<T>[], dateRange: DateRange): MonitoringDatum<T>[] {
    const { startDate, endDate } = dateRange;
    return monitoringData.filter((entry) =>
      isWithinInterval(entry.time, { start: subMinutes(startDate, 1), end: endDate }),
    );
  }

  aggregateData<T>(
    _: MonitoringDatum<T>[],
    __: DateRange,
  ): [Map<any, any>, number | null, T | null] {
    throw new Error('Method not implemented.');
  }

  tickYFormat<T>(value: number, unit: T | null): string {
    if (!unit) {
      return '';
    }
    return `${value.toFixed(1)}`;
  }

  prepareData<T>(
    data: RawMonitoringDatumType<T>[],
    dateRange: DateRange,
  ): [ChartDataType[], number | null, T | null] {
    const datesAdded = this.parseDates(data);
    const [dailyProduction, largestProductionValue, largestProductionUnit] = this.aggregateData<T>(
      datesAdded,
      dateRange,
    );
    const productionData: ChartDataType[] = [];
    dailyProduction.forEach((value: AggregatedData<T>, key: number) => {
      productionData.push({
        x: key,
        y: value.y,
        ...(value.y0 !== undefined && { y0: value.y0 }),
        ...(value.y0_raw && { y0_raw: value.y0_raw }),
        ...(value.y_raw && { y_raw: value.y_raw }),
        unit: value.unit as string,
        value: value.y,
        time: value.time,
      });
    });
    return [productionData, largestProductionValue, largestProductionUnit];
  }

  static getTickValuesYAxis =
    (numberOfTicks: number) =>
    (maximumValue: number): number[] => {
      const tickValues = Array.from(Array(numberOfTicks).keys()).map(
        (_, index) => -maximumValue + (maximumValue * 2 * index) / (numberOfTicks - 1),
      );
      return tickValues;
    };
}

export default ChartDataProvider;
