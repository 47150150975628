import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

import { ArrowUpRightFromSquare } from '../icons';
import {
  CardActionText,
  CardIconContainer,
  CardMainBody,
  CardTitle,
  IconColor,
  IconPosition,
} from './Card.styles';

export type Icon = {
  element: JSX.Element;
  color?: IconColor;
};

interface CardProps {
  title: string;
  url?: string;
  route?: string;
  navState?: Record<string, any>;
  newTab?: boolean;
  description: string;
  height?: string;
  width?: string;
  icon?: Icon;
  hasHeaderIcons?: boolean;
  includeUrlArrow?: boolean;
  className?: string;
  dataTestId?: string;
  onClick?: () => void;
  iconPosition?: IconPosition;
}

export function Card({
  title,
  url,
  route,
  navState,
  children,
  icon,
  height,
  width,
  actionText,
  actionIcon,
  newTab = true,
  hasHeaderIcons = true,
  includeUrlArrow = true,
  className,
  dataTestId,
  onClick,
  iconPosition = IconPosition.ABOVE,
}: PropsWithChildren<Omit<CardProps, 'description'> & { actionText?: string; actionIcon?: Icon }>) {
  const navigate = useNavigate();

  const testId = dataTestId || `${title.split(' ').join('-').toLowerCase()}-card`;

  const handleClick = () => {
    if (route) {
      navigate(route, { state: navState });
      return;
    }

    if (url && newTab) {
      window.open(url, '_blank');
    } else if (url) {
      window.location.href = url;
    }

    onClick?.();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClick();
    }
  };

  const cardRole = route || url || onClick ? 'button' : 'none';
  const enableInteractiveStyles = Boolean(url) || Boolean(route) || Boolean(onClick);

  return (
    <CardMainBody
      data-testid={testId}
      role={cardRole}
      tabIndex={0}
      $cardHeight={height}
      $cardWidth={width}
      $iconColor={icon ? icon.color : undefined}
      $iconPosition={iconPosition}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      // allows importing/direct styling via styled-components
      className={className}
      $enableInteractiveStyles={enableInteractiveStyles}
    >
      {icon && hasHeaderIcons && iconPosition === IconPosition.ABOVE && (
        <CardIconContainer>{icon.element}</CardIconContainer>
      )}

      <CardTitle as="h2">
        {icon && hasHeaderIcons && iconPosition === IconPosition.NEXT && (
          <CardIconContainer>{icon.element}</CardIconContainer>
        )}
        {title}
        {url && hasHeaderIcons && includeUrlArrow && <ArrowUpRightFromSquare />}
      </CardTitle>
      {children}
      {actionText && (
        <CardActionText>
          {actionIcon?.element} {actionText}
        </CardActionText>
      )}
    </CardMainBody>
  );
}

export default Card;
