import styled from 'styled-components';
import { FONT_FAMILY } from '../Text/Text';

export const TooltipTextContainer = styled.div<{ $margin?: string }>`
  display: contents;
  height: 16px;
  width: 16px;
  svg {
    margin: ${({ $margin }) => $margin};
  }

  .eb-tooltip {
    max-width: 196px;
    padding: 12px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
    background: ${({ theme }) => theme.colors.backgroundPrimaryWhite};
    color: ${({ theme }) => theme.colors.textSecondaryGrey90};
    box-shadow: ${({ theme }) => theme.elevations.mid};
    font-family: ${FONT_FAMILY};
  }
`;

export default TooltipTextContainer;
