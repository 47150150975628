import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import Root from 'routes/root';
import pages from 'pages';
import { AdminContextProvider } from 'contexts/AdminContext';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { AuthGuard } from 'guards/AuthGuard';
import { DashboardGuard } from 'guards/DashboardGuard';
import { AdminGuard } from 'guards/AdminGuard';
import { Account } from 'modules/account/screens/Account';
import { Status } from 'modules/status/screens/Status';
import { Support } from 'modules/support/screens/Support';
import { Layout } from 'shared/components/Layout/Layout';
import { Chat } from 'modules/chat/screens/Chat';
import OAuth2 from 'modules/auth/screens/oauth2/OAuth2';
import { ExpiringSessionModal } from 'shared/components/ExpiringSessionModal/ExpiringSessionModal';
import { Dashboard } from './dashboard';
import { Admin } from './admin/admin';
import { Auth } from './auth';
import { DjangoAdmin } from './django-admin';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorFallback />,
    children: [
      {
        element: <DjangoAdmin />,
        path: 'django_admin',
      },
      {
        path: pages.OAUTH2,
        element: <OAuth2 />,
      },
      {
        element: (
          <>
            <Outlet />
            <ExpiringSessionModal />
          </>
        ),
        children: [
          {
            element: <AuthGuard needsSession redirectPath={pages.LOGIN} />,
            children: [
              {
                element: (
                  <AdminGuard isAdminDashboard={false} redirectPath={pages.ADMIN}>
                    <DashboardGuard />
                  </AdminGuard>
                ),
                children: Dashboard,
              },

              {
                element: (
                  <AdminGuard isAdminDashboard redirectPath={pages.DASHBOARD}>
                    <AdminContextProvider>
                      <Outlet />
                    </AdminContextProvider>
                  </AdminGuard>
                ),
                children: Admin,
              },
              {
                element: <DashboardLayout />,
                children: [
                  { path: pages.ACCOUNT, element: <Account /> },
                  { path: pages.STATUS, element: <Status /> },
                  { path: pages.SUPPORT, element: <Support /> },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <AuthGuard needsSession={false} redirectPath={`/${pages.DASHBOARD}`} />,
        children: Auth,
      },
      {
        path: pages.CHAT,
        element: (
          <Layout hasFooter>
            <Chat />
          </Layout>
        ),
      },
      {
        path: pages.ERROR_SURFLY,
        element: <ErrorFallback isScreenShare />,
      },
    ],
  },
]);

export default router;
