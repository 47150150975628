import { startOfDay } from 'date-fns';
import { AgreementType } from 'api/types';
import { hasMonitoring } from './hasMonitoring';

export const getMonitoringSystemInfo = (selectedAgreement: AgreementType | undefined) => {
  const system =
    selectedAgreement?.system && hasMonitoring(selectedAgreement.system)
      ? selectedAgreement.system
      : undefined;

  const inServiceDate: Date | null =
    system && selectedAgreement?.in_service_date
      ? startOfDay(selectedAgreement?.in_service_date)
      : null;

  return { system, inServiceDate };
};

export default getMonitoringSystemInfo;
