import React from 'react';

import { useTranslation } from 'react-i18next';
import { ErrorLaptopImg } from 'shared/components/icons';
import { ErrorButtonStyled } from 'shared/components/ErrorBoundary/ErrorBoundary.styles';
import { Body, ErrorBodyWrapper, HeaderText } from './ErrorBody.styles';

interface ErrorBodyProps {
  onClick: () => void;
}
export function ErrorBody({ onClick }: ErrorBodyProps) {
  const { t } = useTranslation();
  return (
    <ErrorBodyWrapper>
      <ErrorLaptopImg width="193" height="177" />
      <HeaderText as="p">{t('inbox.error.subject')}</HeaderText>
      <Body as="p">{t('inbox.error.body')}</Body>
      <ErrorButtonStyled
        $isFullWidth
        $maxWidth="100%"
        onClick={onClick}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') onClick();
        }}
      >
        {t('inbox.error.button')}
      </ErrorButtonStyled>
    </ErrorBodyWrapper>
  );
}
export default ErrorBody;
