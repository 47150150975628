import React, { useState } from 'react';
import { useUploadAuthCopyAckCsv } from 'api/agreement';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { toast } from 'shared/components/Toast/Toast';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { Button } from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';
import { Header } from 'shared/components/Header/Header';
import { AuthCopyAckWrapper } from './AuthCopyAck.styles';
import { ErrorResponse } from '../../../../api/types';

export function AuthCopyAck() {
  const [file, setFile] = useState<File | null>(null);

  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate, data, isLoading } = useUploadAuthCopyAckCsv({
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('toast.success'),
        theme,
      });
    },
    onError: (error) => {
      const errorData = error.response?.data as ErrorResponse;
      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorData.detail
          ? JSON.stringify(errorData.detail)
          : t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      mutate({ file });
    }
  };

  return (
    <AuthCopyAckWrapper>
      <Header pageName="AUTH_COPY_ACK" title={t('admin.authCopyAck')} />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <input id="ac_ack_csv" type="file" onChange={handleFileChange} />

          {file && <Button type="button" label="Upload" onClick={handleUpload} />}

          {data?.before_true !== null && (
            <>
              <Text as="p">{`Previous Acknowledged: ${data?.before_true}`}</Text>
              <Text as="p">{`New Acknowledged: ${data?.after_true}`}</Text>
              <Text as="p">{`Previous Unacknowledged: ${data?.before_false}`}</Text>
              <Text as="p">{`New Unacknowledged: ${data?.after_false}`}</Text>
            </>
          )}
        </>
      )}
    </AuthCopyAckWrapper>
  );
}

export default AuthCopyAck;
