import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import AlertIcon from 'shared/components/icons/AlertIcon';
import ReactModal from 'react-modal';
import { modalStyles } from 'shared/components/Modal/Modal.styles';
import { InboxMessageType } from 'api/types';
import useUser from 'hooks/useUser';
import { useGetInboxMessages, useSetInboxMessageRead } from 'api/inboxMessage';
import { toast } from 'shared/components/Toast/Toast';
import { useTranslation } from 'react-i18next';
import useMessageCenterContext from 'hooks/useMessageCenterContext';
import { useNavigate } from 'react-router-dom';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { Button } from '../../Button/Button';
import {
  MessageCenterBodyWrapper,
  MessageCenterIconContainer,
  MessageCenterModalWrapper,
} from './MessageCenterModal.styles';
import { DetailBody } from '../DetailBody/DetailBody';
import { FeedBody } from '../FeedBody/FeedBody';
import { ModalBodyType } from '../types';
import { MessageCenterHeader } from '../MessageCenterHeader/MessageCenterHeader';
import { MessageCenterFooter } from '../MessageCenterFooter/MessageCenterFooter';
import { MarkAllReadModal } from '../FeedBody/MarkAllReadModal/MarkAllReadModal';
import { getSupportedDeeplinkPath } from '../utils';

type Props = {
  modalOpen_StorybookOnly?: boolean;
};

export function MessageCenterModal({ modalOpen_StorybookOnly }: Props) {
  const theme = useTheme();
  const messageCenterModalStyles = {
    ...modalStyles(theme),
    content: {
      ...modalStyles(theme).content,
      justifyContent: 'flex-end',
    },
    // z-index of support chat widget is 2147483646
    overlay: { ...modalStyles(theme).overlay, zIndex: '1000' },
  };
  const markAllAsReadStyles = {
    ...modalStyles(theme),
    content: {
      ...modalStyles(theme).content,
      justifyContent: 'center',
    },
    overlay: { ...modalStyles(theme).overlay, zIndex: '1001' },
  };

  const [isOpen, setIsOpen] = useState(modalOpen_StorybookOnly || false);
  const [markAllReadOpen, setMarkAllReadOpen] = useState(false);

  const [modalType, setModalType] = useState<ModalBodyType>(ModalBodyType.FEED);
  const [isClosing, setIsClosing] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(true);

  const {
    detailMessage,
    setDetailMessage,
    inboxMessages,
    setInboxMessages,
    totalUnreadSentInboxMessages,
    setTotalUnreadSentInboxMessages,
  } = useMessageCenterContext();
  const { userResult, selectedAgreement } = useUser();
  const user = userResult?.user;
  const direction = 'descending';
  const sortBy = 'date_sent';

  const alertIcon = () => (
    <AlertIcon filled={isOpen} hasUnreadAlerts={Boolean(totalUnreadSentInboxMessages)} />
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    refetch,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    isFetching,
  } = useGetInboxMessages({
    userId: user?.id,
    sortBy,
    direction,
    onSuccess(data) {
      const allMessages = data.pages.flatMap((page) => page.results);
      const firstPage = data.pages[0];
      setInboxMessages(allMessages);
      setTotalUnreadSentInboxMessages(firstPage.total_unread_sent_inbox_messages);
    },
  });
  const handleBackClick = () => {
    setModalType(ModalBodyType.FEED);
    setDetailMessage(undefined);
    setScrolling(false);
  };
  const handleCloseDropdown = () => {
    setIsClosing(true);
    setIsOpen(false);
    setModalType(ModalBodyType.FEED);
    setDetailMessage(undefined);
    setScrolling(false);
  };
  useEffect(() => {
    if (isClosing) setIsClosing(false);
  }, [isClosing]);

  const handleOpenDropdown = () => {
    if (!isClosing) {
      setIsOpen(true);
    }
    setIsClosing(false);
  };

  const handleMarkAllReadClick = () => {
    setMarkAllReadOpen(true);
  };

  const handleCloseMarkAllRead = () => {
    setMarkAllReadOpen(false);
  };
  const { mutate: mutateSetMessageRead } = useSetInboxMessageRead({
    onSuccess: async () => {
      refetch();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('inbox.setMessageRead.error'),
        theme,
      });
    },
  });

  const handleMessageClick = useCallback(
    (message: InboxMessageType) => {
      setDetailMessage(message);

      if (!message.read && user) {
        mutateSetMessageRead({ userId: user.id, inboxMessageId: message.id });
      }
      setModalType(ModalBodyType.DETAILS);
    },
    [mutateSetMessageRead, user, setDetailMessage],
  );

  const observer = useRef<IntersectionObserver>();
  const lastMessageElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading || isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, isFetchingNextPage, fetchNextPage, hasNextPage],
  );
  const body = useMemo(() => {
    switch (modalType) {
      case ModalBodyType.DETAILS:
        return (
          <DetailBody
            message={detailMessage}
            updateScrolling={setScrolling}
            updateScrolledToBottom={setScrolledToBottom}
          />
        );

      default:
        return (
          <FeedBody
            messages={inboxMessages}
            handleMessageClick={handleMessageClick}
            lastMessageElementRef={lastMessageElementRef}
            isError={isError}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            refetch={refetch}
            updateScrolling={setScrolling}
            updateScrolledToBottom={setScrolledToBottom}
            isFetching={isFetching}
          />
        );
    }
  }, [
    modalType,
    detailMessage,
    inboxMessages,
    isError,
    isLoading,
    isFetchingNextPage,
    handleMessageClick,
    lastMessageElementRef,
    refetch,
    isFetching,
  ]);
  const { hasDashboardAccess } = getDashboardRedirectPath(user, selectedAgreement);
  const deeplink = getSupportedDeeplinkPath(detailMessage?.deeplink, hasDashboardAccess);

  const handleDetailCtaClick = useCallback(() => {
    if (!deeplink) {
      return;
    }
    navigate(deeplink);
    // Close the modal after clicking the CTA
    // Important if the CTA button takes you to the page you're currently on.
    setIsClosing(true);
    setIsOpen(false);
  }, [deeplink, navigate]);
  const footer = useMemo(() => {
    switch (modalType) {
      case ModalBodyType.DETAILS:
        if (!detailMessage || !deeplink) {
          return null;
        }
        return (
          <MessageCenterFooter
            buttonText={
              detailMessage.cta_title ? detailMessage.cta_title : t('inbox.footer.ctaDefaultText')
            }
            handleButtonClick={handleDetailCtaClick}
            scrolledToBottom={scrolledToBottom}
          />
        );
      case ModalBodyType.FEED:
        if (totalUnreadSentInboxMessages === 0 || inboxMessages.length === 0) {
          return null;
        }
        return (
          <MessageCenterFooter
            buttonText={t('inbox.footer.markAllRead')}
            handleButtonClick={handleMarkAllReadClick}
            scrolledToBottom={scrolledToBottom}
          />
        );
      default:
        return null;
    }
  }, [
    modalType,
    scrolledToBottom,
    t,
    totalUnreadSentInboxMessages,
    inboxMessages,
    detailMessage,
    deeplink,
    handleDetailCtaClick,
  ]);
  return (
    <>
      <MessageCenterIconContainer>
        <Button
          styleVariant="tertiary"
          Icon={alertIcon}
          onClick={handleOpenDropdown}
          iconCenterAlign
          label={t('inbox.alertIcon')}
        />
      </MessageCenterIconContainer>

      <ReactModal
        contentLabel={t('inbox.alertIcon')}
        isOpen={isOpen}
        preventScroll={false}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={handleCloseDropdown}
        appElement={document.querySelector('#root') as HTMLElement}
        style={messageCenterModalStyles}
        justify-content="flex-end"
      >
        <MessageCenterModalWrapper>
          <MessageCenterHeader
            modalType={modalType}
            handleClose={handleCloseDropdown}
            handleBackClick={handleBackClick}
            feedScrolling={scrolling}
          />
          <MessageCenterBodyWrapper $modalType={modalType} $hasFooter={footer !== null}>
            {body}
          </MessageCenterBodyWrapper>
          {footer}
        </MessageCenterModalWrapper>
      </ReactModal>
      <ReactModal
        isOpen={markAllReadOpen}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        appElement={document.querySelector('#root') as HTMLElement}
        style={markAllAsReadStyles}
      >
        <MarkAllReadModal handleCloseModal={handleCloseMarkAllRead} refetchMessages={refetch} />
      </ReactModal>
    </>
  );
}
export default MessageCenterModal;
