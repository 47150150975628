import { InboxMessageType } from 'api/types';
import React, { Context, createContext, useMemo, useState } from 'react';

interface MessageCenterContextProps {
  detailMessage: InboxMessageType | undefined;
  setDetailMessage: (message: InboxMessageType | undefined) => void;
  inboxMessages: InboxMessageType[];
  setInboxMessages: (messages: InboxMessageType[]) => void;
  totalUnreadSentInboxMessages: number;
  setTotalUnreadSentInboxMessages: (count: number) => void;
}

const MessageCenterContext: Context<MessageCenterContextProps> = createContext(
  {} as MessageCenterContextProps,
);

function MessageCenterContextProvider({ children }: { children: React.ReactNode }) {
  const [detailMessage, setDetailMessage] = useState<InboxMessageType | undefined>();
  const [inboxMessages, setInboxMessages] = useState<InboxMessageType[]>([]);

  const [totalUnreadSentInboxMessages, setTotalUnreadSentInboxMessages] = useState(0);

  const value = useMemo(
    () => ({
      detailMessage,
      setDetailMessage,
      inboxMessages,
      setInboxMessages,
      totalUnreadSentInboxMessages,
      setTotalUnreadSentInboxMessages,
    }),
    [detailMessage, inboxMessages, totalUnreadSentInboxMessages],
  );

  return <MessageCenterContext.Provider value={value}>{children}</MessageCenterContext.Provider>;
}

export { MessageCenterContext, MessageCenterContextProvider };
