import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { SystemType } from 'api/types';
import { pages } from 'pages';
import { Header } from 'shared/components/Header/Header';
import Accordion, { AccordionItemType } from 'shared/components/Accordion/Accordion';
import Divider from 'shared/components/Divider/Divider';
import { HardWareInfoWrapper, Label, SectionDetails, Title, Value } from './HardwareInfo.styles';

interface Props {
  /** Should never need to be used outside testing–system information should be passed by navigation props */
  systemProp?: SystemType;
}

export function HardwareInfo({ systemProp }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();

  const system: SystemType = systemProp || location.state.system;

  const systemDetails = [
    { label: t('hardwareInformation.model'), value: system.panels || '' },
    { label: t('hardwareInformation.inverters'), value: system.inverters || '' },
    {
      label: t('hardwareInformation.capacity'),
      value: t('hardwareInformation.capacity_format', {
        value: parseFloat(system.capacity?.toString() || '0'),
      }),
    },
  ];

  const solarPanelAccordionItems: AccordionItemType[] = [
    {
      label: <Title as="h3">{t('hardwareInformation.solarPanels')}</Title>,
      content: (
        <SectionDetails>
          {systemDetails.map((item) => (
            <div key={item.label}>
              <Label as="p">{item.label}</Label>
              <Value as="p">{item.value}</Value>
            </div>
          ))}
        </SectionDetails>
      ),
    },
    {
      label: <Title as="h3">{t('hardwareInformation.battery')}</Title>,
      content: (
        <div style={{ width: '100%' }}>
          <div>
            <Label as="p">{t('hardwareInformation.totalStorageCapacity')}</Label>
            <Value as="p">{`${system.total_battery_capacity_kwh} kWh`}</Value>
          </div>

          {system?.batteries.map((battery, idx) => (
            <>
              <Divider
                $margin={`${idx === 0 ? '16px' : '20px'} 0 20px`}
                $lineColor={theme.colors.backgroundAccentGreySubtleGrey10}
              />
              <SectionDetails key={battery.id}>
                <div>
                  <Label as="p">{t('hardwareInformation.manufacturer')}</Label>
                  <Value as="p">{battery.manufacturer || ''}</Value>
                </div>
                <div>
                  <Label as="p">{t('hardwareInformation.model')}</Label>
                  <Value as="p">{battery.model || ''}</Value>
                </div>

                <div>
                  <Label as="p">{t('hardwareInformation.batteryCount')}</Label>
                  <Value as="p">{battery.count}</Value>
                </div>

                <div>
                  <Label as="p">{t('hardwareInformation.storageCapacity')}</Label>
                  <Value as="p">{`${battery.capacity} kWh`}</Value>
                </div>

                <div>
                  <Label as="p">{t('hardwareInformation.powerRating')}</Label>
                  <Value as="p">{`${battery.power_rating} kW`}</Value>
                </div>
              </SectionDetails>
            </>
          ))}
        </div>
      ),
    },
  ];

  return (
    <HardWareInfoWrapper>
      <Header
        pageName="HARDWARE_INFO"
        title={t('hardwareInformation.hardwareInformation')}
        routeBreadCrumbs={[
          {
            label: 'System',
            link: resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`),
          },
          { label: 'Hardware Information' },
        ]}
      />

      <Accordion styleVariant="tertiary" items={solarPanelAccordionItems} isOpen />
    </HardWareInfoWrapper>
  );
}

export default HardwareInfo;
