import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { resolvePath } from 'react-router';
import { useTheme } from 'styled-components';

import { pages } from 'pages';
import { Card } from 'shared/components/Card/Card';
import { CardDescription, IconPosition } from 'shared/components/Card/Card.styles';

import { UtilityPoleIcon } from 'shared/components/icons/UtilityPoleIcon';
import { useBreakpoints } from 'hooks/useWindowSize';
import { FlagKey } from 'api/types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { VppCardBody, VppCardWrapper } from './VppCard.styles';
import VppCardTotalEarnedText from './VppCardTotalEarnedText';

type VppCardProps = {
  total_revenue: string;
};

export function VppCard({ total_revenue }: VppCardProps) {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const {
    flags: { [FlagKey.vpp_2024]: vppFlag },
  } = useFeatureFlags();
  return vppFlag ? (
    <VppCardWrapper>
      <Card
        title={
          screenSize === 'md'
            ? t('overview.virtualPowerPlantCard.vpp')
            : t('overview.virtualPowerPlantCard.virtualPowerPlant')
        }
        icon={{
          element: <UtilityPoleIcon color={colors.iconIconPrimarySecondary1100} />,
          color: 'grey',
        }}
        iconPosition={IconPosition.NEXT}
        route={resolvePath(pages.VPP_DETAILS, `/${pages.DASHBOARD}/${pages.SYSTEM}`).pathname}
      >
        <VppCardBody>
          <VppCardTotalEarnedText value={total_revenue} />
          <CardDescription as="p">
            <Trans components={{ b: <b /> }}>
              {screenSize === 'xl' || screenSize === 'lg'
                ? t('overview.virtualPowerPlantCard.congratulationsViewDetails')
                : t('overview.virtualPowerPlantCard.viewDetails')}
            </Trans>
          </CardDescription>
        </VppCardBody>
      </Card>
    </VppCardWrapper>
  ) : null;
}

export default VppCard;
