import React from 'react';
import { useTheme } from 'styled-components';
import { IIconProps } from './TIcon';

type Props = IIconProps & {
  height?: number;
  width?: number;
};

export function ArrowLeftIcon({ color, height = 24, width = 25 }: Props) {
  const theme = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon">
        <path
          id="Vector"
          d="M16.6171 18.9792C16.8926 19.289 16.8684 19.7634 16.5614 20.042C16.2538 20.3195 15.7791 20.2946 15.5025 19.9862L8.75104 12.4667C8.49466 12.1789 8.49466 11.7473 8.75104 11.4593L15.5014 3.98351C15.7783 3.67518 16.2529 3.65018 16.5604 3.92769C16.8847 4.22235 16.8784 4.69782 16.6161 4.99055L10.3182 11.9639L16.6171 18.9792Z"
          fill={color || theme.colors.textPrimarySecondary1100}
        />
      </g>
    </svg>
  );
}

export default ArrowLeftIcon;
