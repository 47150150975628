import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useRelayOAuthAuthorize } from 'api/oauth2';
import { pages } from 'pages';
import { useUser } from '../../../../hooks/useUser';

export function OAuth2() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userResult } = useUser();
  const [sentAuthRequest, setSentAuthRequest] = useState(false);

  const { mutateAsync: relayOAuth2AuthorizeRequest } = useRelayOAuthAuthorize({
    onSuccess: (response) => {
      window.location.href = response.success_url;
    },
    onError: () => {
      console.log('Error response from the backend!'); // TODO: Not sure what the error state should be.
    },
  });

  useEffect(() => {
    const allow = searchParams.get('allow') === 'true';
    const codeChallengeMethod = searchParams.get('code_challenge_method');
    const clientId = searchParams.get('client_id');
    const clientSecret = searchParams.get('client_secret');
    const codeChallenge = searchParams.get('code_challenge');
    const redirectUri = searchParams.get('redirect_uri');
    const responseType = searchParams.get('response_type');
    const scope = searchParams.get('scope');

    if (!userResult?.loading && userResult?.user && !sentAuthRequest) {
      setSentAuthRequest(true);
      relayOAuth2AuthorizeRequest({
        allow,
        client_id: clientId || '',
        client_secret: clientSecret || '',
        code_challenge: codeChallenge || '',
        code_challenge_method: codeChallengeMethod || '',
        redirect_uri: redirectUri || '',
        response_type: responseType || '',
        scope: scope || '',
      });
    } else if (!userResult?.loading) {
      navigate(`/${pages.LOGIN}?${searchParams}&redirect_to_oauth=true`);
    }
  }, [
    navigate,
    relayOAuth2AuthorizeRequest,
    searchParams,
    userResult?.user,
    userResult?.loading,
    sentAuthRequest,
  ]);
  return null;
}

export default OAuth2;
