/* eslint-disable class-methods-use-this */
import { ChartTheme } from 'modules/system/components/Charts/DayChart/AreaChart.types';
import { TThemeColors } from 'shared/design-system/theme/colors/TThemeColors';
import { ChartDataProvider } from '../ChartDataProvider';

export class HomeChartDataProvider extends ChartDataProvider {
  getTheme: (color: TThemeColors) => ChartTheme = (color: TThemeColors) => ({
    fill: {
      lightTheme: {
        start: '#315EC0',
        middle: '#22345B',
        end: '#1F3F86',
      },
      darkTheme: {
        start: '#315EC0',
        middle: '#22345B',
        end: '#1F3F86',
      },
    },
    line: color.chartChartDuskSecondary140,
    tooltip: color.chartChartDuskSecondary140,
    yAxisTitle: color.chartChartDuskSecondary140,
  });

  static homeGetTickValuesYAxis(maximumValue: number): number[] {
    const numberOfTicks = 2;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map((_, index) => {
      const value = ((index + 1) * maximumValue) / numberOfTicks;
      return value;
    });
    return [0, ...tickValues];
  }
}

export default HomeChartDataProvider;
