import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function UtilityPoleIcon({ color: colorProp }: IconProps) {
  const theme = useTheme();
  const color = colorProp || theme.colors.iconIconPrimarySecondary1100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="utilityPoleButton" clipPath="url(#clip0_4299_1911)">
        <path
          id="Vector"
          d="M8.75 0.75C8.75 0.334375 8.41562 0 8 0C7.58437 0 7.25 0.334375 7.25 0.75V2.5H4V1.75C4 1.33438 3.66563 1 3.25 1C2.83437 1 2.5 1.33438 2.5 1.75V2.5H2.00625C2 2.5 1.99688 2.5 1.99063 2.5H1.5V1.75C1.5 1.33438 1.16562 1 0.75 1C0.334375 1 0 1.33438 0 1.75V2.75C0 3.44062 0.559375 4 1.25 4H2.6L7.25 7.1V9.25V15.25C7.25 15.6656 7.58437 16 8 16C8.41562 16 8.75 15.6656 8.75 15.25V9.25V7.1L13.4 4H14.75C15.4406 4 16 3.44062 16 2.75V1.75C16 1.33438 15.6656 1 15.25 1C14.8344 1 14.5 1.33438 14.5 1.75V2.5H14.0063C14 2.5 13.9969 2.5 13.9906 2.5H13.5V1.75C13.5 1.33438 13.1656 1 12.75 1C12.3344 1 12 1.33438 12 1.75V2.5H8.75V0.75ZM11.6 4L8.75 5.9V4H11.6ZM7.25 5.9L4.4 4H7.25V5.9Z"
          fill="#22345B"
        />
      </g>
      <defs>
        <clipPath id="clip0_4299_1911">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UtilityPoleIcon;
