import { TTheme } from '../theme';
import { TTabs } from './TTabs';

export const themeTabs = (theme: TTheme): TTabs => {
  const inactiveStyles = {
    // default
    background: 'transparent',
    // hover
    backgroundHover: theme.colors.backgroundInteractionActiveGrey20,
    // active
    backgroundActive: theme.colors.backgroundInteractionHoverGrey10,
  };

  return {
    variants: {
      blue: {
        active: {
          // default
          background: theme.colors.chartChartBlueSecondary2100,

          // hover
          backgroundHover: theme.colors.chartChartBlueHoverFunction4100,

          // active
          backgroundActive: theme.colors.chartChartBluePressedFunction41002,
        },
        inactive: inactiveStyles,

        // other
        gap: '8px;',
      },
      green: {
        active: {
          // default
          background: theme.colors.chartChartGreenFunction540,

          // hover
          backgroundHover: theme.colors.chartChartGreenHoverFunction560,

          // active
          backgroundActive: theme.colors.chartChartGreenPressedFunction5100,
        },
        inactive: inactiveStyles,

        // other
        gap: '6px;',
      },
      magenta: {
        active: {
          // default
          background: theme.colors.chartChartMagentaAccent1100,

          // hover
          backgroundHover: theme.colors.chartChartMagentaHoverAccent1120,

          // active
          backgroundActive: theme.colors.chartChartMagentaPressedAccent1160,
        },
        inactive: inactiveStyles,

        // other
        gap: '8px;',
      },

      dusk: {
        active: {
          // default
          background: theme.colors.chartChartDuskSecondary140,

          // hover
          backgroundHover: theme.colors.chartChartDuskHoverSecondary1100,

          // active
          backgroundActive: theme.colors.chartChartDuskPressedSecondary1120,
        },
        inactive: inactiveStyles,

        // other
        gap: '8px;',
      },
    },
  };
};

export default themeTabs;
