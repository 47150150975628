import React from 'react';

import { MessageCategory } from 'api/types';

import MilestoneIcon from 'shared/components/icons/MessageCategories/MilestoneIcon';
import UpsellingIcon from 'shared/components/icons/MessageCategories/UpsellingIcon';

import EarlyAccessIcon from 'shared/components/icons/MessageCategories/EarlyAccessIcon';
import VppIcon from 'shared/components/icons/MessageCategories/VppIcon';
import AccountIcon from 'shared/components/icons/MessageCategories/AccountIcon';
import BillingIcon from 'shared/components/icons/MessageCategories/BillingIcon';
import SystemIcon from 'shared/components/icons/MessageCategories/SystemIcon';
import { IconContainer } from './MessageCategoryIcon.styles';

type MessageCategoryIconProps = {
  category: MessageCategory;
};

function MessageCategoryIcon({ category }: MessageCategoryIconProps) {
  let icon: JSX.Element | null = null;
  switch (category) {
    case MessageCategory.KUDOS:
      icon = <MilestoneIcon />;
      break;
    case MessageCategory.UPSELLING:
      icon = <UpsellingIcon />;
      break;
    case MessageCategory.BILLING:
      icon = <BillingIcon />;
      break;
    case MessageCategory.SYSTEM:
      icon = <SystemIcon />;
      break;
    case MessageCategory.EARLY_ACCESS:
      icon = <EarlyAccessIcon />;
      break;
    case MessageCategory.VPP:
      icon = <VppIcon />;
      break;
    case MessageCategory.ACCOUNT:
      icon = <AccountIcon />;
      break;
    default:
      icon = null;
  }
  return <IconContainer>{icon}</IconContainer>;
}
export default MessageCategoryIcon;
