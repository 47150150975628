import { FaqType, UserType } from 'api/types';

export function filterFAQs(faqs: FaqType[] | null, user?: UserType | null, searchQuery?: string) {
  const agreements = user?.agreements;

  if (!agreements?.length) return [];

  const userProductTypes = new Set(
    agreements.map((agreement) => agreement.product_type.toLowerCase()),
  );

  return faqs
    ?.sort((a, b) => a.order - b.order)
    .filter((faq) => faq.tags.some((tag) => userProductTypes.has(tag.tag.toLowerCase())))
    .filter((faq) => !faq.hidden)
    .filter(
      (faq) =>
        searchQuery === undefined ||
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase()),
    );
}

export default filterFAQs;
