import pages from 'pages';

export enum MessageCategory {
  KUDOS = 'kudos',
  UPSELLING = 'upselling',
  BILLING = 'billing',
  SYSTEM = 'system',
  EARLY_ACCESS = 'early_access',
  VPP = 'vpp',
  ACCOUNT = 'account',
}

export const deeplinks = {
  prePto: {
    '/support': `/${pages.SUPPORT}`,
    '/status': `/${pages.STATUS}`,
  },
  postPto: {
    '/billing': `/${pages.DASHBOARD}/${pages.BILLING}`,
    '/system': `/${pages.DASHBOARD}/${pages.SYSTEM}`,
    '/support': `/${pages.DASHBOARD}/${pages.SUPPORT}`,
    '/status': `/${pages.STATUS}`,
  },
};
export type SupportedDeeplinks = keyof typeof deeplinks.prePto | keyof typeof deeplinks.postPto;
export type SupportedPrePtoDeeplinks = keyof typeof deeplinks.prePto;
export type SupportedPostPtoDeeplinks = keyof typeof deeplinks.postPto;

export interface InboxMessageType {
  id: string;
  user: string;
  name: string;
  category: MessageCategory;
  agreement: string;
  subject: string;
  body: string;
  markdown_body: string | null;
  read: boolean;
  deeplink: SupportedDeeplinks | string | null;
  cta_title: string | null;
  date_sent: Date;
}

export interface InboxMessagesPaginatedResponseType {
  count: number;
  next: string | null;
  previous: string | null;
  results: InboxMessageType[];
  total_unread_sent_inbox_messages: number;
}
