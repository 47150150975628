import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AnswerNetVideoTPVStatusType, WelcomeChecklistStatusType } from 'modules/types';
import { formatPhoneNumber } from 'shared/utils/formatPhoneNumber';
import { PHONE_NUMBERS } from 'constants/phoneNumbers';
import {
  isWelcomeChecklistFailed,
  isWelcomeChecklistPassed,
  isWelcomeChecklistRequired,
} from 'modules/status/utils/getWelcomeChecklistStatus';
import {
  BodyText,
  BodyTextBold,
  ProjectQualificationCopyWrapper,
} from './ProjectQualification.styles';

type Props = {
  welcomeChecklistStatus: WelcomeChecklistStatusType;
  isCurrentMilestone: boolean;
  tpvStatus: AnswerNetVideoTPVStatusType | undefined;
  shouldShowDWCContent: boolean;
};
export function ProjectQualificationCopy({
  welcomeChecklistStatus,
  isCurrentMilestone,
  tpvStatus,
  shouldShowDWCContent,
}: Props) {
  const { t } = useTranslation();

  const installerSteps: string[] = t('projectQualification.installerSteps', {
    returnObjects: true,
  });

  const installerStepsTpv: string[] = t('projectQualification.installerStepsTpv', {
    returnObjects: true,
  });

  const welcomeChecklistRequired = isWelcomeChecklistRequired(welcomeChecklistStatus!);
  const welcomeChecklistFail = isWelcomeChecklistFailed(welcomeChecklistStatus!);
  const welcomeChecklistPass = isWelcomeChecklistPassed(welcomeChecklistStatus!);

  let checklistStatus: React.ReactNode | null = null;

  if (isCurrentMilestone && shouldShowDWCContent) {
    if (welcomeChecklistRequired) {
      checklistStatus = (
        <BodyTextBold as="p">{t('projectQualification.completeWelcomeChecklist')}</BodyTextBold>
      );
    }

    if (welcomeChecklistPass) {
      checklistStatus = (
        <BodyText as="p">
          <BodyTextBold as="p">{t('projectQualification.welcomeChecklistCompleted')}</BodyTextBold>
        </BodyText>
      );
    }

    if (welcomeChecklistFail) {
      checklistStatus = (
        <BodyText as="p">
          <Trans
            i18nKey="projectQualification.welcomeChecklistFail"
            values={{ phoneNumber: formatPhoneNumber(PHONE_NUMBERS.CUSTOMER_SUPPORT) }}
            components={{
              strong: <BodyTextBold as="p" />,
            }}
          />
        </BodyText>
      );
    }
  }
  const tpvStatusVerified = tpvStatus === AnswerNetVideoTPVStatusType.VERIFIED;
  const tpvStatusPending = tpvStatus === AnswerNetVideoTPVStatusType.PENDING;
  const tpvStatusFailed = tpvStatus === AnswerNetVideoTPVStatusType.FAILED;
  let tpvStatusText: React.ReactNode | null = null;
  let bodyTextTpv: React.ReactNode | null = null;
  let closingTextTpv: React.ReactNode | null = null;

  if (!shouldShowDWCContent && (tpvStatusPending || tpvStatus === undefined)) {
    tpvStatusText = (
      <BodyTextBold as="p">{t('projectQualification.pendingTpvStatusHeader')}</BodyTextBold>
    );
    bodyTextTpv = <BodyText as="p">{t('projectQualification.pendingTpvStatusBody')}</BodyText>;
    closingTextTpv = <BodyText as="p">{t('projectQualification.welcomeToEverBright')}</BodyText>;
  } else if (tpvStatusVerified) {
    tpvStatusText = (
      <BodyTextBold as="p">{t('projectQualification.verifiedTpvStatusHeader')}</BodyTextBold>
    );
    bodyTextTpv = (
      <BodyText as="p">{t('projectQualification.installerWillGetSystemReadyTpv')}</BodyText>
    );
    closingTextTpv = <BodyText as="p">{t('projectQualification.welcomeToEverBright')}</BodyText>;
  } else if (tpvStatusFailed) {
    bodyTextTpv = (
      <BodyText as="p">
        <Trans
          i18nKey="projectQualification.failedTpvStatus"
          values={{ phoneNumber: formatPhoneNumber(PHONE_NUMBERS.CUSTOMER_SUPPORT) }}
          components={{
            strong: <BodyTextBold as="p" />,
          }}
        />
      </BodyText>
    );
  }

  return (
    <ProjectQualificationCopyWrapper>
      <BodyText as="p">
        <span>{t('projectQualification.excitedToJoin')}</span>
      </BodyText>

      {checklistStatus}
      {tpvStatusText}

      {shouldShowDWCContent && (
        <BodyText as="p">{t('projectQualification.installerWillGetSystemReady')}</BodyText>
      )}
      {bodyTextTpv}
      {shouldShowDWCContent && (
        <ul>
          {Array.isArray(installerSteps) &&
            installerSteps.map((text) => (
              <li key={text}>
                <BodyText as="p">{text}</BodyText>
              </li>
            ))}
        </ul>
      )}

      {tpvStatusVerified && (
        <ul>
          {Array.isArray(installerStepsTpv) &&
            installerStepsTpv.map((text) => (
              <li key={text}>
                <BodyText as="p">{text}</BodyText>
              </li>
            ))}
        </ul>
      )}

      {!welcomeChecklistFail && shouldShowDWCContent && (
        <BodyText as="p">{t('projectQualification.welcomeToEverBright')}</BodyText>
      )}
      {closingTextTpv}
    </ProjectQualificationCopyWrapper>
  );
}

export default ProjectQualificationCopy;
