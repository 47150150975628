import { DateRangeZoomLevel } from 'api/types';
import { Tuple } from 'modules/types';
import { FONT_FAMILY } from 'shared/components/Text/Text';
import { Sizes } from 'shared/design-system/theme/grid';
import { DefaultTheme } from 'styled-components';

const labelStyles = (theme: DefaultTheme, screenSize?: Sizes) => ({
  fontFamily: FONT_FAMILY,
  fill: theme.colors.textPrimarySecondary1100,
  fontSize: screenSize === 'lg' || screenSize === 'xl' ? '16px' : '12px',
  fontWeight: '300',
  lineHeight: screenSize === 'lg' || screenSize === 'xl' ? '24px' : '16px',
});

export const getDayChartStyles = (theme: DefaultTheme, screenSize?: Sizes) => {
  const appliedLabelStyles = labelStyles(theme, screenSize);
  const largeStyles = {
    height: 430,
    width: 832,
    domainPadding: { x: 0, y: 26 },
    xAxis: { offsetY: 26 },
    padding: { top: 0, right: 24, left: 36, bottom: 0, domainY: 0 },
    tooltip: { lineHeight: 402, y2: 0 },
    label: {
      x: 28,
      y: 8,
      dx: 0,
      dy: 0,
      textAnchor: 'end',
      style: appliedLabelStyles,
    },
    tickLabels: appliedLabelStyles,
    unitLabel: { fontWeight: '700', paddingBottom: '12px' },
  };
  const medStyles = {
    ...largeStyles,
    height: 352,
    width: 700,
    domainPadding: { x: 14, y: 26 },
    xAxis: { offsetY: 26 },
    padding: { top: 0, right: 0, left: 0, bottom: 0 , domainY: 0 },
    tooltip: { lineHeight: 330, y2: 0 },
    label: {
      ...largeStyles.label,
      x: 18,
      y: 20,
    },
  };
  const smallStyles = {
    ...largeStyles,
    height: 247,
    width: 300,
    domainPadding: { x: 14, y: 26 },
    xAxis: { offsetY:  26},
    padding: { top: 0, right: 0, left: 0, bottom: 0, domainY: 0 },
    tooltip: { lineHeight: 192, y2: 0 },
    label: {
      ...largeStyles.label,
      x: 18,
      y: 8,
    },
  };
  switch (screenSize) {
    case 'xl':
      return largeStyles
    case 'lg':
      return largeStyles;
    case 'md':
      return medStyles;
    case 'sm':
      return smallStyles;
    default:
      return largeStyles;
  }
};

const adjustStylesForZoomLevel = (
  chartStyles: Record<string, any>,
  screenSize: Sizes,
  zoomLevel: DateRangeZoomLevel,
) => {
  if (zoomLevel === DateRangeZoomLevel.DAY || zoomLevel === DateRangeZoomLevel.WEEK)
    return chartStyles;

  const newChartStyles = { ...chartStyles };
  if (zoomLevel === DateRangeZoomLevel.MONTH) {
    newChartStyles.barWidth = 12;
    switch (screenSize) {
      case 'sm':
        newChartStyles.barWidth = 5;
        newChartStyles.domainPadding.x = [40, 8];
        newChartStyles.yTickLabelOffset = { x: -30, y: 15 };
        break;
      case 'md':
        newChartStyles.domainPadding.x = [42, 12];
        newChartStyles.yTickLabelOffset = { x: -30, y: 15 };
        break;
      default:
        newChartStyles.domainPadding.x = [10, 10];
        newChartStyles.yTickLabelOffset = { x: -48, y: 0 };
        newChartStyles.padding.left = 48;
        break;
    }
  }
  if (zoomLevel === DateRangeZoomLevel.YEAR) {
    newChartStyles.barWidth = 35;
    switch (screenSize) {
      case 'sm':
        newChartStyles.barWidth = 11;
        newChartStyles.domainPadding.x = [58, 8];
        newChartStyles.yTickLabelOffset = { x: -48, y: 15 };
        break;
      case 'md':
        newChartStyles.domainPadding.x = [72, 20];
        newChartStyles.yTickLabelOffset = { x: -60, y: 15 };
        break;
      default:
        newChartStyles.domainPadding.x = [20, 20];
        newChartStyles.padding = { ...newChartStyles.padding, left: 62 };
        newChartStyles.yTickLabelOffset = { x: -71, y: 0 };
        break;
    }
  }
  return newChartStyles;
};

export const getChartStyles = (
  theme: DefaultTheme,
  screenSize: Sizes,
  zoomLevel: DateRangeZoomLevel,
) => { 
  const appliedLabelStyles = labelStyles(theme, screenSize);
  let chartStyles;

  const largeStyles = {
    height: 460,
    width: 832,
    spinnerHeight: 250,
    barWidth: 65,
    domainPadding: { x: [34, 36] as Tuple<number>, y: 0 },
    xAxis: { offsetY: 26 },
    padding: { top: 24, right: 6, left: 34, bottom: 26, domainY: 0 },
    tooltip: { lineHeight: 460 - 26, y2: 0 },
    label: {
      x: 33,
      y: 8,
      dx: 0,
      dy: 0,
      textAnchor: 'end',
      style: appliedLabelStyles,
    },
    tickLabels: appliedLabelStyles,
    unitLabel: { fontWeight: '700' },
    yTickLabelOffset: { x: -58, y: 0 },
  };
  const medStyles = {
    ...largeStyles,
    height: 372,
    width: 700,
    domainPadding: { x: [62, 34] as Tuple<number>, y: 0 },
    padding: { top: 24, right: 0, left: 0, bottom: 26, domainY: 0 },
    tooltip: { lineHeight: 372 - 26, y2: 0 },
    label: {
      ...largeStyles.label,
      x: 25,
      y: 10,
    },
    yTickLabelOffset: { x: -50, y: 15 },
  };
  const smallStyles = {
    ...largeStyles,
    height: 247,
    width: 350,
    barWidth: 22,
    domainPadding: { x: [48, 14] as Tuple<number>, y: 0 },
    padding: { top: 24, right: 10, left: 8, bottom: 26, domainY: 0 },
    tooltip: { lineHeight: 247 - 26, y2: 0 },
    label: {
      ...largeStyles.label,
      x: 32,
      y: 8,
    },
    yTickLabelOffset: { x: -37, y: 15 },
  };
  switch (screenSize) {
    case 'xl':
      chartStyles = largeStyles;
      break;
    case 'lg':
      chartStyles = largeStyles;
      break;
    case 'md':
      chartStyles = medStyles;
      break;
    case 'sm':
      chartStyles = smallStyles;
      break;
    default:
      chartStyles = largeStyles;
  }

  // final adjustments based on zoom level for month/year
  return adjustStylesForZoomLevel(chartStyles, screenSize, zoomLevel);
};
