import axios, { AxiosError, AxiosPromise } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

import routes from 'routes';
import {
  CACHE_STALE_TIME,
  MonitoringQueryKey,
  REFETCH_INTERVAL,
} from 'modules/system/utils/prefetchQueries';
import { SystemType } from 'api/types';
import { DateRange } from './utils/DateRange';
import { getRequestParams } from './utils/getRequestParams';
import {
  DateRangeZoomLevel,
  EnergyUnit,
  PowerFlowDataResponse,
  PowerUnit,
  SiteTimezoneResponse,
} from './types';
import {
  EnergyAllocationType,
  EnergySummaryType,
  ProductionSummary,
  QualitySummary,
  RawMonitoringDatumType,
  SystemCategory,
  VppDetails,
} from './monitoringTypes';

export type GetMonitoringRequestParams = {
  systemId: string;
  dateRange?: DateRange | null;
};

export function patchSystem(id: string, body: {}) {
  return axios.patch(routes.system(id), body);
}

export function backfillWorkflows(workflow_ids: string[]) {
  return axios.post(routes.backfillWorkflows, { workflow_ids });
}

export function getPowerFlow(systemId: string): AxiosPromise<PowerFlowDataResponse> {
  return axios.get(routes.powerFlow(systemId));
}

export function getSiteTimezone(systemId: string): AxiosPromise<SiteTimezoneResponse> {
  return axios.get(routes.siteTimezone(systemId));
}

export function getSolarPower({
  systemId,
  dateRange,
}: GetMonitoringRequestParams): AxiosPromise<RawMonitoringDatumType<PowerUnit>[]> {
  return axios.get(routes.solarPower(systemId), {
    params: getRequestParams(dateRange),
  });
}

export function getSolarEnergy({
  systemId,
  dateRange,
}: GetMonitoringRequestParams): AxiosPromise<RawMonitoringDatumType<EnergyUnit>[]> {
  return axios.get(routes.solarEnergy(systemId), {
    params: getRequestParams(dateRange),
  });
}

export function getSolarEnergyWithEnergyAllocation({
  systemId,
  dateRange,
}: GetMonitoringRequestParams): AxiosPromise<{
  monitoring_data: RawMonitoringDatumType<EnergyUnit>[];
  energy_allocation: EnergyAllocationType;
  production_summary: ProductionSummary;
}> {
  return axios.get(routes.solarEnergy(systemId), {
    params: { ...getRequestParams(dateRange), include_energy_allocation: 'true' },
  });
}

export type GetSoloarEnergyWithEnergyAllocationResult = {
  monitoring_data: RawMonitoringDatumType<EnergyUnit>[];
  energy_allocation: EnergyAllocationType;
  production_summary: ProductionSummary;
};

export const useGetSolarEnergyWithEnergyAllocation = ({
  systemId,
  dateRange,
}: {
  systemId?: string;
  dateRange?: DateRange;
}): UseQueryResult<GetSoloarEnergyWithEnergyAllocationResult, AxiosError> =>
  useQuery(
    [MonitoringQueryKey.ENERGY_TODAY_WITH_ENERGY_ALLOCATION, systemId, dateRange],
    () =>
      axios
        .get(routes.solarEnergy(systemId!), {
          params: { ...getRequestParams(dateRange), include_energy_allocation: true },
        })
        .then((res) => res.data),
    {
      enabled: !!systemId && !!dateRange,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );

export function getEnergySummary(id: string): AxiosPromise<EnergySummaryType> {
  return axios.get(routes.energySummary(id));
}

export const useGetSiteTimezone = (
  system?: SystemType,
): UseQueryResult<SiteTimezoneResponse, AxiosError> =>
  useQuery(
    [MonitoringQueryKey.TIMEZONE, system],
    () => axios.get(routes.siteTimezone(system?.id!)).then((res) => res.data),
    {
      enabled: Boolean(system?.id && !system.site_timezone),
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );

export type GetPowerWithAllocationResult = {
  monitoring_data: RawMonitoringDatumType<PowerUnit>[];
  energy_allocation: EnergyAllocationType;
  production_summary: ProductionSummary;
  quality_summary?: QualitySummary;
};

export const useGetPowerWithAllocation = ({
  systemId,
  category,
  dateRange,
}: {
  systemId?: string;
  category: SystemCategory;
  dateRange?: DateRange;
}): UseQueryResult<GetPowerWithAllocationResult, AxiosError> =>
  useQuery(
    [MonitoringQueryKey.POWER_WITH_ALLOCATION, systemId, category, dateRange],
    () =>
      axios
        .get(routes.powerWithAllocation(systemId!, category), {
          params: { ...getRequestParams(dateRange), include_energy_allocation: true },
        })
        .then((res) => res.data),
    {
      enabled: !!systemId && dateRange && dateRange.zoomLevel === DateRangeZoomLevel.DAY,
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );

export type GetEnergyWithAllocationResult = {
  monitoring_data: RawMonitoringDatumType<EnergyUnit>[];
  energy_allocation: EnergyAllocationType;
  production_summary: ProductionSummary;
  quality_summary?: QualitySummary;
};

export const useGetEnergyWithAllocation = ({
  systemId,
  category,
  dateRange,
}: {
  systemId: string;
  category: SystemCategory;
  dateRange: DateRange;
}): UseQueryResult<GetEnergyWithAllocationResult, AxiosError> =>
  useQuery(
    [MonitoringQueryKey.ENERGY_TODAY_WITH_ENERGY_ALLOCATION, systemId, category, dateRange],
    () =>
      axios
        .get(routes.energyWithAllocation(systemId, category), {
          params: { ...getRequestParams(dateRange), include_energy_allocation: true },
        })
        .then((res) => res.data),
    {
      enabled: !!systemId && dateRange && dateRange.zoomLevel !== DateRangeZoomLevel.DAY,
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );

export function getVppDetails({ systemId }: GetMonitoringRequestParams): AxiosPromise<VppDetails> {
  return axios.get(routes.vppDetails(systemId), {});
}

export const useGetVppDetails = ({
  systemId,
}: GetMonitoringRequestParams): UseQueryResult<VppDetails, AxiosError> =>
  useQuery(
    [MonitoringQueryKey.VPP_DETAILS, systemId],
    () => getVppDetails({ systemId }).then((res) => res.data),
    {
      enabled: !!systemId,
      refetchInterval: REFETCH_INTERVAL,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: CACHE_STALE_TIME,
    },
  );
