import React from 'react';
import { useTranslation } from 'react-i18next';
import { resolvePath } from 'react-router';
import { useTheme } from 'styled-components';

import { pages } from 'pages';
import { Card } from 'shared/components/Card/Card';
import { BoltLightIcon } from 'shared/components/icons/BoltLightIcon';
import { IconPosition } from 'shared/components/Card/Card.styles';

import { EnergyUnit, RawMonitoringDatumType, SystemType } from 'api/types';

import { EnergyAllocationType, SystemCategory } from 'api/system/monitoringTypes';
import { SystemCardBody, SystemCardWrapper } from './SystemCard.styles';
import SystemCardTotalOutputText from './SystemCardTotalOutputText';
import { SystemCardOutputsWrapper } from './SystemCardOutputsSection.styles';
import SystemCardIndividualOutput from './SystemCardIndividualOutput';

const aggregateSolarEnergyData = (data: RawMonitoringDatumType<EnergyUnit>[]): string =>
  data
    .reduce(
      (acc: number, _data: RawMonitoringDatumType<EnergyUnit>) => acc + (_data?.value || 0),
      0,
    )
    .toFixed(1);

type SectionOutputItem = {
  title: string;
  value: string;
};

type CardDetail = {
  title: string;
  totalOutput?: string;
  sectionOutput: SectionOutputItem[];
};

type CardDetailsObj = {
  // [key in SystemCategory]: CardDetail;
  [key: string]: CardDetail;
};

type SystemCardProps = {
  system: SystemType | undefined;
  category: SystemCategory;
  energyAllocation?: EnergyAllocationType;
  monitoringData?: RawMonitoringDatumType<EnergyUnit>[];
  routeToSystemPage: boolean;
};

export function SystemCard({
  system,
  category,
  energyAllocation,
  monitoringData,
  routeToSystemPage,
}: SystemCardProps) {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const solarEnergyKwhToday = monitoringData ? aggregateSolarEnergyData(monitoringData) : '';

  const gridEnergyKwhToday =
    energyAllocation && system?.has_grid_monitoring
      ? (energyAllocation.production_to_grid_wh / 1000).toFixed(1)
      : '';
  const homeEnergyKwhToday =
    energyAllocation && system?.has_home_monitoring
      ? (energyAllocation.production_to_home_wh / 1000).toFixed(1)
      : '';
  const batteryEnergyKwhToday =
    energyAllocation && system?.has_battery_monitoring
      ? (energyAllocation.production_to_battery_wh / 1000).toFixed(1)
      : '';

  const batteryDischarge = energyAllocation
    ? (energyAllocation.battery_discharge_total_wh / 1000).toFixed(1)
    : '';
  const batteryCharged = energyAllocation
    ? (energyAllocation.battery_charge_total_wh / 1000).toFixed(1)
    : '';

  const gridImported = energyAllocation
    ? (energyAllocation.grid_import_total_wh / 1000).toFixed(1)
    : '';
  const gridExported = energyAllocation
    ? (energyAllocation.grid_export_total_wh / 1000).toFixed(1)
    : '';

  const fromBattery = energyAllocation
    ? (energyAllocation.consumption_from_battery_wh / 1000).toFixed(1)
    : '';
  const fromGrid = energyAllocation
    ? (energyAllocation.consumption_from_grid_wh / 1000).toFixed(1)
    : '';
  const totalHomeConsumption = (Number(fromBattery) + Number(fromGrid)).toFixed(1);

  const cardDetailsObj: CardDetailsObj = {
    [SystemCategory.SOLAR]: {
      title: t('system.totalSolarProduction'),
      totalOutput: solarEnergyKwhToday,
      sectionOutput:
        system?.has_grid_monitoring && system.has_home_monitoring
          ? [
              {
                title: t('systemCard.homeHeader'),
                value: homeEnergyKwhToday,
              },
              ...(system && system.has_battery_monitoring
                ? [
                    {
                      title: t('systemCard.batteryHeader'),
                      value: batteryEnergyKwhToday,
                    },
                  ]
                : []),
              {
                title: t('systemCard.gridHeader'),
                value: gridEnergyKwhToday,
              },
            ]
          : [],
    },
    [SystemCategory.BATTERY]: {
      title: t('system.batteryPerformance'),
      sectionOutput:
        system?.has_battery_monitoring && system.has_home_monitoring
          ? [
              {
                title: t('system.totalDischarged'),
                value: batteryDischarge,
              },
              {
                title: t('system.totalCharged'),
                value: batteryCharged,
              },
            ]
          : [],
    },
    [SystemCategory.GRID]: {
      title: t('system.gridUsage'),
      sectionOutput: system?.has_grid_monitoring
        ? [
            { title: t('system.totalImported'), value: gridImported },
            { title: t('system.totalExported'), value: gridExported },
          ]
        : [],
    },
    [SystemCategory.HOME]: {
      title: t('system.totalConsumption'),
      totalOutput: totalHomeConsumption,
      sectionOutput: system?.has_home_monitoring
        ? [
            { title: t('system.fromBattery'), value: fromBattery },
            { title: t('system.fromGrid'), value: fromGrid },
          ]
        : [],
    },
  };

  const cardDetails = cardDetailsObj[category];

  return (
    <SystemCardWrapper>
      <Card
        title={cardDetails.title}
        icon={{
          element: <BoltLightIcon color={colors.iconIconPrimarySecondary1100} />,
          color: 'grey',
        }}
        iconPosition={IconPosition.NEXT}
        route={
          routeToSystemPage ? resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname : undefined
        }
      >
        <SystemCardBody>
          {cardDetails.totalOutput && <SystemCardTotalOutputText value={cardDetails.totalOutput} />}

          {cardDetails.sectionOutput.length ? (
            <SystemCardOutputsWrapper>
              {cardDetails.sectionOutput.map((item) => (
                <SystemCardIndividualOutput
                  key={item.title}
                  header={item.title}
                  value={item.value.toString()}
                  suffix={t('systemCard.kWh')}
                />
              ))}
            </SystemCardOutputsWrapper>
          ) : null}
        </SystemCardBody>
      </Card>
    </SystemCardWrapper>
  );
}

export default SystemCard;
