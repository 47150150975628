import React from 'react';
import { useTheme } from 'styled-components';
import { IIconProps } from '../TIcon';

export function UpsellingIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.iconIconPrimarySecondary1100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M5.04883 0C5.32383 0 5.54883 0.223875 5.54883 0.5V3.5H4.54883V0.5C4.54883 0.223875 4.77258 0 5.04883 0ZM11.0488 0C11.3238 0 11.5488 0.223875 11.5488 0.5V3.5H10.5488V0.5C10.5488 0.223875 10.7738 0 11.0488 0ZM13.5488 4.5C13.8238 4.5 14.0488 4.725 14.0488 5C14.0488 5.275 13.8238 5.5 13.5488 5.5H13.0488V7.5C13.0488 10.0656 11.077 12.225 8.54883 12.475V15.5C8.54883 15.775 8.32383 16 8.04883 16C7.77383 16 7.54883 15.775 7.54883 15.5V12.475C5.02227 12.225 3.04883 10.0656 3.04883 7.5V5.5H2.54883C2.2727 5.5 2.04883 5.275 2.04883 5C2.04883 4.725 2.2727 4.5 2.54883 4.5H13.5488ZM8.04883 11.5C10.2582 11.5 12.0488 9.70938 12.0488 7.5V5.5H4.04883V7.5C4.04883 9.70938 5.83945 11.5 8.04883 11.5Z"
        fill={color}
      />
    </svg>
  );
}

export default UpsellingIcon;
