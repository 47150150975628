/* eslint-disable class-methods-use-this */
import { differenceInCalendarMonths } from 'date-fns';

import { MonitoringDatum } from 'api/types';
import { DateRange } from 'api/system/utils/DateRange';
import { AggregatedData } from 'api/system/types';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { Sizes } from 'shared/design-system/theme/grid';
import { AnyChartDataProvider, YAxisLabel } from '../AnyChartDataProvider';
import { BatteryChartDataProvider } from './BatteryChartDataProvider';
import { YearChartDataProvider } from '../YearChartDataProvider';
import { ChartDataProvider } from '../ChartDataProvider';

export class BatteryYearChartDataProvider
  extends BatteryChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 300;

  static numberOfTicks = 12;

  barWidth = 10;

  shouldShowDateLabel = YearChartDataProvider.shouldShowDateLabel;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const monthlyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach((props) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { discharge_kwh, charge_kwh, time, unit } = props;
      if (!discharge_kwh || !charge_kwh) {
        return;
      }
      const newValue = Math.max(Math.abs(discharge_kwh), Math.abs(charge_kwh));
      largestProductionValue = Math.max(largestProductionValue, newValue);
      if (largestProductionValue === newValue) {
        largestProductionUnit = unit;
      }
      const monthNumber = differenceInCalendarMonths(time, dateRange.startDate);
      monthlyProduction.set(monthNumber, {
        time,
        y0: Math.abs(discharge_kwh),
        y: charge_kwh > 0.0 ? -1 * charge_kwh : 0.0,
        y0_raw: discharge_kwh,
        y_raw: charge_kwh,
        unit,
      });
    });
    return [monthlyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, YearChartDataProvider.numberOfTicks - 1],
    y: [
      -1 * (maximumValue || BatteryYearChartDataProvider.placeholderMaximumValue),
      maximumValue || BatteryYearChartDataProvider.placeholderMaximumValue,
    ],
  });

  tickXFormat = YearChartDataProvider.tickXFormat;

  domainPaddingX: PaddingType = YearChartDataProvider.domainXPadding;

  yTickLabelOffset = YearChartDataProvider.yTickLabelOffset;

  getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(YearChartDataProvider.numberOfTicks).keys());

  getXAxis = (range: DateRange, screenSize: Sizes): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (month: number) => this.tickXFormat(month, range, screenSize),
  });

  getTickValuesYAxis = ChartDataProvider.getTickValuesYAxis(5);

  getYAxis = <T>(maximumValue: number | null, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(Math.abs(value), unit),
  });

  tooltipDateFormatter = YearChartDataProvider.tooltipDateFormatter;

  getFlyoutLabels(t: (key: string) => string): { y: string; y0: string } {
    return {
      y0: t('system.labels.flyout.battery.discharged'),
      y: t('system.labels.flyout.battery.charged'),
    };
  }

  yAxisLabels(t: (key: string) => string): { top: YAxisLabel; bottom: YAxisLabel } {
    return {
      top: {
        title: t('system.labels.yaxis.battery.top.title'),
        subtitle: t('system.labels.yaxis.battery.top.subtitle'),
      },
      bottom: {
        title: t('system.labels.yaxis.battery.bottom.title'),
        subtitle: t('system.labels.yaxis.battery.bottom.subtitle'),
      },
    };
  }

  tooltipIndicatorHidden?: boolean | undefined = true;
}

export default BatteryYearChartDataProvider;
