import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { FullStory } from '@fullstory/browser';

import routes from 'routes';
import { login as loginApi, logout as logoutApi } from 'api/account';
import { AgreementType, LoginRequestBody, UserType } from 'api/types';
import { authenticatedKey } from 'constants/localStorageKeys';
import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { removeLocalStorageItems } from './removeLocalStorageItems';

/* intentional */
const noop = (): Promise<UserResult | undefined> => new Promise(() => {});
const noop2 = (): Promise<LoginReturnType> => new Promise(() => {});

interface UserResult {
  user: UserType | null;
  impersonator?: UserType;
  error?: Error | string;
  loading?: boolean;
  selectedAgreement?: AgreementType;
}

export type BillingFormType = {
  isEditing: boolean;
  addressId?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export type LoginReturnType = {
  error: boolean;
  user?: UserType;
  billingForm?: BillingFormType;
  requiresMFA?: boolean;
  errorData?: Record<string, string>;
};

interface UserContextProps {
  userResult?: UserResult;
  setUserResult: (UserResult: UserResult) => void;
  refetch: () => Promise<UserResult | undefined>;
  login: ({ email, password }: LoginRequestBody) => Promise<LoginReturnType>;
  logout: (props: { keepBillingFormState?: boolean }) => void;
  clearUserState: () => void;
  billingForm: BillingFormType;
  setBillingForm: (value: BillingFormType) => void;
}

const UserContext = React.createContext<UserContextProps>({
  userResult: undefined,
  setUserResult: noop,
  refetch: noop,
  login: noop2,
  logout: noop,
  clearUserState: noop,
  billingForm: { isEditing: false },
  setBillingForm: noop,
});

/**
 * Provider to store the user's current profile.
 */

const userResultDefault = {
  user: null,
  impersonator: undefined,
  error: undefined,
  loading: true,
  selectedAgreement: undefined,
};

const billingFormDefault: BillingFormType = {
  isEditing: false,
  addressId: undefined,
  street1: undefined,
  street2: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
};
interface Props {
  children: React.ReactNode;
}
interface MeResponse {
  user: UserType | null;
  impersonator?: UserType;
}

function UserProvider({ children }: Props) {
  const authenticated = localStorage.getItem(authenticatedKey);
  // Store the current user in state
  const [userResult, setUserResult] = useState<UserResult>(userResultDefault);
  const [billingForm, setBillingForm] = useState<BillingFormType>(billingFormDefault);

  const asyncSetUserResult = useCallback(async () => {
    let payload: UserResult = {
      user: null,
      impersonator: undefined,
      error: undefined,
      loading: false,
      selectedAgreement: undefined,
    };
    if (authenticated) {
      try {
        const response: AxiosResponse<MeResponse, any> = await axios.get(routes.me);

        const { user, impersonator } = response.data;
        const agreements = user?.agreements;

        const selectedAgreement = agreements?.[0];

        payload = {
          user,
          impersonator,
          error: undefined,
          loading: false,
          selectedAgreement,
        };

        const name = `${user?.first_name} ${user?.last_name}`;
        const userEmail = user?.email;
        const email = impersonator ? `${impersonator.email} as ${userEmail}` : userEmail;
        datadogRum.setUser({
          id: user?.id,
          email,
          name,
          role: user?.role,
        });

        const currentMilestone = selectedAgreement?.current_milestone;
        const { hasDashboardAccess } = getDashboardRedirectPath(user, selectedAgreement);
        FullStory('setIdentity', {
          uid: user?.id,
          properties: {
            authCopyAcknowledgementRequired: !selectedAgreement?.authoritative_copy_acknowledged,
            currentMilestone,
            displayName: name,
            email,
            pto: hasDashboardAccess,
            role: user?.role,
          },
        });
      } catch (e) {
        let error: Error | string = 'Unknown Error';

        if (e instanceof Error) error = e;

        payload = { ...userResultDefault, error, loading: false };
      }
    }
    setUserResult(payload);
    return payload;
  }, [authenticated]);

  // Fetch the user when this component is rendered
  useEffect(() => {
    asyncSetUserResult();
  }, [asyncSetUserResult]);

  const login = useCallback(
    async ({ email, password }: LoginRequestBody): Promise<LoginReturnType> => {
      try {
        const response = await loginApi({ email, password });
        localStorage.setItem(authenticatedKey, JSON.stringify(true));
        const { user, requires_MFA: requiresMFA } = response.data;

        if (requiresMFA) {
          return { error: false, requiresMFA };
        }

        const billingFormResult =
          billingForm.isEditing && billingForm.addressId !== user?.address?.id
            ? billingFormDefault
            : billingForm;

        setBillingForm(billingFormResult);

        setUserResult({
          user,
          loading: false,
          selectedAgreement: user?.agreements[0],
        });
        return {
          error: false,
          user: response.data,
          billingForm: billingFormResult,
        };
      } catch (e) {
        const error = e as AxiosError<{ message: string }>;
        return { error: true, errorData: error.response?.data };
      }
    },
    [billingForm],
  );

  const resetUserState = () => {
    removeLocalStorageItems();
    const resetUser = { ...userResultDefault, loading: false };
    setUserResult(resetUser);
    datadogRum.clearUser();
  };

  /**
   * Clear all user state.
   * Called when the session has already been expired on the BE and we want to return user to the login page.
   */
  const clearUserState = useCallback(resetUserState, []);

  const logout = useCallback(
    async ({ keepBillingFormState }: { keepBillingFormState?: boolean } = {}) => {
      try {
        await logoutApi();

        if (!keepBillingFormState) {
          setBillingForm(billingFormDefault);
          resetUserState();
        } else {
          removeLocalStorageItems();
          datadogRum.clearUser();
        }
      } catch (e) {
        // TODO(bzuhair): no-op catch block so that an error doesn't crash
        //  the app until error handling is implemented in HOP-536
      }
    },
    [],
  );

  const value = useMemo(() => {
    const refetch = async () => asyncSetUserResult();

    return {
      userResult,
      setUserResult,
      refetch,
      login,
      logout,
      clearUserState,
      billingForm,
      setBillingForm,
    };
  }, [userResult, login, logout, clearUserState, billingForm, asyncSetUserResult]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export { UserProvider, UserContext };
