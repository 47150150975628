import {
  subDays,
  addDays,
  subWeeks,
  addWeeks,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isBefore,
  isAfter,
  startOfWeek,
  startOfMonth,
  startOfYear,
  isSameYear,
  isSameMonth,
  isSameWeek,
} from 'date-fns';

export enum TimescaleEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const showArrows = ({
  currentDate,
  inServiceDate,
  timescale,
}: {
  currentDate: Date;
  inServiceDate: Date;
  timescale: TimescaleEnum;
}): { leftArrow: boolean; rightArrow: boolean } => {
  const date = new Date(currentDate);
  const today = new Date();

  let prevDate: Date;
  let nextDate: Date;
  let leftArrow: boolean;
  let rightArrow: boolean;

  switch (timescale) {
    case TimescaleEnum.DAY:
      prevDate = subDays(date, 1);
      nextDate = addDays(date, 1);
      leftArrow = !isBefore(prevDate, inServiceDate);
      rightArrow = !isAfter(nextDate, today);
      break;

    case TimescaleEnum.WEEK:
      prevDate = startOfWeek(subWeeks(date, 1));
      nextDate = startOfWeek(addWeeks(date, 1));
      rightArrow = isAfter(nextDate, today) ? isSameWeek(nextDate, today) : true;
      leftArrow = isBefore(date, inServiceDate) ? isSameWeek(date, inServiceDate) : true;

      break;

    case TimescaleEnum.MONTH:
      prevDate = startOfMonth(subMonths(date, 1));
      nextDate = startOfMonth(addMonths(date, 1));
      rightArrow = isAfter(nextDate, today) ? isSameMonth(nextDate, today) : true;
      leftArrow = isBefore(date, inServiceDate) ? isSameMonth(date, inServiceDate) : true;
      break;

    case TimescaleEnum.YEAR:
      prevDate = startOfYear(subYears(date, 1));
      nextDate = startOfYear(addYears(date, 1));
      rightArrow = isAfter(nextDate, today) ? isSameYear(nextDate, today) : true;
      leftArrow = isBefore(prevDate, inServiceDate) ? isSameYear(prevDate, inServiceDate) : true;
      break;

    default:
      throw new Error('Invalid timescale');
  }

  return { leftArrow, rightArrow };
};

export default showArrows;
