import styled from 'styled-components';
import { Text } from 'shared/components/Text/Text';

export const SystemCardIndividualOutputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - 12px);
  align-items: center;
`;

export const SystemCardIndividualOutputHeader = styled(Text)`
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const SystemCardIndividualOutputValue = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
`;

export const SystemCardIndividualOutputValueSuffix = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
`;
