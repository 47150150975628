/* eslint-disable class-methods-use-this */
import { addMonths, format } from 'date-fns';
import { capitalize } from 'lodash';

import { Sizes } from 'shared/design-system/theme/grid';
import { DateRange } from 'api/system/utils/DateRange';
import { PaddingType } from 'modules/types';

export class YearChartDataProvider {
  static placeholderMaximumValue = 300;

  static numberOfTicks = 12;

  static barWidth = 10;

  static domainXPadding: PaddingType = [70, 10];

  static yTickLabelOffset = {
    x: -60,
    y: 15,
  };

  static shouldShowDateLabel = (days: number, dateRange: DateRange): boolean => {
    const dayOfLastDayInMonth = new Date(
      dateRange.startDate.getFullYear(),
      dateRange.startDate.getMonth() + 1,
      0,
    ).getDate();
    if (days === dayOfLastDayInMonth) {
      return true;
    }
    if (days === 1) {
      return true;
    }
    if (days < 28 && (days - 1) % 7 === 0) {
      return true;
    }
    return false;
  };

  static tickXFormat = (month: number, range: DateRange, screenSize: Sizes): string => {
    const date = addMonths(range.startDate, month);
    const abbreviation = format(date, 'MMM').substring(0, screenSize === 'sm' ? 1 : 3);
    const initialCaseAbbreviation = abbreviation.charAt(0).toUpperCase() + abbreviation.slice(1);
    return initialCaseAbbreviation;
  };

  static tooltipDateFormatter = (date: Date): string =>
    capitalize(format(date, 'MMMM').toLowerCase());
}

export default YearChartDataProvider;
