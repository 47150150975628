import { addDays, addMonths, addWeeks, addYears, set, startOfDay } from 'date-fns';
import { DateRangeZoomLevel } from '../types';

export enum DateRangeDirection {
  FORWARD = 1,
  BACKWARD = -1,
}

export const startOfWeek = (currentDate: Date): Date => addDays(currentDate, -currentDate.getDay());

export const startOfMonth = (currentDate: Date): Date =>
  addDays(currentDate, -currentDate.getDate() + 1);

export const startOfYear = (currentDate: Date): Date => set(currentDate, { month: 0, date: 1 });

export enum DateRangeStartDateBehavior {
  FROM_DATE,
  FROM_CALENDAR_UNIT,
}

/*
We allow zoom levels of day, week, month and year
Our UI (native ios or android) only allows selecting a _date_ (month/day/year)
We also aggregate data, so that if you are in year mode (for example), we bucket
by month.

However, we also aggregate based on the _start_ of the month, rather than a random day
_within_ the month. If a user selects a 12-month range starting on Februrary 5, no data
will be returned for February. However, if we request starting on February 1, data _will_
show for February.

Below, only in the case that a zoom level is for year, we therefore pick the start of the
month that they are requesting.
*/
export const startDateForZoomLevel = (startDate: Date, zoomLevel: DateRangeZoomLevel) => {
  switch (zoomLevel) {
    case DateRangeZoomLevel.YEAR:
      return startOfMonth(startDate);
    default:
      return startDate;
  }
};

const upperBound = (date: Date, fn: (date: Date, amount: number) => Date) => {
  const previousDay = fn(date, 1);
  return previousDay;
};

export class DateRange {
  startDate: Date;

  endDate: Date;

  zoomLevel: DateRangeZoomLevel;

  systemTimezone: string;

  constructor(startDate: Date, zoomLevel: DateRangeZoomLevel, systemTimezone: string) {
    this.startDate = startDate;
    this.zoomLevel = zoomLevel;
    this.systemTimezone = systemTimezone;
    this.endDate = startDate;

    switch (zoomLevel) {
      case DateRangeZoomLevel.DAY:
        this.endDate = addDays(startDate, 1);
        break;
      case DateRangeZoomLevel.WEEK:
        // we need to add an hour, because the endpoints are not inclusive at the upper bound
        /*
        1. The range should not be inclusive of the first day of the next week, so adding 6 days
        2. We need to add a second, because the endpoints are not inclusive at the upper bound
        */
        this.endDate = upperBound(startDate, addWeeks);
        break;
      case DateRangeZoomLevel.MONTH:
        /*
        1. If the user selected February 10, the range should go to March 9. This is because our
        normal range is showing the entire month, rather than inclusive of the first day of the
        next month. 
        2. We need to add a second, because the endpoints are not inclusive at the upper bound
        */
        this.endDate = upperBound(startDate, addMonths);
        break;
      case DateRangeZoomLevel.YEAR:
        // we need to add an hour, because the endpoints are not inclusive at the upper bound
        // this.endDate = addHours(addDays(addYears(startDate, 1), -1), 1);
        this.endDate = upperBound(startDate, addYears);
        break;
      default:
        break;
    }
  }
}

export const incrementDateRange = (dateRange: DateRange, amount: number): DateRange => {
  let fn: (date: number | Date, amount: number) => Date;
  switch (dateRange.zoomLevel) {
    case DateRangeZoomLevel.DAY:
      fn = addDays;
      break;
    case DateRangeZoomLevel.WEEK:
      fn = addWeeks;
      break;
    case DateRangeZoomLevel.MONTH:
      fn = addMonths;
      break;
    case DateRangeZoomLevel.YEAR:
      fn = addYears;
      break;
    default:
      fn = addDays;
      break;
  }
  return new DateRange(
    fn(dateRange.startDate, amount),
    dateRange.zoomLevel,
    dateRange.systemTimezone,
  );
};

export const canIncrementDateRange = (
  dateRange: DateRange,
  direction: DateRangeDirection,
  dateInService: Date,
): boolean => {
  const newDateRange = incrementDateRange(dateRange, direction);
  if (direction === DateRangeDirection.BACKWARD) {
    return newDateRange.endDate >= dateInService;
  }
  return newDateRange.startDate < new Date();
};

export const createDateRange = (
  selectedStartDate: Date,
  zoomLevel: DateRangeZoomLevel,
  systemTimezone: string | undefined,
  behavior: DateRangeStartDateBehavior,
): DateRange | null => {
  if (!systemTimezone) {
    return null;
  }

  let startOf = startOfDay(selectedStartDate);
  let startDate = selectedStartDate;

  if (behavior === DateRangeStartDateBehavior.FROM_CALENDAR_UNIT) {
    switch (zoomLevel) {
      case DateRangeZoomLevel.DAY:
        /*
        below for testing, since I can't see anything
        till later in the day, since we pick our date
        range based on calendar day, rather than a
        trailing average. this leads to scenarios most
        of the time where the graph is empty.
        design call, not mine.
        */
        // startDate = addDays(midnightToday, -1);
        break;
      case DateRangeZoomLevel.WEEK:
        startOf = startOfWeek(selectedStartDate);
        startDate = startOfDay(startOf);
        break;
      case DateRangeZoomLevel.MONTH:
        startOf = startOfMonth(selectedStartDate);
        startDate = startOfDay(startOf);
        break;
      case DateRangeZoomLevel.YEAR:
        startOf = startOfYear(selectedStartDate);
        startDate = startOfDay(startOf);
        break;
      default:
        break;
    }
  }
  return new DateRange(startDate, zoomLevel, systemTimezone);
};
