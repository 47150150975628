import axios, { AxiosError, AxiosPromise } from 'axios';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import { E164Number } from 'libphonenumber-js/types';
import parsePhoneNumberFromString from 'libphonenumber-js';

import routes from 'routes';
import { RolesKey } from 'modules/types/util';
import { AddressBodyType, AddressType, UserFormType, UserStatsType, UserType } from 'api/types';
import { isHomeowner } from 'shared/utils/checkUserRole';

export function getUser(id: string): AxiosPromise<UserType> {
  return axios.get(routes.user(id));
}

type GetUsersRequestBody = {
  search?: string;
  page?: number;
  page_size?: number;
  sort_by?: string;
  direction?: 'ascending' | 'descending';
  internal_users?: boolean;
};

export function getUsers(body?: GetUsersRequestBody) {
  return axios.get(routes.users, { params: { ...body } });
}

type UnImpersonateUserResponse = {
  user: UserType;
  impersonator: UserType;
};

export async function unimpersonateUser(): AxiosPromise<UnImpersonateUserResponse> {
  await axios.post(routes.unimpersonate);
  const response = await axios.get(routes.me);

  return response;
}

export function impersonateUser(user_id: string) {
  return axios.post(routes.impersonate, { user_id });
}

export function updateUserPhone(user_id: string, phone_number: E164Number) {
  return axios.patch(routes.user(user_id), { phone_number });
}

export type UpdateUserRequestBody = {
  email?: string;
  phone_number?: E164Number;
  archived?: boolean;
  role?: RolesKey;
  address?: Partial<AddressBodyType>;
  research_opt_in?: boolean;
  privacy_policy_accepted?: boolean;
};

export type UpdateUserAddressRequestBody = {
  address?: Omit<AddressType, 'id' | 'archived'>;
};

export function updateUserBillingAddress({
  user_id,
  body,
}: {
  user_id: string;
  body: UpdateUserAddressRequestBody;
}) {
  return axios.patch(routes.user(user_id), body);
}

export type UpdateUserAddressConfirmedRequestBody = {
  address?: Partial<AddressType>;
};

export function updateUserBillingAddressConfirmation({
  user_id,
  confirmed,
}: {
  user_id: string;
  confirmed: UpdateUserAddressConfirmedRequestBody;
}) {
  return axios.patch(routes.user(user_id), confirmed);
}

export function updateUser({ user_id, body }: { user_id: string; body: UpdateUserRequestBody }) {
  const updatedBody = {
    ...body,
    ...(body.phone_number
      ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
      : {}),
  };
  return axios.patch(routes.user(user_id), updatedBody);
}

type UseUpdateUserBody = {
  onSuccess?: (data: UserType, variables: { user_id: string; body: UpdateUserRequestBody }) => void;
  onError?: (error: AxiosError, variabes: { user_id: string; body: UpdateUserRequestBody }) => void;
};

export const useUpdateUser = ({
  onSuccess,
  onError,
}: UseUpdateUserBody): UseMutationResult<
  UserType,
  AxiosError,
  { user_id: string; body: UpdateUserRequestBody }
> =>
  useMutation(
    ({ user_id, body }) => {
      const updatedBody = {
        ...body,
        ...(body.phone_number
          ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
          : {}),
      };
      return axios.patch(routes.user(user_id), updatedBody).then((res) => res.data);
    },
    {
      onSuccess,
      onError,
    },
  );

type UseInviteUserBody = {
  onSuccess?: (data: UserType) => void;
  onError?: (error: AxiosError<ErrorResponse>) => void;
};

type ErrorResponse = {
  detail?: string | string[];
  default_code?: string;
  error_codes?: string;
};

export const useInviteUser = ({
  onSuccess,
  onError,
}: UseInviteUserBody): UseMutationResult<unknown, AxiosError<ErrorResponse>, UserFormType> =>
  useMutation(
    (body: UserFormType) => {
      const updatedBody = {
        ...body,
        is_admin_invite: !isHomeowner(body.role),
        ...(body.phone_number
          ? { phone_number: parsePhoneNumberFromString(body.phone_number, 'US')?.format('E.164') }
          : {}),
      };

      return axios.post(routes.invite, updatedBody).then((res) => res.data);
    },
    {
      onSuccess,
      onError,
    },
  );

type UseImpersonateUserBody = {
  onSuccess?: (data: { user: UserType; impersonator: UserType }) => void;
  onError?: (error: AxiosError) => void;
};

export const useImpersonateUser = ({
  onSuccess,
  onError,
}: UseImpersonateUserBody): UseMutationResult<unknown, unknown, { user_id: string }> =>
  useMutation((body) => axios.post(routes.impersonate, body).then((res) => res.data), {
    onSuccess,
    onError,
  });

export const useResendClaimAccountEmail = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}): UseMutationResult<unknown, unknown, { email: string }> =>
  useMutation(
    ({ email }) =>
      axios.post(routes.sendClaimAccountEmail, { email, is_resend: true }).then((res) => res.data),
    {
      onSuccess,
      onError,
    },
  );

/* 
|
|       Bottom API CALLS are ONLY for admin dashboard       
|
*/

export const useGetUserStatistics = (): UseQueryResult<UserStatsType, unknown> =>
  useQuery(['userStatistics'], () => axios.get(routes.userStatistics).then((res) => res.data));
