/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { Trans, useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js/types';
import { useTheme } from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { resolvePath } from 'react-router';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { AxiosError } from 'axios';

import DataDogLogs from 'shared/logging/DataDogLogs';
import { Button } from 'shared/components/Button/Button';
import TextInput from 'shared/components/TextInput/TextInput';
import { updateUser } from 'api/user';
import { UserType } from 'api/types';
import { toast } from 'shared/components/Toast/Toast';
import Select from 'shared/components/Select/Select';
import { TDropdownDataItem } from 'shared/design-system/theme/dropdowns';
import { STATES } from 'constants/states';
import pages from 'pages';
import { validateCity } from 'modules/account/utils/validateCity';
import { PhoneInput } from 'shared/components/PhoneInput/PhoneInput';
import {
  BodyText,
  ButtonGroup,
  Form,
  FormWrapper,
  Header,
  Label,
  LinkStyled,
  StaticTextWrapper,
  Value,
} from '../Form.styles';
import { StateZipGroup, StaticPhoneNumber } from './ContactForm.styles';
import { getUpdatedData, IFormInput } from './utils/getUpdatedData';
import { getEmptyValues } from './utils/getEmptyValues';

interface ErrorResponse {
  detail: {
    phone_number: string[];
    address: {
      street_1: string[];
      city: string[];
      state: string[];
      zip_code: string[];
    };
  };
}

export type FieldChangeType = {
  isDirty: boolean;
  phoneNumber: E164Number;
  address: { street1: string; street2: string; city: string; state: string; zip: string };
};

type Props = {
  user: UserType;
  onCancel: () => void;
  onSave: () => void;
  showAccountInfo: Boolean;
  onFieldChange: (props: FieldChangeType) => void;
};

export const getDefaultStateOption = (
  value: string,
  options: TDropdownDataItem[],
): TDropdownDataItem | undefined => options.find((option) => option.value === value);

export function ContactForm({ user, onCancel, onSave, showAccountInfo, onFieldChange }: Props) {
  const [isPhoneValid, setIsPhoneValid] = useState(Boolean(user.phone_number));

  const { t } = useTranslation();
  const theme = useTheme();

  const initialValues = {
    phoneNumber: parsePhoneNumberFromString(
      user.phone_number,
      'US',
    )?.formatNational() as E164Number,
    street1: user.address.street_1,
    street2: user.address.street_2,
    city: user.address.city,
    state:
      getDefaultStateOption(
        user.address.state,
        STATES.map((state) => ({ title: state.text, value: state.value })),
      )?.value || '',
    zip: user.address.zip_code,
  };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isDirty },
    reset,
    getValues,
    watch,
  } = useForm<IFormInput>({
    defaultValues: initialValues,
  });

  const formValues = watch();

  useEffect(() => {
    onFieldChange({
      isDirty,
      phoneNumber: formValues.phoneNumber,
      address: {
        street1: formValues.street1,
        street2: formValues.street2,
        city: formValues.city,
        state: formValues.state,
        zip: formValues.zip,
      },
    });
  }, [
    formValues.city,
    formValues.phoneNumber,
    formValues.state,
    formValues.street1,
    formValues.street2,
    formValues.zip,
    getValues,
    isDirty,
    onFieldChange,
    watch,
  ]);

  const { mutate, isLoading } = useMutation({
    mutationFn: updateUser,
    onSuccess: async () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('account.successfullyUpdated'),
        theme,
      });
      await onSave();
      reset();
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data as ErrorResponse;

      if (errorData) {
        const addressError = errorData.detail.address;

        if (errorData.detail.phone_number) {
          setError('phoneNumber', { message: t('account.invalidPhoneNumber') });
        }
        if (addressError.street_1) {
          setError('street1', { message: t('account.invalidAddress') });
        }

        if (addressError.city) {
          setError('city', { message: t('account.invalidCity') });
        }

        if (addressError.zip_code) {
          setError('zip', { message: t('account.invalidZipCode') });
        }

        return;
      }

      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('account.unableToSave'),
        theme,
      });

      DataDogLogs.error(
        'Account - ContactForm',
        {
          user,
        },
        error,
      );
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (formData) => {
    if (!isDirty) return;

    if (!formData.state) {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('account.invalidState'),
        theme,
      });
      return;
    }
    // Simple validation to prevent empty inputs
    const emptyValues = getEmptyValues([
      { name: 'phoneNumber', value: formData.phoneNumber },
      { name: 'street1', value: formData.street1 },
      { name: 'city', value: formData.city },
      { name: 'state', value: formData.state },
      { name: 'zip', value: formData.zip.toString() },
    ]);

    if (emptyValues.length) {
      emptyValues.forEach((value) => {
        setError(value, { type: 'required', message: t('account.required') });
      });
      return;
    }

    const body = getUpdatedData(formData, initialValues);

    mutate({
      user_id: user.id,
      body,
    });
  };

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const stateOptions: TDropdownDataItem[] = useMemo(
    () => STATES.map((state) => ({ title: state.text, value: state.value })),
    [],
  );

  return (
    <FormWrapper>
      <Header as="h3">{t('account.primaryContact')}</Header>

      <StaticTextWrapper>
        <BodyText as="p">
          <Trans
            i18nKey="account.contactSupport"
            components={{
              a: <LinkStyled to={resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`)} />,
            }}
          />
        </BodyText>

        <div>
          <Label as="p">{t('account.name')}</Label>
          <Value as="p">
            {user.first_name} {user.last_name}
          </Value>
        </div>

        <div>
          <Label as="p">{t('account.email')} </Label>
          <Value as="p">{user.email}</Value>
        </div>
        {!showAccountInfo && (
          <StaticPhoneNumber>
            <Label as="p">{t('account.phoneNumber')}</Label>
            <Value as="p">
              {parsePhoneNumberFromString(user.phone_number, 'US')?.formatNational()}
            </Value>
          </StaticPhoneNumber>
        )}
      </StaticTextWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {showAccountInfo && (
          <PhoneInput
            id="contact-form-phone"
            ariaLabel={t('account.phoneNumber')}
            label={t('account.phoneNumber')}
            onValidate={setIsPhoneValid}
            defaultValue={parsePhoneNumberFromString(user.phone_number, 'US')?.formatNational()}
            hasError={Boolean(errors.phoneNumber)}
            errorMessage={errors.phoneNumber?.message}
            margin="16px 0 0 0"
            {...register('phoneNumber', {
              validate: () => {
                if (!isPhoneValid) {
                  return t('account.invalidPhoneNumber');
                }
                return true;
              },
            })}
          />
        )}
        {showAccountInfo ? (
          <>
            <TextInput
              id="contact-form-street1"
              ariaLabel={t('account.street1')}
              label={t('account.street1')}
              hasError={Boolean(errors.street1)}
              errorMessage={errors.street1?.message}
              {...register('street1', { required: t('account.addressRequired') })}
            />

            <TextInput
              id="contact-form-street2"
              ariaLabel={t('account.street2')}
              label={t('account.street2')}
              hasError={Boolean(errors.street2)}
              errorMessage={errors.street2?.message}
              {...register('street2')}
            />
          </>
        ) : (
          <>
            <TextInput
              id="contact-form-billing-street1"
              ariaLabel={t('account.billingStreet1')}
              label={t('account.billingStreet1')}
              hasError={Boolean(errors.street1)}
              errorMessage={errors.street1?.message}
              {...register('street1', { required: t('account.addressRequired') })}
            />

            <TextInput
              id="contact-form-billing-street2"
              ariaLabel={t('account.billingStreet2')}
              label={t('account.billingStreet2')}
              hasError={Boolean(errors.street2)}
              errorMessage={errors.street2?.message}
              {...register('street2')}
            />
          </>
        )}
        <TextInput
          id="contact-form-city"
          ariaLabel={t('account.city')}
          label={t('account.city')}
          hasError={Boolean(errors.city)}
          errorMessage={errors.city?.message}
          {...register('city', {
            required: t('account.cityRequired'),
            validate: (value: string) => validateCity(value, t),
          })}
        />

        <StateZipGroup>
          <Select
            id="contact-form-state"
            ariaLabelledBy={t('account.state')}
            label={t('account.state')}
            options={stateOptions}
            defaultOption={getDefaultStateOption(user.address.state, stateOptions)}
            onSelectOption={(value) => {
              setValue('state', value, { shouldDirty: true, shouldTouch: true });
            }}
          />

          <TextInput
            id="contact-form-zip"
            ariaLabel={t('account.zip')}
            label={t('account.zip')}
            hasError={Boolean(errors.zip)}
            errorMessage={errors.zip?.message}
            {...register('zip', { required: t('account.zipCodeRequired') })}
          />
        </StateZipGroup>

        <ButtonGroup>
          <Button
            type="submit"
            dataTestId={`${t('cta.save')}-btn`}
            styleVariant="secondary"
            label={t('cta.save')}
            isDisabled={isLoading}
          />
          <Button
            dataTestId={`${t('cta.cancel')}-btn`}
            styleVariant="tertiary"
            label={t('cta.cancel')}
            onClick={handleCancel}
          />
        </ButtonGroup>
      </Form>
    </FormWrapper>
  );
}

export default ContactForm;
