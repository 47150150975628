import React from 'react';

import { differenceInDays, differenceInHours, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { MessageDate } from './MessageDateText.styles';

type MessageDateProps = {
  date: Date;
};

function MessageDateText({ date }: MessageDateProps) {
  const { t } = useTranslation();
  const now = new Date();
  const hoursDifference = differenceInHours(now, date);
  const daysDifference = differenceInDays(now, date);

  let dateText;
  if (hoursDifference < 24) {
    dateText = t('inbox.message.today');
  } else if (hoursDifference < 48) {
    dateText = t('inbox.message.yesterday');
  } else if (daysDifference < 7) {
    dateText = `${daysDifference} ${t('inbox.message.daysAgo')}`;
  } else {
    dateText = format(date, 'MM/dd/yyyy');
  }
  return <MessageDate as="p">{dateText}</MessageDate>;
}
export default MessageDateText;
