import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { DateRange } from 'api/system/utils/DateRange';
import { ChartDataType } from 'modules/types';
import { DateRangeZoomLevel, EnergyUnit, SystemType } from 'api/types';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import { useBreakpoints } from 'hooks/useWindowSize';
import { AnyChartDataProvider } from 'modules/system/utils/ChartDataProviders/AnyChartDataProvider';
import { MonitoringQueryKey } from 'modules/system/utils/prefetchQueries';
import { useGetEnergyWithAllocation } from 'api/system';
import { SystemCategory } from 'api/system/monitoringTypes';
import { SpinnerContainer } from '../Chart.styles';
import { BarChart } from './BarChart';
import { ChartDataError, DisplayChartError } from '../../ChartDataError/ChartDataError';
import { YAxisLabel } from '../YAxisLabel/YAxisLabel';

interface Props {
  dataProvider: AnyChartDataProvider;
  dateRange: DateRange;
  system: SystemType;
  category: SystemCategory;
  isTimezoneError: boolean;
  onHasChartError: (error: boolean) => void;
}

export function EnergyChart({ dataProvider, dateRange, system, category, isTimezoneError, onHasChartError }: Props) {
  const [chartData, setChartData] = useState<ChartDataType[] | null>(null);
  const [yAxisEnergyUnit, setYAxisEnergyUnit] = useState<EnergyUnit | null>(null);
  const [largestProductionValue, setLargestProductionValue] = useState<number | null>(null);

  const { zoomLevel, startDate } = dateRange;

  const screenSize = useBreakpoints();
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();

    const { data, isFetching, isError, error, refetch } = useGetEnergyWithAllocation({systemId: system?.id, category, dateRange})


  const isChartDataEmpty = chartData && chartData.length === 0;

  useEffect(() => {
    let monitoringData = data?.monitoring_data;

    if (!monitoringData) {
      //  if there's no data from the in-house query, check the appropriate cache
      if (zoomLevel === DateRangeZoomLevel.YEAR) {
        monitoringData = queryClient.getQueryData([MonitoringQueryKey.ENERGY_YEAR, system.id]);
      } else {
        monitoringData = queryClient.getQueryData([MonitoringQueryKey.ENERGY_MONTH, system.id]);
      }
    }

    if (monitoringData && dataProvider && monitoringData.length) {
      onHasChartError(false);
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const [productionData, _largestProductionValue, _yAxisEnergyUnit] =
        dataProvider.prepareData<EnergyUnit>(monitoringData, dateRange);
      setYAxisEnergyUnit(_yAxisEnergyUnit as EnergyUnit);
      setLargestProductionValue(_largestProductionValue || 0);
      setChartData(productionData); 
    }
    // if the query completes and there's no data we shouldn't leave stale data in the displayed chart
    if (!isFetching && !monitoringData?.length) {
      setChartData([]);
    }
  }, [startDate, zoomLevel, data, isFetching, queryClient, dataProvider, dateRange, system, onHasChartError]);

    useEffect(() => {
      if (isError || isChartDataEmpty) {
        onHasChartError(true)
      }
    }, [isChartDataEmpty, isError, onHasChartError])

  let errorMessage: DisplayChartError | null =  null

  if (!isFetching ) {
    if (isChartDataEmpty || isTimezoneError ) {
      errorMessage = DisplayChartError.TRY_AGAIN;
    }
    
    if (isError) {
      if (error.response?.status === 531) {
        errorMessage = DisplayChartError.SITE_NOT_FOUND;
      } else if (error.response?.status === 400 || isChartDataEmpty) {
        errorMessage = DisplayChartError.TRY_AGAIN;
      } else {
        errorMessage = DisplayChartError.CONTACT_SUPPORT;
      }   
    }
  }

  const domain =
  (dateRange &&
    dataProvider.getDomain &&
    dataProvider.getDomain(dateRange, largestProductionValue)) ||
  undefined;

  const chartTheme = dataProvider.getTheme(theme.colors)
  const yAxisLabels = dataProvider.yAxisLabels && dataProvider.yAxisLabels(t);

  return (
    <>
    {errorMessage && (
      <ChartDataError error={errorMessage} onClickTryAgain={refetch}/>
    )}

      {!errorMessage &&
        (yAxisEnergyUnit && largestProductionValue && chartData && !isFetching ? (
          <>
            {yAxisLabels && <YAxisLabel labels={yAxisLabels.top} titleColor={chartTheme.yAxisTitle} />}
            <BarChart
              yAxisUnit={yAxisEnergyUnit}
              domain={domain}
              dateRange={dateRange}
              data={chartData}
              axes={{
                x: dataProvider.getXAxis(dateRange, screenSize),
                y: dataProvider.getYAxis(largestProductionValue, yAxisEnergyUnit, screenSize),
              }}
              tooltipDateFormatter={dataProvider.tooltipDateFormatter}
              flyoutLabels={dataProvider.getFlyoutLabels}
              zoomLevel={zoomLevel}
              chartTheme={chartTheme}
              category={category}         
            />
            
            {yAxisLabels && <YAxisLabel labels={yAxisLabels.bottom} titleColor={chartTheme.yAxisTitle} />}
          </>
        ) : (
          <SpinnerContainer>
            <LoadingSpinner localCenter />
          </SpinnerContainer>
        ))}
    </>
  );
}

export default EnergyChart;
