import React from 'react';
import { useTheme } from 'styled-components';
import { IIconProps } from '../TIcon';

export function AccountIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.iconIconPrimarySecondary1100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M8.02539 8C10.2348 8 12.0254 6.20906 12.0254 4C12.0254 1.79094 10.2348 0 8.02539 0C5.81602 0 4.02539 1.79094 4.02539 4C4.02539 6.20906 5.81602 8 8.02539 8ZM8.02539 1C9.67977 1 11.0254 2.34562 11.0254 4C11.0254 5.65406 9.67977 7 8.02539 7C6.37102 7 5.02539 5.65312 5.02539 4C5.02539 2.34562 6.37227 1 8.02539 1ZM9.60977 9.5H6.44102C3.4507 9.5 1.02539 11.925 1.02539 14.9156C1.02539 15.5138 1.51039 15.9991 2.10852 15.9991H13.9429C14.541 16 15.0254 15.5156 15.0254 14.9156C15.0254 11.925 12.6004 9.5 9.60977 9.5ZM13.941 15H2.10852C2.06289 15 2.02539 14.9625 2.02539 14.9156C2.02539 12.4812 4.00664 10.5 6.44102 10.5H9.60664C12.0441 10.5 14.0254 12.4812 14.0254 14.9156C14.0254 14.9625 13.9879 15 13.941 15Z"
        fill={color}
      />
    </svg>
  );
}

export default AccountIcon;
