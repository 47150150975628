import { AgreementType, UserType } from 'api/types';
import {
  MAXIMUM_PASSWORD_LENGTH,
  MINIMUM_PASSWORD_LENGTH,
  MINIMUM_PASSWORD_STRENGTH,
} from 'constants/auth';
import { TFunction } from 'i18next';
import { MilestoneType, RolesKey, USER_ROLES } from 'modules/types/util';
import pages, { Page } from 'pages';

const isHomeowner = (role?: RolesKey): boolean => role === USER_ROLES.HOMEOWNER;

export const getDashboardRedirectPath = (user?: UserType | null, agreement?: AgreementType) => {
  let hasDashboardAccess = isHomeowner(user?.role) && Boolean(agreement);
  let redirectPath: `/${Page}` = `/${pages.ADMIN}`;

  // contract/workflow has been canceled if no agreements are on an account
  if (isHomeowner(user?.role) && !agreement) {
    hasDashboardAccess = false;
    redirectPath = `/${pages.STATUS}`;
  } else if (agreement) {
    if (
      hasDashboardAccess &&
      agreement?.product_version !== 1 && // RIC 1.0 aren't a part of Early Access
      agreement?.current_milestone && // current_milestone is null for older agreements that are in service.
      agreement?.current_milestone !== MilestoneType.IN_SERVICE
    ) {
      hasDashboardAccess = false;
      redirectPath = `/${pages.STATUS}`;
    }
  }

  return { hasDashboardAccess, redirectPath };
};

interface PasswordValidationProps {
  value: string;
  newPassword: string;
  passwordScore: number;
  translator: TFunction<'translation', undefined>;
  isConfirmPasswordField?: boolean;
  isNewPasswordField?: boolean;
}

export const handlePasswordValidation = ({
  value,
  newPassword,
  passwordScore,
  translator,
  isConfirmPasswordField,
  isNewPasswordField,
}: PasswordValidationProps) => {
  if (!value) {
    if (isNewPasswordField) {
      return translator('account.newPasswordRequired');
    }

    if (isConfirmPasswordField) {
      return translator('account.confirmNewPassword');
    }

    return translator('account.passwordRequired');
  }

  if (isConfirmPasswordField && value !== newPassword) {
    return translator('account.passwordsDoNotMatch');
  }

  if (value.length <= MINIMUM_PASSWORD_LENGTH) {
    return translator('account.passwordTooShort', { num: MINIMUM_PASSWORD_LENGTH });
  }

  if (value.length > MAXIMUM_PASSWORD_LENGTH) {
    return translator('account.passwordTooLong', { num: MAXIMUM_PASSWORD_LENGTH });
  }

  if (passwordScore < MINIMUM_PASSWORD_STRENGTH) return translator('account.passwordNotStrong');

  return true;
};
