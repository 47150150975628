import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import useUser from 'hooks/useUser';
import { Header } from 'shared/components/Header/Header';
import { UnsavedChangesModal } from 'shared/components/UnsavedChangesModal/UnsavedChangesModal';
import { MilestoneType } from 'modules/types';
import { EDIT_ADDRESS_PARAM } from 'modules/types/util';
import {
  AccountNumber,
  AccountWrapper,
  ContactSection,
  LoginSection,
  SectionTitle,
} from './Account.styles';
import { ContactForm, FieldChangeType } from '../components/ContactForm/ContactForm';
import { LoginDetailsForm } from '../components/LoginDetailsForm/LoginDetailsForm';
import { ContactCard } from '../components/ContactCard/ContactCard';
import { LoginCard } from '../components/LoginCard/LoginCard';

export function Account() {
  const [searchParams] = useSearchParams();
  const { userResult, selectedAgreement, billingForm, refetch, setBillingForm } = useUser();

  const openContactInfo = Boolean(searchParams.get(EDIT_ADDRESS_PARAM) && billingForm.isEditing);

  const [updateContactInfo, setUpdateContactInfo] = useState(openContactInfo || false);
  const [updateCredentials, setUpdateCredentials] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { t } = useTranslation();

  const user = userResult?.user;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const canEditAccountInfo =
    selectedAgreement?.current_milestone === MilestoneType.IN_SERVICE ||
    selectedAgreement?.current_milestone === null;

  const showContactForm = () => {
    setUpdateContactInfo(true);
  };

  const hideContactForm = () => {
    setIsFormDirty(false);
    setUpdateContactInfo(false);
  };

  const showLoginForm = () => {
    setUpdateCredentials(true);
  };

  const hideLoginForm = () => {
    setIsFormDirty(false);
    setUpdateCredentials(false);
  };

  const handleOnSave = async () => {
    searchParams.delete(EDIT_ADDRESS_PARAM);
    setBillingForm({ isEditing: false });
    refetch();
    hideContactForm();
  };

  const handleLeave = () => {
    blocker.proceed?.();
  };

  const handleCancel = () => {
    searchParams.delete(EDIT_ADDRESS_PARAM);
    setBillingForm({ isEditing: false });
    blocker.reset?.();
  };

  const handleContactFormChange = useMemo(
    () => (fields: FieldChangeType) => {
      setBillingForm({
        isEditing: fields.isDirty,
        addressId: user?.address.id,
        ...(fields.isDirty ? fields.address : {}),
      });
      setIsFormDirty(fields.isDirty);
    },
    [setBillingForm, user?.address.id],
  );

  return (
    <>
      <AccountWrapper>
        <Header pageName="ACCOUNT" title={t('account.account')} />

        {user ? (
          <>
            <AccountNumber as="h3">#{selectedAgreement?.homeowner_account_id}</AccountNumber>

            <ContactSection>
              <SectionTitle as="h3">{t('account.contactInformation')}</SectionTitle>
              {updateContactInfo ? (
                <ContactForm
                  user={{
                    ...user,
                    address: openContactInfo
                      ? {
                          ...user.address,
                          street_1: billingForm.street1 || user.address.street_1,
                          street_2: billingForm.street2 || user.address.street_2,
                          city: billingForm.city || user.address.city,
                          state: billingForm.state || user.address.state,
                          zip_code: billingForm.zip || user.address.zip_code,
                        }
                      : user.address,
                  }}
                  onCancel={hideContactForm}
                  onSave={handleOnSave}
                  showAccountInfo={canEditAccountInfo}
                  onFieldChange={handleContactFormChange}
                />
              ) : (
                <ContactCard user={user} onEdit={showContactForm} />
              )}
            </ContactSection>

            <LoginSection>
              <SectionTitle as="h3">{t('account.loginInformation')}</SectionTitle>

              {updateCredentials ? (
                <LoginDetailsForm
                  user={user}
                  onCancel={hideLoginForm}
                  onSave={hideContactForm}
                  onFieldChange={setIsFormDirty}
                />
              ) : (
                <LoginCard user={user} onEdit={showLoginForm} />
              )}
            </LoginSection>
          </>
        ) : null}
      </AccountWrapper>
      <UnsavedChangesModal
        isOpen={blocker.state === 'blocked'}
        onCancel={handleCancel}
        onLeave={handleLeave}
      />
    </>
  );
}

export default Account;
