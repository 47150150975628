/* eslint-disable class-methods-use-this */
import { capitalize } from 'lodash';
import { addDays, differenceInCalendarDays, format, isSameDay } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { PaddingType } from 'modules/types';

export class MonthChartDataProvider {
  static barWidth = 5;

  static placeholderMaximumValue = 30.0;

  static shouldShowDateLabel = (day: number, dateRange: DateRange): boolean => {
    const currentDate = addDays(dateRange.startDate, day);
    const daysTillEnd = differenceInCalendarDays(dateRange.endDate, currentDate);
    // in the final days of the month, don't show the multiple of 7. Instead, show the last day of the range
    const omitDaysInFinalDays = 4;
    if (isSameDay(dateRange.endDate, currentDate)) {
      return true;
    }
    if (daysTillEnd < omitDaysInFinalDays) {
      return false;
    }
    if (day === 0) {
      return true;
    }
    if (day % 7 === 0) {
      return true;
    }
    return false;
  };

  static tickXFormat = (
    day: number,
    range: DateRange,
    shouldShowDateLabel: (day: number, dateRange: DateRange) => boolean,
    t: (key: string) => string,
  ): string => {
    const date = addDays(range.startDate, day);
    const dayOfMonth: number = date.getDate();
    const key = `system.ordinals.${dayOfMonth}`;
    const ordinal = t(key);
    return shouldShowDateLabel(day, range) ? ordinal : '';
  };

  static yTickLabelOffset = {
    x: -39,
    y: 15,
  };

  static domainPaddingX: PaddingType = [50, 3];

  static getTickValuesXAxis = (range: DateRange): number[] => {
    const daysInRange = differenceInCalendarDays(range.endDate, range.startDate) + 1;
    const values = Array.from(Array(daysInRange).keys()).map((value) => value);
    return values;
  };

  static tooltipDateFormatter = (date: Date): string =>
    capitalize(format(date, 'eee d').toLowerCase());
}

export default MonthChartDataProvider;
