import React from 'react';

export function HouseIcon() {
  return (
    <svg width="24" height="21" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        opacity="0.4"
        d="M4.66797 8.40098V17.7197C4.66797 18.4457 5.2638 19.0322 6.0013 19.0322H8.0013V12.7979C8.0013 11.8914 8.74714 11.1572 9.66797 11.1572H14.3346C15.2555 11.1572 16.0013 11.8914 16.0013 12.7979V19.0322H18.0013C18.7388 19.0322 19.3346 18.4457 19.3346 17.7197V8.40098L12.0013 2.27734L4.66797 8.40098Z"
        fill="#324C85"
      />
      <path
        id="Vector_2"
        d="M11.3523 0.233789C11.7273 -0.0779297 12.2731 -0.0779297 12.6481 0.233789L23.6481 9.42129C24.069 9.77402 24.1231 10.3934 23.7648 10.8076C23.4065 11.2219 22.7773 11.2711 22.3565 10.9225L21.3315 10.0693V17.7188C21.3315 19.5316 19.8398 21 17.9981 21H5.99815C4.15648 21 2.66481 19.5316 2.66481 17.7188V10.0693L1.64398 10.9225C1.22315 11.2752 0.589813 11.2219 0.235647 10.8076C-0.11852 10.3934 -0.0685199 9.76992 0.352313 9.42129L11.3523 0.233789ZM11.9981 2.27637L4.66481 8.4V17.7188C4.66481 18.4447 5.26065 19.0312 5.99815 19.0312H7.99815V12.7969C7.99815 11.8904 8.74398 11.1562 9.66481 11.1562H14.3315C15.2523 11.1562 15.9981 11.8904 15.9981 12.7969V19.0312H17.9981C18.7356 19.0312 19.3315 18.4447 19.3315 17.7188V8.4L11.9981 2.27637ZM9.99815 19.0312H13.9981V13.125H9.99815V19.0312Z"
        fill="#324C85"
      />
    </svg>
  );
}

export default HouseIcon;
