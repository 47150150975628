import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

import { Navigation, MenuOptions } from 'shared/components/Navigation/Navigation';
import { hideSupportChat, showSupportChat } from 'shared/utils/supportChat';
import { isHomeowner } from 'shared/utils/checkUserRole';
import { useUser } from 'hooks/useUser';
import { pages } from 'pages';
import { LayoutContent, LayoutContentInner, LayoutWrapper, MenuBar } from './Layout.styles';
import { Footer } from '../Footer/Footer';

type Props = {
  isAuthScreen?: boolean;
  menuOptions?: MenuOptions[];
  hasNav?: boolean;
  hasFooter?: boolean;
  children?: JSX.Element;
};

export function Layout({ menuOptions, hasNav, hasFooter = true, isAuthScreen, children }: Props) {
  const { userResult } = useUser();
  const { pathname } = useLocation();

  useEffect(() => {
    if (
      (isHomeowner(userResult?.user?.role) && !userResult?.impersonator) ||
      pathname.includes(pages.CHAT)
    ) {
      showSupportChat();
    } else {
      hideSupportChat();
    }
  }, [pathname, userResult?.impersonator, userResult?.user]);

  return (
    <LayoutWrapper>
      {hasNav && (
        <MenuBar>
          <Navigation menuOptions={menuOptions || []} />
        </MenuBar>
      )}
      <LayoutContent $isAuthScreen={isAuthScreen}>
        <LayoutContentInner $isAuthScreen={isAuthScreen}>
          {children || <Outlet />}
          {hasFooter && <Footer />}
        </LayoutContentInner>
      </LayoutContent>
    </LayoutWrapper>
  );
}

export default Layout;
