import React, { useEffect, useRef } from 'react';

import { InboxMessageType } from 'api/types';

import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import {
  FeedBodyWrapper,
  LoadingContainer,
  NoAlertsDescription,
  NoAlertsHeader,
  NoAlertsWrapper,
} from './FeedBody.styles';
import { Message } from './Message/Message';
import { ErrorBody } from '../ErrorBody/ErrorBody';
import { setBodyScrollingValues } from '../utils';

type FeedBodyProps = {
  messages: InboxMessageType[];
  handleMessageClick: (message: InboxMessageType) => void;
  lastMessageElementRef: (node: HTMLDivElement | null) => void;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  refetch: () => void;
  updateScrolling: (isScrolling: boolean) => void;
  updateScrolledToBottom: (scrolledToBottom: boolean) => void;
};

export function FeedBody({
  messages,
  handleMessageClick,
  lastMessageElementRef,
  isError,
  isLoading,
  isFetchingNextPage,
  isFetching,
  refetch,
  updateScrolling,
  updateScrolledToBottom,
}: FeedBodyProps) {
  const { t } = useTranslation();
  const feedBodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setBodyScrollingValues({
      isFetching,
      isFetchingNextPage,
      updateScrolledToBottom,
      updateScrolling,
      bodyRef: feedBodyRef,
    });
  }, [updateScrolling, updateScrolledToBottom, isFetchingNextPage, isFetching]);

  let body;
  if (isLoading) {
    body = (
      <LoadingContainer $firstLoad>
        <LoadingSpinner />
      </LoadingContainer>
    );
  } else if (isError) {
    body = <ErrorBody onClick={refetch} />;
  } else if (!messages.length) {
    body = (
      <NoAlertsWrapper>
        <NoAlertsHeader as="p">{t('inbox.message.noAlertsHeader')}</NoAlertsHeader>
        <NoAlertsDescription as="p">{t('inbox.message.noAlertsDescription')}</NoAlertsDescription>
      </NoAlertsWrapper>
    );
  } else {
    body = (
      <>
        {messages.map((message, index) => (
          <Message
            key={message.id}
            message={message}
            lastMessageElementRef={
              index === messages.length - 1 ? lastMessageElementRef : undefined
            }
            onClick={handleMessageClick}
            isFetchingNextPage={isFetchingNextPage}
          />
        ))}
        {isFetchingNextPage && (
          <LoadingContainer $firstLoad={false}>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </>
    );
  }
  return <FeedBodyWrapper ref={feedBodyRef}>{body}</FeedBodyWrapper>;
}
export default FeedBody;
