import React from 'react';

import { CellText } from '../TableCellString/TableCellString.styles';
import { TableCellButtonStyled } from './TableCellButton.styles';

type Props = {
  text: string;
  highlightRed?: boolean;
  onClick: () => void;
};

export function TableCellButton({ text, highlightRed, onClick }: Props) {
  return (
    <TableCellButtonStyled onClick={onClick}>
      <CellText as="p" $highlightRed={highlightRed}>
        {text}
      </CellText>
    </TableCellButtonStyled>
  );
}

export default TableCellButton;
