import styled, { css, DefaultTheme } from 'styled-components';

import Text from 'shared/components/Text/Text';

export const IconSubjectContainer = styled.div`
  ${({ theme }) => `
  display: flex;

  justify-content: center;
  align-items: center;
  max-width: calc(100% - 53px);
  @media screen and (min-width: ${theme.breakpoints.small}) {
    max-width: calc(100% - 42px);
  }
`}
`;
export const Subject = styled(Text)<{
  $read: boolean;
}>`
  ${({ theme, $read }) => `

  align-items: center;
  color: ${theme.colors.textPrimarySecondary1100};
  font-size: 16px;
  font-weight: ${$read ? '400' : '800'};
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`}
`;

export const ReadStatusContainer = styled.div`
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: right;
`;
export const MessageHeaderContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
`;

export const Body = styled(Text)<{
  $read: boolean;
}>`
  ${({ theme, $read }) => `
  margin-left: 24px;
  color: ${theme.colors.textSecondaryGrey90};
  font-size: 14px;
  font-weight: ${$read ? '400' : '800'};
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 36px);
  padding-right: 53px;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding-right: 42px;
  }
  `}
`;

const messageBackgroundColors = (theme: DefaultTheme) => ({
  default: theme.colors.cardElevationMidWhite,
  hover: theme.colors.cardInteractionHoverGrey05,
  active: theme.colors.cardInteractionActiveSecondary420,
});

export const MessageContainer = styled.div<{
  $borderBottom: boolean;
}>`
  ${({ theme, $borderBottom = false }) => {
    const backgroundColors = messageBackgroundColors(theme);
    return css`
      background: ${backgroundColors.default};
      align-self: stretch;
      max-height: 104px;
      border-bottom: ${$borderBottom
        ? `1px solid ${theme.colors.chartChartLightblueSecondary4100}`
        : 'none'};
      cursor: pointer;

      &:hover {
        background: ${backgroundColors.hover};
      }

      &:active {
        background: ${backgroundColors.active};
      }

      &:focus {
        outline: 3px solid ${theme.colors.chartChartLightbluePressedSecondary240};
      }
    `;
  }}
`;
