/* eslint-disable class-methods-use-this */
import { differenceInHours, differenceInSeconds } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { AxisType, Tuple } from 'modules/types';
import { MonitoringDatum } from 'api/types';
import { AggregatedData } from 'api/system/types';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { GridChartDataProvider } from './GridChartDataProvider';
import { DayChartDataProvider } from '../DayChartDataProvider';
import { generateDatesInRange } from '../generateDatesInRange';

export const getMaximumValue = (import_kw: number, export_kw: number): number =>
  Math.max(Math.abs(import_kw), Math.abs(export_kw));

export class GridDayChartDataProvider
  extends GridChartDataProvider
  implements AnyChartDataProvider
{
  largestProductionValue: number = 0.0;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData
      .filter(({ import_kw, export_kw }) => import_kw || export_kw)
      .forEach(({ import_kw, export_kw, time, unit }) => {
        /*
        The values will either be import_kw or export_kw, but not both.
        One will always be 0, since you can't be charing and discharging
        at the same time
      */

        if (import_kw === undefined || export_kw === undefined) {
          return;
        }

        const maxValue = getMaximumValue(import_kw, export_kw);
        const value: number = Math.abs(import_kw) > 0 ? import_kw : -1 * export_kw;
        if (maxValue > largestProductionValue) {
          largestProductionValue = maxValue;
          largestProductionUnit = unit;
        }
        const secondsSinceCurrentDate = differenceInSeconds(time, dateRange.startDate);
        const hoursSinceCurrentDate = secondsSinceCurrentDate / 60 / 60;
        dailyProduction.set(hoursSinceCurrentDate, {
          time,
          y: value,
          y_raw: Math.abs(value) === export_kw ? export_kw : import_kw,
          unit,
        });
      });
    this.largestProductionValue = largestProductionValue;
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (_: DateRange, __: number | null): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, 24],
    y: (this.getMaximumValue() && [-1 * this.getMaximumValue(), this.getMaximumValue()]) || [0, 0],
  });

  tickXFormat = DayChartDataProvider.tickXFormat;

  getTickValuesXAxis = (range: DateRange): number[] => {
    const numberOfTicks = 5;
    const tickValues = generateDatesInRange(range, numberOfTicks).map((date) =>
      differenceInHours(date, range.startDate),
    );
    return tickValues;
  };

  xAxisValue = (): number => -1 * this.getMaximumValue();

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getMaximumValue() {
    // Leaving commented. Will resolve with product alignment
    // on how to determine maximum value of y axis.
    // const maximumValue =
    //   (this.system?.capacity &&
    //     this.system?.estimated_offset &&
    //     Number(this.system?.capacity) / Number(this.system?.estimated_offset)) ||
    //   this.largestProductionValue;
    // return maximumValue;
    return this.largestProductionValue;
  }

  getTickValuesYAxis = (_: number): number[] => {
    const numberOfTicks = 5;
    const tickValues = Array.from(Array(numberOfTicks).keys()).map(
      (__, index) =>
        -this.getMaximumValue() + (this.getMaximumValue() * 2 * index) / (numberOfTicks - 1),
    );
    return tickValues;
  };

  getYAxis = <T>(_: number, unit: T): AxisType => ({
    tickValues: this.getTickValuesYAxis(this.getMaximumValue()),
    tickFormat: (value: number) => this.tickYFormat(Math.abs(value), unit),
  });

  tooltipDateFormatter = DayChartDataProvider.tooltipDateFormatter;

  getFlyoutLabels(t: (key: string) => string, y?: number): { y: string; y0: string } {
    return {
      y0: '',
      y:
        y && y < 0.0
          ? t('system.labels.flyout.grid.exported')
          : t('system.labels.flyout.grid.imported'),
    };
  }
}

export default GridDayChartDataProvider;
