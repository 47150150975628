import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const ManageSectionWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.backgroundSecondaryGrey05};
  align-items: center;
  flex: 1 0 0;
  width: 100%;
  max-width: 440px;

  // small styling
  margin: 10px 0;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px;
  gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    border-radius: 24px;
    padding: 24px;
    gap: 24px;
    max-width: unset;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 10px 0 10px 12px;
    padding: 24px 32px;
    gap: 32px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    margin: 10px 0;
    padding: 32px 48px;
    gap: 48px;
  }
`;

export const TitleWrapper = styled.div`
  // small styling
  h2 {
    margin-bottom: 8px;
  }
`;

export const SubText = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const ManageSectionContents = styled.div`
  display: flex;
  flex: 1 0 0;
  width: 100%;

  // small styling
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
    align-items: center;
    gap: 32px;
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    flex-direction: row;
    align-items: center;
    gap: 48px;
    height: 100%;
  }
`;
