import styled from "styled-components";
import { Text } from "shared/components/Text/Text";

export const LABEL_VALUE_CONTAINER_GAP= {
  xl: 24,
  lg: 10,
  md: 8,
  sm: 8,
};

export const LabelValueContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${LABEL_VALUE_CONTAINER_GAP.sm}px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    gap: ${LABEL_VALUE_CONTAINER_GAP.lg}px;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: ${LABEL_VALUE_CONTAINER_GAP.xl}px;
  }
`

export const Label = styled(Text)`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${({theme}) => theme.colors.textPrimarySecondary1100 };

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 14px;
  }
`