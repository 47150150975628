import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';

import { getDisplayUrl } from 'shared/utils/getDisplayUrl';
import { AgreementType } from 'api/types';
import { useDownloadContract } from 'api/agreement';
import { toast } from 'shared/components/Toast/Toast';
import { ArrowUpRightFromSquare, DownloadIcon } from '../../../shared/components/icons';
import { Text } from '../../../shared/components/Text/Text';
import {
  CardBody,
  ImageWrapper,
  SystemDetailsCardsWrapper,
  SystemDetailsWrapper,
} from './SystemDetails.styles';
import { Card } from '../../../shared/components/Card/Card';
import { CardDescription, CardDescriptionBold } from '../../../shared/components/Card/Card.styles';

export interface InstallerCardProps {
  installer: string;
  installerUrl: string;
  installerLogoUrl: string;
}

export interface ContractCardProps {
  contractType: string;
  contractSignedDate?: string;
  contractTerm: number;
  onClick: () => void;
}

export interface PaymentMethodCardProps {
  achElected: boolean;
  onClick: () => void;
}

function InstallPartnerCard({ installer, installerUrl, installerLogoUrl }: InstallerCardProps) {
  const displayUrl = getDisplayUrl(installerUrl);

  const { t } = useTranslation();

  return (
    <Card
      title={t('status.installPartner')}
      actionText={displayUrl ? t('status.visitWebsite') : undefined}
      actionIcon={{ element: <ArrowUpRightFromSquare /> }}
      url={installerUrl}
      hasHeaderIcons={false}
    >
      <CardBody>
        <CardDescription as="body">{t('status.installPartner')}</CardDescription>
        <CardDescriptionBold as="body">{installer || ''}</CardDescriptionBold>
        {displayUrl && (
          <>
            <CardDescription as="body">{t('status.installerWebsite')}</CardDescription>
            <CardDescriptionBold as="body">{displayUrl}</CardDescriptionBold>
          </>
        )}
        <ImageWrapper>
          <img src={installerLogoUrl} alt="Installer logo" />
        </ImageWrapper>
      </CardBody>
    </Card>
  );
}

function ContractCard({
  contractType,
  contractSignedDate,
  contractTerm,
  onClick,
}: ContractCardProps) {
  const { t } = useTranslation();

  return (
    <Card
      title={contractType}
      actionText={t('status.downloadContract')}
      actionIcon={{ element: <DownloadIcon /> }}
      onClick={onClick}
    >
      <CardBody>
        {contractSignedDate && (
          <>
            <CardDescription as="body">{t('status.contractSigned')}</CardDescription>
            <CardDescriptionBold as="body">{contractSignedDate}</CardDescriptionBold>
          </>
        )}
        <CardDescription as="body">{t('status.contractTerm')}</CardDescription>
        <CardDescriptionBold as="body">
          {contractTerm} {contractTerm === 1 ? t('status.year') : t('status.years')}
        </CardDescriptionBold>
      </CardBody>
    </Card>
  );
}

function PaymentMethodCard({ achElected, onClick }: PaymentMethodCardProps) {
  const { t } = useTranslation();
  return (
    <Card
      title={t('status.paymentMethod')}
      actionText={achElected ? undefined : t('status.autoPaySignUp')}
      onClick={achElected ? undefined : onClick}
    >
      <CardBody>
        <CardDescription as="body">
          {achElected ? t('status.autoPayment') : t('status.manualPayment')}
        </CardDescription>
      </CardBody>
    </Card>
  );
}

export interface SystemDetailsProps {
  agreement: AgreementType;
  onOpenAutoPay: () => void;
}

export function SystemDetails({ agreement, onOpenAutoPay }: SystemDetailsProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate: download } = useDownloadContract({
    onSuccess: (data) => {
      const { presigned_url: presignedUrl } = data;
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.download = 'signed_everbright_contract.pdf';
      link.click();
      link.remove();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    },
  });

  const {
    id: agreementId,
    organization,
    product_display_name: contractType,
    contract_signed_date: contractSignedDate,
    contract_term: contractTerm,
  } = agreement;

  const formattedDate = contractSignedDate
    ? format(new Date(contractSignedDate), 'MMMM d, yyyy')
    : undefined;

  const handleDownload = () => {
    download(agreementId);
  };

  return (
    <SystemDetailsWrapper>
      <Text as="h2">{t('status.accountSummary')}</Text>
      <SystemDetailsCardsWrapper>
        {organization && (
          <InstallPartnerCard
            installer={organization.name}
            installerUrl={organization.website_url}
            installerLogoUrl={organization.logo_image_url || ''}
          />
        )}
        <PaymentMethodCard achElected={agreement.ach_elected} onClick={onOpenAutoPay} />
        <ContractCard
          contractType={contractType}
          contractSignedDate={formattedDate}
          contractTerm={contractTerm || 0}
          onClick={handleDownload}
        />
      </SystemDetailsCardsWrapper>
    </SystemDetailsWrapper>
  );
}

export default {};
