import styled from 'styled-components';
import { footerHeight } from '../MessageCenterFooter/MessageCenterFooter.styles';
import { ModalBodyType } from '../types';
import { headerHeight } from '../MessageCenterHeader/MessageCenterHeader.styles';

// TODO: Update these values when we switch to account menu v2
export const MessageCenterIconContainer = styled.div`
  ${({ theme }) => `
  align-self: flex-end;
  margin-bottom: 8px;
  button {
    font-weight: 400;
    color: ${theme.colors.textPrimarySecondary1100};
    font-size: 14px;
  }
  @media screen and (min-width: ${theme.breakpoints.small}) {
    margin-bottom: 0;
  }
    `}
`;

export const MessageCenterModalWrapper = styled.div`
  ${({ theme }) => `
  position: absolute;
  top: 76px;
  width: 100%;
  height: calc(100% - 76px);
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 24px;
  background: ${theme.colors.cardElevationMidWhite};
  box-shadow: ${theme.elevations.midInverse};
  
  @media screen and (min-width: ${theme.breakpoints.small}) {
    top: 0;
    max-width: 412px;
    height: 100%;
    box-shadow: ${theme.elevations.highest};
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    max-width: 490px;
  }

`}
`;

export const MessageCenterBodyWrapper = styled.div<{
  $hasFooter: boolean;
  $modalType: ModalBodyType;
}>`
  ${({ theme, $hasFooter, $modalType }) => `
    position: relative;
    z-index: 1;
    height: calc(100% - ${headerHeight[$modalType][theme.breakpoints.small]} - ${$hasFooter ? footerHeight[theme.breakpoints.small] : '0px'});
    overflow: auto;
    @media screen and (min-width: ${theme.breakpoints.small}) {
      height: calc(100% - ${headerHeight[$modalType][theme.breakpoints.medium]} - ${$hasFooter ? footerHeight[theme.breakpoints.medium] : '0px'});
    }
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      height: calc(100% - ${headerHeight[$modalType][theme.breakpoints.large]} - ${$hasFooter ? footerHeight[theme.breakpoints.large] : '0px'});
    }
  `}
`;
