/* eslint-disable class-methods-use-this */
import { differenceInHours, differenceInSeconds } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { EnergyAllocationType, MonitoringDatum } from 'api/types';
import { AggregatedData } from 'api/system/types';
import { AxisType } from 'modules/types';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { SolarChartDataProvider } from './SolarChartDataProvider';
import { DayChartDataProvider } from '../DayChartDataProvider';
import { generateDatesInRange } from '../generateDatesInRange';

export class SolarDayChartDataProvider
  extends SolarChartDataProvider
  implements AnyChartDataProvider
{
  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue: number | null = null;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      if (value === undefined) {
        return;
      }
      if (!largestProductionValue || !largestProductionUnit) {
        largestProductionValue = value;
        largestProductionUnit = unit;
      } else if (
        !largestProductionValue ||
        (largestProductionValue && value >= largestProductionValue)
      ) {
        largestProductionValue = value;
        largestProductionUnit = unit;
      }
      const secondsSinceCurrentDate = differenceInSeconds(time, dateRange.startDate);
      const hoursSinceCurrentDate = secondsSinceCurrentDate / 60 / 60;
      dailyProduction.set(hoursSinceCurrentDate, { time, y: value, unit });
    });
    return [dailyProduction, null, largestProductionUnit];
  }

  tickXFormat = DayChartDataProvider.tickXFormat;

  getTickValuesXAxis = (range: DateRange): number[] => {
    const numberOfTicks = 5;
    const tickValues = generateDatesInRange(range, numberOfTicks).map((date) =>
      differenceInHours(date, range.startDate),
    );
    return tickValues;
  };

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = (maximumValue: number) =>
    SolarChartDataProvider.solarGetTickValuesYAxis(maximumValue);

  getYAxis = <T>(_: number, unit: T): AxisType => ({
    tickValues: this.getTickValuesYAxis(this.system?.capacity || 0.0),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = DayChartDataProvider.tooltipDateFormatter;

  total = (t: (key: string) => string, allocation: EnergyAllocationType) => ({
    label: t('system.summary.categories.SOLAR.total_production'),
    value: allocation.production_total_wh,
  });
}

export default SolarDayChartDataProvider;
