import axios, { AxiosPromise } from 'axios';

import routes from 'routes';
import { InboxMessageType } from 'api/types';
import { useInfiniteQuery, InfiniteData, useMutation } from 'react-query';

export const useGetInboxMessages = ({
  userId,
  pageSize = 25,
  sortBy,
  direction,
  onSuccess,
}: {
  userId?: string;
  pageSize?: number;
  sortBy?: string;
  direction?: 'ascending' | 'descending';
  onSuccess: (data: InfiniteData<any>) => void;
}) =>
  useInfiniteQuery(
    ['inboxMessages', userId, pageSize, sortBy, direction],
    ({ pageParam = 1 }) =>
      axios
        .get(routes.inboxMessages(userId || ''), {
          params: {
            page: pageParam,
            page_size: pageSize,
            sort_by: sortBy,
            direction,
          },
        })
        .then((res) => res.data),
    {
      staleTime: 1000 * 60, // One minute
      cacheTime: 1000 * 60 * 10, // Ten minutes
      refetchOnWindowFocus: false, // Only refetch when the user refreshes page
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!userId,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.next) {
          // Page indexes from 1
          return pages.length + 1;
        }
        return undefined;
      },
      onSuccess: (data) => {
        onSuccess(data);
      },
    },
  );

export const useSetInboxMessageRead = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  function setInboxMessageRead({
    userId,
    inboxMessageId,
  }: {
    userId: string;
    inboxMessageId: string;
  }): AxiosPromise<InboxMessageType> {
    return axios.patch(routes.inboxMessage(userId, inboxMessageId), { read: true });
  }
  return useMutation({
    mutationFn: setInboxMessageRead,
    onSuccess: async () => {
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
};

export const useSetInboxMessageMarkAllRead = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  function setInboxMessageMarkAllRead({
    userId,
  }: {
    userId: string;
  }): AxiosPromise<InboxMessageType> {
    return axios.put(routes.markAllMessagesRead(userId));
  }
  return useMutation({
    mutationFn: setInboxMessageMarkAllRead,
    onSuccess: async () => {
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
};
