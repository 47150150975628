import React from 'react';
import { Trans } from 'react-i18next';

import { CardDescription, IconPosition } from 'shared/components/Card/Card.styles';
import { Card } from 'shared/components/Card/Card';
import ManageCardWrapper from './ManageCard.styles';

type Props = {
  title: string;
  iconElement: JSX.Element;
  subHeader: string;
  route?: string;
  url?: string;
  actionText?: string;
  includeUrlArrow?: boolean;
  navState?: Record<string, any>;
  onClick?: () => void;
};

export function ManageCard({
  title,
  iconElement,
  subHeader,
  route,
  url,
  actionText,
  includeUrlArrow = false,
  navState,
  onClick,
}: Props) {
  return (
    <ManageCardWrapper>
      <Card
        title={title}
        route={route}
        url={url}
        actionText={actionText}
        navState={navState}
        onClick={onClick}
        icon={{
          element: iconElement,
          color: 'grey',
        }}
        iconPosition={IconPosition.NEXT}
        includeUrlArrow={includeUrlArrow}
        width="100%"
        height="100%"
      >
        <CardDescription as="p">
          <Trans components={{ b: <b /> }}>{subHeader} </Trans>
        </CardDescription>
      </Card>
    </ManageCardWrapper>
  );
}

export default ManageCard;
