import styled, { css } from 'styled-components';

import { Text } from 'shared/components/Text/Text';
import { BannerContent, BannerStyled } from '../../../../../shared/components/Banner/Banner.styles';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

export const AgreementTpvBannerStyled = styled(BannerStyled)`
  ${({ theme }) => css`
    border: solid 1px ${theme.colors.textSystemErrorFunction1100};
  `}
`;

export const AgreementTpvBannerIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const AgreementTpvBannerContent = styled(BannerContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

export const AgreementTpvPreviewSection = styled(BannerContent)`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
`;

export const AgreementDetailsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

export const SystemSection = styled(FlexContainer)`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
`;

export const Title = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const Label = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
`;

export const Value = styled(Text)`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;
