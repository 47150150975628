/* eslint-disable class-methods-use-this */
import { addHours, format } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { toZonedTime } from 'date-fns-tz';

export class DayChartDataProvider {
  static tickXFormat = (hours: number, range: DateRange): string => {
    const date = addHours(range.startDate, hours);
    const zoned = toZonedTime(date, range.systemTimezone);
    return format(zoned, 'ha').toLowerCase();
  };

  static tooltipDateFormatter = (date: Date, dateRange: DateRange): string => {
    const zoned = toZonedTime(date, dateRange.systemTimezone);
    return format(zoned, 'h:mma').toLowerCase();
  };
}

export default DayChartDataProvider;
