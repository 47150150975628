import React from 'react';
import { useTheme } from 'styled-components';

import useUser from 'hooks/useUser';
import { useBreakpoints } from 'hooks/useWindowSize';

import { useTranslation } from 'react-i18next';

import { CloseIcon } from 'shared/components/icons';
import Markdown from 'react-markdown';
import { useSetInboxMessageMarkAllRead } from 'api/inboxMessage';
import { toast } from 'shared/components/Toast/Toast';
import {
  ButtonsContainer,
  MarkAllReadModalBody,
  MarkAllReadModalHeader,
  MarkAllReadModalWrapper,
} from './MarkAllReadModal.styles';
import { Button } from '../../../Button/Button';

type Props = {
  handleCloseModal: () => void;
  refetchMessages: () => void;
};

export function MarkAllReadModal({ handleCloseModal, refetchMessages }: Props) {
  const theme = useTheme();

  const { userResult } = useUser();
  const user = userResult?.user;

  const { t } = useTranslation();
  const screenSize = useBreakpoints();

  const { mutate } = useSetInboxMessageMarkAllRead({
    onSuccess: async () => {
      refetchMessages();
      handleCloseModal();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('inbox.markAllReadModal.error'),
        theme,
      });
    },
  });

  const handleMarkAllRead = () => {
    if (user) {
      mutate({ userId: user.id });
    }
  };

  const closeIcon = () => (
    <CloseIcon size="large" color={theme.colors.iconIconPrimarySecondary1100} />
  );
  const markReadButtonWidth = screenSize === 'sm' ? '100%' : '174';
  return (
    <MarkAllReadModalWrapper>
      <MarkAllReadModalHeader as="p">
        {t('inbox.markAllReadModal.header')}
        <Button
          styleVariant="tertiary"
          Icon={closeIcon}
          onClick={handleCloseModal}
          iconRight
          margin="0 -7px 0 0" // Dealing with these margins is very annoying
        />
      </MarkAllReadModalHeader>
      <MarkAllReadModalBody as="p">
        <Markdown>{t('inbox.markAllReadModal.body')}</Markdown>
      </MarkAllReadModalBody>
      <ButtonsContainer>
        <Button
          styleVariant="tertiary"
          label={t('inbox.markAllReadModal.cancel')}
          onClick={handleCloseModal}
        />
        <Button
          styleVariant="primary"
          label={t('inbox.markAllReadModal.confirm')}
          onClick={handleMarkAllRead}
          isFullWidth={screenSize === 'sm'}
          maxWidth={markReadButtonWidth}
        />
      </ButtonsContainer>
    </MarkAllReadModalWrapper>
  );
}
export default MarkAllReadModal;
