/* eslint-disable class-methods-use-this */
import { ChartTheme } from 'modules/system/components/Charts/DayChart/AreaChart.types';
import { TThemeColors } from 'shared/design-system/theme/colors/TThemeColors';
import { YAxisLabel } from '../AnyChartDataProvider';
import { ChartDataProvider } from '../ChartDataProvider';

export class GridChartDataProvider extends ChartDataProvider {
  yAxisLabels(t: (key: string) => string): { top: YAxisLabel; bottom: YAxisLabel } {
    return {
      top: {
        title: t('system.labels.yaxis.grid.top.title'),
        subtitle: t('system.labels.yaxis.grid.top.subtitle'),
      },
      bottom: {
        title: t('system.labels.yaxis.grid.bottom.title'),
        subtitle: t('system.labels.yaxis.grid.bottom.subtitle'),
      },
    };
  }

  cornerRadius = {
    top: 2,
    bottom: 2,
  };

  getTheme: (color: TThemeColors) => ChartTheme = (color: TThemeColors) => ({
    fill: {
      lightTheme: {
        start: '#D1659B',
        middle: '#E672AC',
        end: '#C178BF',
      },
      darkTheme: {
        start: '#D1659B',
        middle: '#E672AC',
        end: '#C178BF',
      },
    },
    line: color.chartChartMagentaAccent1100,
    tooltip: color.chartChartMagentaAccent1100,
    yAxisTitle: color.chartChartMagentaAccent1100,
  });
}

export default GridChartDataProvider;
