import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import { useTheme } from 'styled-components';
import { AxiosError } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import { useGetEmbeddedSigningUrl } from '../../../../api/agreement';
import {
  getButtonStyleVariantValues,
  themeButtons,
} from '../../../../shared/design-system/theme/buttons';
import { toast } from '../../../../shared/components/Toast/Toast';
import { LoadingSpinner } from '../../../../shared/components/LoadingSpinner';
import {
  AutopayAddBankAccountContainer,
  AutopaySigningSection,
} from './AutopayAddBankAccountModal.styles';
import { AutopayAddBankAccountSuccessModal } from './AutopayAddBankAccountSuccessModal';

enum DocusignSessionEndType {
  SIGNING_COMPLETE = 'signing_complete',
  VIEWING_COMPLETE = 'viewing_complete',
}

const DOCUSIGN_SIGNING_SECTION_ID = 'signing-section';

export type AutopayAddBankAccountModalProps = {
  agreementId: string;
  docusignRef: MutableRefObject<any>;
  onCompleteFormModal: () => void;
  onCloseModal: () => void;
};

export function AutopayAddBankAccountModal({
  agreementId,
  docusignRef,
  onCompleteFormModal,
  onCloseModal,
}: AutopayAddBankAccountModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [signingReady, setSigningReady] = useState<boolean>(false);
  const [signingComplete, setSigningComplete] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const signingSectionRef = useRef<any>(null);

  const theme = useTheme();
  const { fontColor: docuSignButtonFontColor, background: docusignButtonBackgroundColor } =
    getButtonStyleVariantValues(theme, themeButtons, 'primary');

  const { mutateAsync: getEmbeddedSigningUrlAsync } = useGetEmbeddedSigningUrl({
    onError(error: AxiosError) {
      setErrorMessage(error.message);
    },
  });

  const loadDocuSignDocument = useCallback(async () => {
    setLoading(true);
    try {
      const signingUrlResponse = await getEmbeddedSigningUrlAsync({ agreement_id: agreementId });
      const signingConfiguration = {
        url: signingUrlResponse.url,
        displayFormat: 'focused',
        style: {
          branding: {
            primaryButton: {
              backgroundColor: docusignButtonBackgroundColor,
              color: docuSignButtonFontColor,
            },
          },
          signingNavigationButton: {
            finishText: 'Finish',
          },
        },
      };

      const signing = docusignRef.current.signing(signingConfiguration);

      signing.on('sessionEnd', (event: any) => {
        if (
          event.sessionEndType === DocusignSessionEndType.SIGNING_COMPLETE ||
          event.sessionEndType === DocusignSessionEndType.VIEWING_COMPLETE
        ) {
          setSigningComplete(true);
          datadogRum.addAction('ach-enrollment-complete');
        }
      });

      signing.mount(`#${DOCUSIGN_SIGNING_SECTION_ID}`);
      setSigningReady(true);
    } catch (error: any) {
      if (error instanceof AxiosError && (error as AxiosError).message) {
        setErrorMessage((error as AxiosError).message);
      } else {
        setErrorMessage(t('autopayModal.errorBody2'));
      }
    } finally {
      setLoading(false);
    }
  }, [
    agreementId,
    docuSignButtonFontColor,
    docusignButtonBackgroundColor,
    docusignRef,
    getEmbeddedSigningUrlAsync,
    t,
  ]);

  useEffect(() => {
    if (!signingReady && docusignRef.current && signingSectionRef.current) {
      loadDocuSignDocument();
    }
  }, [docusignRef, signingReady, signingSectionRef, loadDocuSignDocument]);

  useEffect(() => {
    if (errorMessage) {
      onCloseModal();
      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorMessage,
        theme,
      });
    }
  }, [errorMessage, onCloseModal, t, theme]);

  return (
    <>
      {signingComplete && (
        <AutopayAddBankAccountSuccessModal onCompleteFormModal={onCompleteFormModal} />
      )}

      {!signingComplete && (
        <>
          <Text as="h2">{t('autopayModal.addBankAccount')}</Text>
          <AutopayAddBankAccountContainer>
            <AutopaySigningSection
              id={DOCUSIGN_SIGNING_SECTION_ID}
              $isSigningReady={signingReady}
              ref={signingSectionRef}
            />
            {loading && <LoadingSpinner size="large" />}
          </AutopayAddBankAccountContainer>
        </>
      )}
    </>
  );
}

export default AutopayAddBankAccountModal;
