import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import { Modal } from 'shared/components/Modal/Modal';
import { Text } from 'shared/components/Text/Text';
import { AxiosError } from 'axios';
import { useTheme } from 'styled-components';
import { Button } from '../../../../shared/components/Button/Button';
import { TpvBaseContainer, TpvModalContent, TpvModalFooter } from './TpvModal.styles';
import { TpvGraphic } from '../../../../shared/components/icons/TpvGraphic';
import { useGenerateTpvUrl } from '../../../../api/agreement';
import { toast } from '../../../../shared/components/Toast/Toast';

export type TpvModalProps = {
  agreementId: string;
  isOpen: boolean;
  onCloseModal: () => void;
  onStartCallClickComplete: () => void;
};

export function TpvModal({
  agreementId,
  isOpen,
  onCloseModal,
  onStartCallClickComplete,
}: TpvModalProps) {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();

  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const bodyList: string[] = t('tpvModal.body', {
    returnObjects: true,
  });

  const { mutateAsync: generateTpvUrlAsync } = useGenerateTpvUrl({
    onError(error: AxiosError) {
      setErrorMessage(error.message);
    },
  });

  useEffect(() => {
    if (errorMessage) {
      onCloseModal();
      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorMessage,
        theme,
      });
    }
  }, [errorMessage, onCloseModal, t, theme]);

  const handleStartCallClick = async () => {
    try {
      setLoading(true);
      const tpvUrlResponse = await generateTpvUrlAsync({ agreement_id: agreementId });
      window.open(tpvUrlResponse.url, '_blank');
    } catch (error: any) {
      if (error instanceof AxiosError && (error as AxiosError).message) {
        setErrorMessage((error as AxiosError).message);
      } else {
        setErrorMessage(t('tpvModal.errorMessage'));
      }
    } finally {
      onStartCallClickComplete();
      setLoading(false);
    }
  };

  return (
    <Modal
      contentLabel={t('tpvModal.title')}
      styleVariant="tertiary"
      isOpen={isOpen}
      subHeader
      isFullWidth={screenSize === 'sm'}
      fitContent
      padding="24px"
      onRequestClose={onCloseModal}
    >
      <TpvModalContent>
        <TpvBaseContainer>
          <TpvGraphic />
          <Text as="h2" $textAlign="center">
            {t('tpvModal.title')}
          </Text>
          {Array.isArray(bodyList) &&
            bodyList.map((item) => (
              <Text as="p">
                <Trans defaults={item} />
              </Text>
            ))}
          <TpvModalFooter>
            <Button
              label={t('tpvModal.cancelButton')}
              styleVariant="secondary"
              onClick={onCloseModal}
            />
            <Button
              label={t('tpvModal.startCallButton')}
              onClick={handleStartCallClick}
              isLoading={loading}
            />
          </TpvModalFooter>
        </TpvBaseContainer>
      </TpvModalContent>
    </Modal>
  );
}

export default TpvModal;
