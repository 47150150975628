import styled, { css } from 'styled-components';
import { TTabStyleVariantsKey } from 'shared/design-system/theme/tabs/TTabs';
import { getTabStyleVariantValues, themeTabs } from 'shared/design-system/theme/tabs';
import { Text } from '../Text/Text';

export const TabControlsWrapper = styled.div`
  display: flex;
  padding: 4px;
  gap: 2px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
  background: ${({ theme }) => theme.colors.cardElevationMidWhite};
  box-shadow: ${({ theme }) => theme.elevations.mid};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    width: fit-content;
  }
`;

export const TabStyled = styled.button<{
  $styleVariant: TTabStyleVariantsKey;
  $isActive: boolean;
}>`
  ${({ $styleVariant, $isActive, theme }) => {
    const { background, backgroundActive, backgroundHover, gap } = getTabStyleVariantValues(
      theme,
      themeTabs,
      $styleVariant,
      $isActive ? 'active' : 'inactive',
    );
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      gap: ${gap};
      border-radius: 6px;
      outline: none;
      border: none;
      padding: 6px 0;
      max-height: 36px;

      cursor: pointer;
      box-shadow: ${$isActive ? theme.elevations.mid : 'none'};
      background: ${background};
      &:hover {
        background: ${backgroundHover};
        box-shadow: ${theme.elevations.mid};
      }
      &:active {
        background: ${backgroundActive};
        box-shadow: ${theme.elevations.mid};
      }

      @media screen and (min-width: ${theme.breakpoints.small}) {
        flex: unset;
        padding: 8px 0;
        width: 90px;
      }

      @media screen and (min-width: ${theme.breakpoints.medium}) {
        padding: 8px 0;
        width: 100px;
      }
    `;
  }}
`;

export const TabText = styled(Text)<{ $isActive: boolean }>`
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ $isActive }) => ($isActive ? 800 : 400)};
  line-height: 20px;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.textInverseWhite : theme.colors.textPrimarySecondary1100};
`;
