import * as timezones from 'countries-and-timezones';
/*
  We are provided a name of a timezone from the vendor. The data is messy.
  Usually, the value is something like America/Los_Angeles, which is a valid
  IANA time zone name.
  But also potentially, we could get a deprecated value like US/Pacific,
  which is no longer an IANA value. Without providing a valie IANA
  time zone name to date-fns-tz, we can't compute dates.

  This method will take a name like 'US/Pacific', use countries-and-timezones
  to determine if this value is really an alias of another name, ie:
  'US/Pacific' maps directly to 'America/Los_Angeles', or if it's already
  a valid timezone, in which case it returns the value directly
*/
export const timezoneValidateName = (timezoneNameUnvalidated: string): string => {
  const timezone = timezones.getTimezone(timezoneNameUnvalidated);
  if (!timezone) {
    throw new Error(`No timezone found for value:${timezoneNameUnvalidated}`);
  }
  if (timezone.aliasOf) {
    return timezone.aliasOf;
  }
  return timezone.name;
};

export default timezoneValidateName;
