import React from 'react';

import { InboxMessageType } from 'api/types';

import DotIcon from 'shared/components/icons/DotIcon';
import {
  Body,
  IconSubjectContainer,
  MessageContainer,
  MessageHeaderContainer,
  ReadStatusContainer,
  Subject,
} from './Message.styles';
import MessageCategoryIcon from './MessageCategoryIcon/MessageCategoryIcon';
import MessageDateText from './MessageDateText/MessageDateText';

type MessageProps = {
  message: InboxMessageType;
  isFetchingNextPage: boolean;
  lastMessageElementRef?: (node: HTMLDivElement | null) => void;
  onClick: (message: InboxMessageType) => void;
};

export function Message({
  message,
  lastMessageElementRef,
  onClick,
  isFetchingNextPage,
}: MessageProps) {
  return (
    <MessageContainer
      $borderBottom={!lastMessageElementRef || isFetchingNextPage}
      onClick={() => onClick(message)}
      ref={lastMessageElementRef}
    >
      <MessageHeaderContainer>
        <IconSubjectContainer>
          <MessageCategoryIcon category={message.category} />
          <Subject as="p" $read={message.read}>
            {message.subject}
          </Subject>
        </IconSubjectContainer>

        {!message.read && (
          <ReadStatusContainer>
            <DotIcon />
          </ReadStatusContainer>
        )}
      </MessageHeaderContainer>

      <Body as="p" $read={message.read}>
        {message.body}
      </Body>
      <MessageDateText date={message.date_sent} />
    </MessageContainer>
  );
}

export default Message;
