export enum InverterManufacturer {
  ENPHASE = '61b93d95-cd4e-4b20-a3e1-0648f36e7f34',
  SOLAREDGE = '16941363-3cc3-471d-a9de-cc401c81c23d',
}

export const SUPPORTED_MANUFACTURERS: InverterManufacturer[] = [InverterManufacturer.ENPHASE];

export function isSystemCategoriesFeatureEnabled({
  flag,
  inverter_manufacturer_id,
}: {
  flag: boolean;
  inverter_manufacturer_id: string | undefined;
}): boolean {
  if (!flag) {
    return false;
  }
  return SUPPORTED_MANUFACTURERS.includes(inverter_manufacturer_id as InverterManufacturer);
}
