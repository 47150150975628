export interface RawMonitoringDatumType<T> {
  value?: number;
  timestamp: string;
  unit: T;
}

export interface EnergySummaryType {
  day_kwh: number;
  month_kwh: number;
  all_time_kwh: number;
}

export interface EnergyAllocationType {
  production_total_wh: number;
  production_to_grid_wh: number;
  production_to_home_wh: number;
  production_to_battery_wh: number;
  consumption_total_wh: number;
  consumption_from_grid_wh: number;
  consumption_from_battery_wh: number;
  grid_import_total_wh: number;
  grid_export_total_wh: number;
  battery_charge_total_wh: number;
  battery_discharge_total_wh: number;
  unexplained_surpluses_total_wh: number;
  unexplained_deficits_total_wh: number;
  rows_total: number;
  rows_non_zero_sum: number;
  rows_invalid: number;
}

export interface ProductionSummary {
  day_production_kwh: number;
  month_production_kwh: number;
  lifetime_production_kwh: number;
}

export interface MonitoringResponseType<T> {
  monitoring_data: RawMonitoringDatumType<T>[];
  energy_allocation?: EnergyAllocationType;
}

export type DataQuality = {
  empty_response: boolean;
  whole_site_offline: boolean;
  non_zero_sum_deficit: boolean;
  non_zero_sum_surplus: boolean;
  negative_home_consumption: boolean;
  negative_solar_production: boolean;
  solar_power_exceeds_capacity: boolean;
  battery_power_exceeds_capacity: boolean;
  missing_site_data: boolean;
  missing_site_data_minus_not_producing: boolean;
  missing_all_battery_data: boolean;
  null_production: boolean;
  null_consumption: boolean;
  null_import: boolean;
  null_export: boolean;
  null_battery_power: boolean;
  null_battery_soc: boolean;
  negative_production: boolean;
  negative_consumption: boolean;
  negative_import: boolean;
  negative_export: boolean;
  night_production: boolean;
  excessive_battery_discharge: boolean;
  excessive_battery_charge: boolean;
  battery_power_and_soc_disagreement: boolean;
  power_imbalance: boolean;
  excessive_production: boolean;
  excessive_dc_coupled_battery_discharge: boolean;
};

export enum QualitySummaryGranularity {
  Hourly,
  Daily,
  Monthly,
}

export type QualitySummaryBucket = Partial<DataQuality> & {
  start_time: Date;
  end_time: Date;
};

export type QualitySummary = {
  granularity: QualitySummaryGranularity;
  buckets: QualitySummaryBucket[];
};

export enum SystemCategory {
  GRID = 'grid',
  SOLAR = 'solar',
  BATTERY = 'battery',
  HOME = 'home',
  SITE = 'site',
}
export interface VppDetails {
  vpp_participant: boolean;
  vpp_program: string;
  vpp_program_details: string;
  total_revenue: number;
  total_kwh_discharged: number;
}
