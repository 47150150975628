import styled from 'styled-components';

import { Header } from 'modules/account/components/Form.styles';

export const HeaderText = styled(Header)`
  padding-bottom: 16px;
  font-size: 32px;
`;

export default HeaderText;
