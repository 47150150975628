import styled from 'styled-components';

import { Body } from 'modules/billing/screens/Billing.styles';
import { Text } from 'shared/components/Text/Text';

export const ProjectQualificationCopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ul {
    margin: 0;
  }
`;

export const BodyText = styled(Text)`
  color: ${({ theme: { colors } }) => colors.textSecondaryGrey90};
`;

export const BodyTextBold = styled(Body)`
  font-family: Avenir;
  font-weight: 700;
  margin: 0;
  display: inline;
  color: ${({ theme: { colors } }) => colors.textSecondaryGrey90};
`;

export const ButtonFooter = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-top: 10px;

    @media screen and (${theme.breakpoints.medium} <= width < ${theme.breakpoints.xLarge}) {
      margin-top: 8px;
    }

    @media screen and (min-width: ${theme.breakpoints.small}) {
      flex-direction: row;
    }
  `}
`;
