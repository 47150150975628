import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressType, AgreementType } from 'api/types';
import { Header } from 'shared/components/Header/Header';
import { AddressSelect } from 'modules/overview/components/AddressSelect/AddressSelect';
import { StatusHeaderWrapper } from './StatusHeader.styles';

type Props = {
  selectedAgreement?: AgreementType;
  agreements: AgreementType[];
  userAddress: AddressType;
  banner?: JSX.Element;
  onAgreementChange: (agreementId: string) => void;
};

export function StatusHeader({
  selectedAgreement,
  agreements,
  userAddress,
  banner,
  onAgreementChange,
}: Props) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const optionsArray: { id: string; address: AddressType }[] = [];

    agreements.forEach((agreement) => {
      optionsArray.push({ id: agreement.id, address: agreement.system.address });
    });

    return optionsArray;
  }, [agreements]);

  const showAddressSelect = options.length > 1;
  return (
    <StatusHeaderWrapper>
      <Header pageName="STATUS" title={t('earlyAccess.hello')} />

      {banner}

      {selectedAgreement && showAddressSelect && (
        <AddressSelect
          selectedOption={{ id: selectedAgreement.id, address: userAddress }}
          options={options}
          onSelectOption={onAgreementChange}
        />
      )}
    </StatusHeaderWrapper>
  );
}

export default StatusHeader;
