/* eslint-disable class-methods-use-this */
import { differenceInHours, differenceInSeconds } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { AxisType, Tuple } from 'modules/types';
import { EnergyAllocationType, MonitoringDatum } from 'api/types';
import { AggregatedData } from 'api/system/types';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { HomeChartDataProvider } from './HomeChartDataProvider';
import { DayChartDataProvider } from '../DayChartDataProvider';
import { generateDatesInRange } from '../generateDatesInRange';

export class HomeDayChartDataProvider
  extends HomeChartDataProvider
  implements AnyChartDataProvider
{
  largestProductionValue: number = 0.0;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue: number | null = null;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      if (value === undefined) {
        return;
      }
      if (!largestProductionUnit || !largestProductionValue) {
        largestProductionValue = value;
        largestProductionUnit = unit;
      } else if (value >= largestProductionValue) {
        largestProductionValue = value;
        largestProductionUnit = unit;
      }
      const secondsSinceCurrentDate = differenceInSeconds(time, dateRange.startDate);
      const hoursSinceCurrentDate = secondsSinceCurrentDate / 60 / 60;
      dailyProduction.set(hoursSinceCurrentDate, { time, y: value, unit });
    });

    this.largestProductionValue = largestProductionValue || 0.0;
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (_: DateRange, __: number | null): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, 24],
    y: (this.largestProductionValue && [0, this.largestProductionValue]) || [0, 0],
  });

  tickXFormat = DayChartDataProvider.tickXFormat;

  getTickValuesXAxis = (range: DateRange): number[] => {
    const numberOfTicks = 5;
    const tickValues = generateDatesInRange(range, numberOfTicks).map((date) =>
      differenceInHours(date, range.startDate),
    );
    return tickValues;
  };

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = HomeChartDataProvider.homeGetTickValuesYAxis;

  getYAxis = <T>(_: number, unit: T): AxisType => ({
    tickValues: this.getTickValuesYAxis(this.largestProductionValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = DayChartDataProvider.tooltipDateFormatter;

  total = (t: (key: string) => string, allocation: EnergyAllocationType) => ({
    label: t('system.summary.categories.HOME.total_consumption'),
    value: allocation.consumption_total_wh,
  });
}

export default HomeDayChartDataProvider;
