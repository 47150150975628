import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function HomeIcon({ color: colorProp, filled }: IconProps & { filled: boolean }) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2555_25872)">
        <path
          d="M16.4958 8.09783C16.4958 8.59787 16.0791 8.98957 15.6068 8.98957H14.7179L14.7373 13.4399C14.7373 13.515 14.7318 13.59 14.7234 13.665V14.1122C14.7234 14.7262 14.2262 15.2234 13.6122 15.2234H13.1677C13.1372 15.2234 13.1066 15.2234 13.0761 15.2207C13.0372 15.2234 12.9983 15.2234 12.9594 15.2234H12.0565H11.3898C10.7759 15.2234 10.2786 14.7262 10.2786 14.1122V13.4455V11.6676C10.2786 11.1759 9.88135 10.7786 9.38965 10.7786H7.61172C7.12001 10.7786 6.72275 11.1759 6.72275 11.6676V13.4455V14.1122C6.72275 14.7262 6.22549 15.2234 5.61155 15.2234H4.94482H4.05864C4.01697 15.2234 3.9753 15.2207 3.93363 15.2179C3.90029 15.2207 3.86695 15.2234 3.83362 15.2234H3.38913C2.77519 15.2234 2.27793 14.7262 2.27793 14.1122V11.0009C2.27793 10.9758 2.27793 10.9481 2.28071 10.9231V8.98957H1.38896C0.888922 8.98957 0.5 8.60065 0.5 8.09783C0.5 7.84781 0.58334 7.62556 0.777801 7.4311L7.90063 1.22224C8.09509 1.02778 8.31733 1 8.51179 1C8.70625 1 8.92849 1.05556 9.09518 1.19446L16.1902 7.4311C16.4125 7.62556 16.5236 7.84781 16.4958 8.09783Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2555_25872">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3164_3009)">
        <path
          d="M8.37627 1.15833C8.12628 0.947223 7.76239 0.947223 7.5124 1.15833L0.179207 7.38043C-0.101343 7.61932 -0.134676 8.03875 0.101431 8.3193C0.337537 8.59985 0.759752 8.63319 1.0403 8.39708L1.72362 7.81931V12.9998C1.72362 14.2275 2.71805 15.2219 3.9458 15.2219H11.9456C13.1734 15.2219 14.1678 14.2275 14.1678 12.9998V7.81931L14.8484 8.39708C15.1289 8.63596 15.5511 8.59985 15.7872 8.3193C16.0233 8.03875 15.99 7.61654 15.7095 7.38043L8.37627 1.15833ZM3.05693 12.9998V6.68878L7.94572 2.54164L12.8345 6.68878V12.9998C12.8345 13.4914 12.4373 13.8886 11.9456 13.8886H10.6123V9.6665C10.6123 9.05262 10.1151 8.55541 9.50125 8.55541H6.3902C5.77632 8.55541 5.27911 9.05262 5.27911 9.6665V13.8886H3.9458C3.45414 13.8886 3.05693 13.4914 3.05693 12.9998ZM6.61242 13.8886V9.88872H9.27903V13.8886H6.61242Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3164_3009">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomeIcon;
