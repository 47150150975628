import styled, { css } from 'styled-components';

import { CardMainBody, CardTitle } from 'shared/components/Card/Card.styles';

export const VppCardWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;
    ${CardMainBody} {
      max-width: 440px;
      width: 100%;
      ${CardTitle} {
        margin: 0 0 16px 0;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.small}) {
      justify-content: flex-start;
      ${CardMainBody} {
        max-width: 225px;
        min-width: unset;
        ${CardTitle} {
          margin: 0 0 12px 0;
        }
      }
    }
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      ${CardMainBody} {
        height: 231px;
        width: 100%;
        max-width: 538px;
        ${CardTitle} {
          margin: 0 0 16px 0;
        }
      }
    }
    }
  `}
`;

export const VppCardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 0 0 44px;
    gap: 16px;
  }
`;
