import styled from 'styled-components';

import { Text } from 'shared/components/Text/Text';
import { AccordionItemStyled } from 'shared/components/Accordion/Accordion.styles';

export const Title = styled(Text)`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
  font-weight: 500;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: 28px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    display: none;
  }
`;

export const InputWrapper = styled.div`
  margin: 8px 0 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 16px;
  }
`;

export const FAQWrapper = styled.div`
  ${AccordionItemStyled} {
    margin-bottom: 16px;
  }
`;
