import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function BatteryBoltIcon({ color: colorProp, filled }: IconProps & { filled: boolean }) {
  const theme = useTheme();
  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.22222 4C0.994444 4 0 4.99444 0 6.22222V10.6667C0 11.8944 0.994444 12.8889 2.22222 12.8889H4.78333C4.425 12.4861 4.33611 11.9 4.58333 11.4028L4.73056 11.1111H2.22222C1.97778 11.1111 1.77778 10.9111 1.77778 10.6667V6.22222C1.77778 5.97778 1.97778 5.77778 2.22222 5.77778H6.08889L8.31111 4H2.22222ZM12.8889 11.1111H9.02222L6.8 12.8889H12.8889C14.1167 12.8889 15.1111 11.8944 15.1111 10.6667V10.2222C15.6028 10.2222 16 9.825 16 9.33333V7.55556C16 7.06389 15.6028 6.66667 15.1111 6.66667V6.22222C15.1111 4.99444 14.1167 4 12.8889 4H10.3278C10.6889 4.40278 10.775 4.98889 10.5278 5.48611L10.3806 5.77778H12.8889C13.1333 5.77778 13.3333 5.97778 13.3333 6.22222V10.6667C13.3333 10.9111 13.1333 11.1111 12.8889 11.1111ZM9.61389 4.54444C9.45278 4.41389 9.21944 4.41389 9.05556 4.54167L4.61111 8.09722C4.46389 8.21389 4.40556 8.41389 4.46944 8.59167C4.53333 8.76944 4.7 8.88889 4.88889 8.88889H6.83611L5.38056 11.8C5.28611 11.9861 5.33611 12.2139 5.5 12.3444C5.66389 12.475 5.89444 12.475 6.05833 12.3472L10.5028 8.79167C10.65 8.675 10.7083 8.475 10.6444 8.29722C10.5806 8.11944 10.4139 8 10.225 8H8.275L9.73056 5.08889C9.825 4.90278 9.775 4.675 9.61389 4.54444Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2924_6211)">
        <path
          d="M2.22222 4C0.994444 4 0 4.99444 0 6.22222V10.6667C0 11.8944 0.994444 12.8889 2.22222 12.8889H4.78333C4.46111 12.5278 4.35833 12.0139 4.51944 11.5556H2.22222C1.73056 11.5556 1.33333 11.1583 1.33333 10.6667V6.22222C1.33333 5.73056 1.73056 5.33333 2.22222 5.33333H6.64444L8.31111 4H2.22222ZM12.8889 11.5556H8.46667L6.8 12.8889H12.8889C14.1167 12.8889 15.1111 11.8944 15.1111 10.6667V10.2222C15.6028 10.2222 16 9.825 16 9.33333V7.55556C16 7.06389 15.6028 6.66667 15.1111 6.66667V6.22222C15.1111 4.99444 14.1167 4 12.8889 4H10.3278C10.65 4.36111 10.7528 4.875 10.5917 5.33333H12.8889C13.3806 5.33333 13.7778 5.73056 13.7778 6.22222V10.6667C13.7778 11.1583 13.3806 11.5556 12.8889 11.5556ZM9.61389 4.54444C9.45278 4.41389 9.21944 4.41389 9.05556 4.54167L4.61111 8.09722C4.46389 8.21389 4.40556 8.41389 4.46944 8.59167C4.53333 8.76944 4.7 8.88889 4.88889 8.88889H6.83611L5.38056 11.8C5.28611 11.9861 5.33611 12.2139 5.5 12.3444C5.66389 12.475 5.89444 12.475 6.05833 12.3472L10.5028 8.79167C10.65 8.675 10.7083 8.475 10.6444 8.29722C10.5806 8.11944 10.4139 8 10.225 8H8.275L9.73056 5.08889C9.825 4.90278 9.775 4.675 9.61389 4.54444Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2924_6211">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BatteryBoltIcon;
