import styled from 'styled-components';
import Text from 'shared/components/Text/Text';

export const MarkAllReadModalWrapper = styled.div`
  ${({ theme }) => `
  max-width: calc(100% - 48px);
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: ${theme.colors.cardElevationHighWhite};
  padding: 6px 24px 6px 24px;
  align-self: center;

  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding: 14px 24px 20px 24px;

    width: 472px;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    width: 614px;
  }

`}
`;

export const MarkAllReadModalHeader = styled(Text)`
  ${({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  justify-content: space-between;

  @media screen and (min-width: ${theme.breakpoints.small}) {
    font-size: 24px;
    line-height: 1.5;
  }
`}
`;

export const MarkAllReadModalBody = styled(Text)`
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-bottom: 8px;
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  padding: 8px 0 0 0;
  gap: 8px;
  justify-content: center;
  align-self: stretch;

  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding: 20px 0 0 0;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
  }
`}
`;
