import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import {
  getAgreement,
  getAgreementHomeownerContractId,
  getAgreementWorkflowId,
  updateAgreement,
  useGetTpvPreviewUrls,
} from 'api/agreement';
import { AgreementPreviewLink, AgreementType, ErrorResponse } from 'api/types';
import { useUser } from 'hooks/useUser';
import { toast } from 'shared/components/Toast/Toast';
import { AgreementsMap } from 'admin/modules/user-profile/components/AgreementsAccordion/AgreementsMap';
import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { HeaderText } from './AgreementProfile.styles';

export function AgreementProfile() {
  const { agreementId, homeownerContractId, workflowId } = useParams();
  const theme = useTheme();
  const { t } = useTranslation();
  const { refetch: refetchUser } = useUser();
  const handleChangeArchiveAgreement = (agreement: AgreementType) => {
    const archived = !agreement.archived;
    updateAgreement(agreement.id, { archived })
      .then(() => {
        refetchUser();
      })
      .catch((error: AxiosError) => {
        toast({
          type: 'error',
          title: t('toast.error'),
          message: error.message,
          theme,
        });
      });
  };

  const { mutateAsync: getTpvPreviewUrlAsync } = useGetTpvPreviewUrls({
    onError: (error) => {
      const errorData = error.response?.data as ErrorResponse;

      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorData.detail
          ? JSON.stringify(errorData.detail)
          : t('toast.somethingWentWrong'),
        theme,
      });
    },
  });

  const { data: agreementContract, isFetching: isFetchingHomeownerContract } = useQuery(
    ['agreement', homeownerContractId],
    () => getAgreementHomeownerContractId(homeownerContractId!).then((response) => response.data),
    {
      enabled: Boolean(homeownerContractId),
    },
  );
  const { data: agreementUsed, isFetching: isFetchingAgreement } = useQuery(
    ['agreement', agreementId],
    () => getAgreement(agreementId!).then((response) => response.data),
    {
      enabled: Boolean(agreementId),
    },
  );
  const { data: agreementWorkflow, isFetching: isFetchingWorkflowId } = useQuery(
    ['agreement', workflowId],
    () => getAgreementWorkflowId(workflowId!).then((response) => response.data),
    {
      enabled: Boolean(workflowId),
    },
  );

  const handleFetchAgreementTpvVideo = async (
    agreement: AgreementType,
  ): Promise<AgreementPreviewLink[]> => {
    const response = await getTpvPreviewUrlAsync({ agreement_id: agreement.id });
    return response.preview_urls;
  };

  let agreement = agreementUsed;
  if (agreementContract) {
    const [agreementAssign] = agreementContract;
    agreement = agreementAssign;
  } else if (agreementWorkflow) {
    const [agreementWorkflowAssign] = agreementWorkflow;
    agreement = agreementWorkflowAssign;
  }

  if (isFetchingAgreement || isFetchingHomeownerContract || isFetchingWorkflowId || agreement) {
    return (
      <>
        <HeaderText as="h1">Agreement Info</HeaderText>
        {agreement && (
          <AgreementsMap
            agreement={agreement}
            onChangeArchiveAgreement={handleChangeArchiveAgreement}
            onFetchPreviewVideoLinks={handleFetchAgreementTpvVideo}
          />
        )}
      </>
    );
  }
  return <ErrorFallback />;
}

export default AgreementProfile;
