/* eslint-disable class-methods-use-this */
import { differenceInHours, differenceInSeconds } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { AxisType, Tuple } from 'modules/types';
import { MonitoringDatum } from 'api/types';
import { AggregatedData } from 'api/system/types';
import { AnyChartDataProvider, YAxisLabel } from '../AnyChartDataProvider';
import { BatteryChartDataProvider } from './BatteryChartDataProvider';
import { DayChartDataProvider } from '../DayChartDataProvider';
import { generateDatesInRange } from '../generateDatesInRange';

export class BatteryDayChartDataProvider
  extends BatteryChartDataProvider
  implements AnyChartDataProvider
{
  largestProductionValue: number = 0.0;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue: number | null = null;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach((props) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { charge_kw, discharge_kw, time, unit } = props;
      /*
        The values will either be charge_kw or discharge_kw, but not both.
        One will always be 0, since you can't be charing and discharging
        at the same time
      */

      if (charge_kw === undefined || discharge_kw === undefined) {
        return;
      }

      const maxValue = Math.max(Math.abs(charge_kw), Math.abs(discharge_kw));
      const value: number = Math.abs(charge_kw) > 0.0 ? charge_kw : -1 * discharge_kw;
      if (!largestProductionUnit || !largestProductionValue) {
        largestProductionValue = maxValue;
        largestProductionUnit = unit;
      } else if (largestProductionValue && maxValue >= largestProductionValue) {
        largestProductionValue = maxValue;
        largestProductionUnit = unit;
      }
      this.largestProductionValue = largestProductionValue;
      const secondsSinceCurrentDate = differenceInSeconds(time, dateRange.startDate);
      const hoursSinceCurrentDate = secondsSinceCurrentDate / 60 / 60;
      dailyProduction.set(hoursSinceCurrentDate, {
        time,
        y: value,
        y_raw: Math.abs(value) === discharge_kw ? discharge_kw : charge_kw,
        unit,
      });
    });

    return [dailyProduction, null, largestProductionUnit];
  }

  getPowerRatingTotalCapacity = () => {
    if (
      this.system?.total_battery_output_rating_kw &&
      this.system?.total_battery_output_rating_kw > this.largestProductionValue
    ) {
      return this.system.total_battery_output_rating_kw;
    }
    return this.largestProductionValue;
  };

  getDomain = (_: DateRange, __: number | null): { x: Tuple<number>; y: Tuple<number> } => {
    const powerRatingCapacity = this.getPowerRatingTotalCapacity();
    return {
      x: [0, 24],
      y: [-1 * powerRatingCapacity, powerRatingCapacity],
    };
  };

  tickXFormat = DayChartDataProvider.tickXFormat;

  getTickValuesXAxis = (range: DateRange): number[] => {
    const numberOfTicks = 5;
    const tickValues = generateDatesInRange(range, numberOfTicks).map((date) =>
      differenceInHours(date, range.startDate),
    );
    return tickValues;
  };

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = (_: number): number[] => {
    const numberOfTicks = 5;
    const maximumValue = this.getPowerRatingTotalCapacity();
    const tickValues = Array.from(Array(numberOfTicks).keys()).map(
      (__, index) => -maximumValue + (maximumValue * 2 * index) / (numberOfTicks - 1),
    );
    return tickValues;
  };

  getYAxis = <T>(_: number | null, unit: T): AxisType => ({
    tickValues: this.getTickValuesYAxis(this.largestProductionValue),
    tickFormat: (value: number) => this.tickYFormat(Math.abs(value), unit),
  });

  yAxisLabels(t: (key: string) => string): { top: YAxisLabel; bottom: YAxisLabel } {
    return {
      top: {
        title: t('system.labels.yaxis.battery.top.title'),
        subtitle: t('system.labels.yaxis.battery.top.subtitle'),
      },
      bottom: {
        title: t('system.labels.yaxis.battery.bottom.title'),
        subtitle: t('system.labels.yaxis.battery.bottom.subtitle'),
      },
    };
  }

  tooltipDateFormatter = DayChartDataProvider.tooltipDateFormatter;

  getFlyoutLabels(t: (key: string) => string, y?: number): { y: string; y0: string } {
    return {
      y0: '',
      y:
        y && y < 0.0
          ? t('system.labels.flyout.battery.charged')
          : t('system.labels.flyout.battery.discharged'),
    };
  }
}

export default BatteryDayChartDataProvider;
