/* eslint-disable class-methods-use-this */
import { differenceInCalendarDays } from 'date-fns';

import { MonitoringDatum } from 'api/types';
import { DateRange } from 'api/system/utils/DateRange';
import { AggregatedData } from 'api/system/types';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { AnyChartDataProvider, YAxisLabel } from '../AnyChartDataProvider';
import { BatteryChartDataProvider } from './BatteryChartDataProvider';
import { WeekChartDataProvider } from '../WeekChartDataProvider';
import { ChartDataProvider } from '../ChartDataProvider';

export class BatteryWeekChartDataProvider
  extends BatteryChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 16;

  barWidth = 20;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach((props) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { discharge_kwh, charge_kwh, time, unit } = props;
      if (!discharge_kwh || !charge_kwh) {
        return;
      }
      const newValue = Math.max(Math.abs(discharge_kwh), Math.abs(charge_kwh));
      largestProductionValue = Math.max(largestProductionValue, newValue);
      if (largestProductionValue === newValue) {
        largestProductionUnit = unit;
      }
      const dateNumber = differenceInCalendarDays(time, dateRange.startDate);
      dailyProduction.set(dateNumber, {
        time,
        y0: Math.abs(discharge_kwh),
        y: charge_kwh > 0.0 ? -1 * charge_kwh : 0.0,
        y0_raw: discharge_kwh,
        y_raw: charge_kwh,
        unit,
      });
    });
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, WeekChartDataProvider.numberOfTicks - 1],
    y: [
      (maximumValue && -maximumValue) || -BatteryWeekChartDataProvider.placeholderMaximumValue,
      maximumValue || BatteryWeekChartDataProvider.placeholderMaximumValue,
    ],
  });

  tickXFormat = WeekChartDataProvider.tickXFormat;

  getTickValuesXAxis = WeekChartDataProvider.getTickValuesXAxis;

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  yTickLabelOffset = WeekChartDataProvider.yTickLabelOffset;

  getTickValuesYAxis = ChartDataProvider.getTickValuesYAxis(5);

  domainPaddingX: PaddingType = WeekChartDataProvider.domainPaddingX;

  getYAxis = <EnergyUnit>(maximumValue: number | null, unit: EnergyUnit | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(Math.abs(value), unit),
  });

  tooltipDateFormatter = WeekChartDataProvider.tooltipDateFormatter;

  getFlyoutLabels(t: (key: string) => string): { y: string; y0: string } {
    return {
      y0: t('system.labels.flyout.battery.discharged'),
      y: t('system.labels.flyout.battery.charged'),
    };
  }

  yAxisLabels(t: (key: string) => string): { top: YAxisLabel; bottom: YAxisLabel } {
    return {
      top: {
        title: t('system.labels.yaxis.battery.top.title'),
        subtitle: t('system.labels.yaxis.battery.top.subtitle'),
      },
      bottom: {
        title: t('system.labels.yaxis.battery.bottom.title'),
        subtitle: t('system.labels.yaxis.battery.bottom.subtitle'),
      },
    };
  }

  tooltipIndicatorHidden?: boolean | undefined = true;
}

export default BatteryWeekChartDataProvider;
