import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { toast } from 'shared/components/Toast/Toast';
import { getCurrentTpvStatus, useDownloadContract } from 'api/agreement';
import { AgreementType, UserType } from 'api/types';
import {
  AnswerNetVideoTPVStatusType,
  MilestoneType,
  WelcomeChecklistStatusType,
} from 'modules/types';
import { Button } from 'shared/components/Button/Button';
import { DownloadIcon } from 'shared/components/icons';
import { useQuery } from 'react-query';
import { ButtonFooter } from './ProjectQualification.styles';
import { ProjectQualificationCopy } from './ProjectQualificationCopy';

const POLLING_INTERVAL_IN_SECONDS = 5 * 1000;
const BACKOFF_TIME_IN_SECONDS = 30 * 1000;
const QUERY_KEY = 'tpvStatus';

export type ProjectQualificationContentProps = {
  user?: UserType;
  isLoadingWelcomeChecklistQuestions: boolean;
  agreement?: AgreementType;
  isCurrentMilestone: boolean;
  onOpenWelcomeChecklist: () => void;
  onOpenTpv: () => void;
};

export function ProjectQualification({
  user,
  isLoadingWelcomeChecklistQuestions,
  agreement,
  isCurrentMilestone,
  onOpenWelcomeChecklist,
  onOpenTpv,
}: ProjectQualificationContentProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [pollingInterval, setPollingInterval] = useState<number>(POLLING_INTERVAL_IN_SECONDS);

  const { mutate: download, isLoading } = useDownloadContract({
    onSuccess: (data) => {
      const { presigned_url: presignedUrl } = data;
      const link = document.createElement('a');
      link.href = presignedUrl;
      link.download = 'signed_everbright_contract.pdf';
      link.click();
      link.remove();
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    },
  });

  const tpvStatus: AnswerNetVideoTPVStatusType | undefined = useMemo(
    () =>
      agreement?.is_tpv_installer
        ? agreement.users_tpv_statuses.find((item) => item.user_id === user?.id)?.tpv_status
        : undefined,
    [agreement, user],
  );

  useQuery(
    [QUERY_KEY, agreement?.id],
    () => getCurrentTpvStatus(String(agreement?.id)).then((response) => response.data),
    {
      onSuccess: (data) => {
        if (data.tpv_status !== AnswerNetVideoTPVStatusType.PENDING) {
          window.location.reload();
        } else {
          setPollingInterval(POLLING_INTERVAL_IN_SECONDS);
        }
      },
      onError: () => {
        setPollingInterval(BACKOFF_TIME_IN_SECONDS);
      },
      enabled: Boolean(agreement?.id) && tpvStatus === AnswerNetVideoTPVStatusType.PENDING,
      refetchOnWindowFocus: false,
      refetchInterval: pollingInterval,
    },
  );

  const handleDownload = async () => {
    if (agreement) {
      download(agreement.id);
    } else {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('projectQualification.somethingWentWrong'),
        theme,
      });
    }
  };

  const shouldShowDWCContent: boolean = useMemo(
    () =>
      !!agreement &&
      !agreement.is_tpv_installer &&
      agreement.current_milestone === MilestoneType.CONTRACT_SIGNED &&
      agreement.welcome_checklist_status !== WelcomeChecklistStatusType.NOT_REQUIRED &&
      agreement.welcome_checklist_status !== WelcomeChecklistStatusType.PASSED,
    [agreement],
  );

  const shouldShowTPVContent: boolean = useMemo(
    () =>
      !!agreement &&
      agreement.is_tpv_installer &&
      (tpvStatus === undefined || tpvStatus === AnswerNetVideoTPVStatusType.PENDING),
    [agreement, tpvStatus],
  );

  return agreement ? (
    <div>
      <ProjectQualificationCopy
        welcomeChecklistStatus={agreement.welcome_checklist_status}
        isCurrentMilestone={isCurrentMilestone}
        tpvStatus={tpvStatus}
        shouldShowDWCContent={shouldShowDWCContent}
      />
      {shouldShowDWCContent &&
        agreement.welcome_checklist_status === WelcomeChecklistStatusType.REQUIRED && (
          <ButtonFooter>
            <Button
              onClick={handleDownload}
              label="Contract"
              iconRight
              Icon={DownloadIcon}
              styleVariant="tertiary"
              margin="0 0 0 auto"
              isLoading={isLoading}
            />

            <Button
              label="Complete Checklist"
              onClick={onOpenWelcomeChecklist}
              margin="0 0 0 20px"
              isLoading={isLoadingWelcomeChecklistQuestions}
            />
          </ButtonFooter>
        )}
      {shouldShowTPVContent && (
        <ButtonFooter>
          <Button
            onClick={handleDownload}
            label="Contract"
            iconRight
            Icon={DownloadIcon}
            styleVariant="tertiary"
            margin="0 0 0 auto"
            isLoading={isLoading}
          />

          <Button
            label={t('projectQualification.verificationButton')}
            onClick={onOpenTpv}
            margin="0 0 0 20px"
            isLoading={isLoadingWelcomeChecklistQuestions}
          />
        </ButtonFooter>
      )}
    </div>
  ) : null;
}

export default ProjectQualification;
