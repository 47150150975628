import React, { useEffect, useState } from 'react';
import {
  PRIMARY_ACCORDION_STYLE_VARIANT,
  TAccordionStyleVariantsKey,
} from 'theme/accordions/TAccordions';

import { useTheme } from 'styled-components';
import {
  AccordionItemButton,
  AccordionItemContent,
  AccordionItemStyled,
  Label,
  LabelIconWrapper,
  LabelWrapper,
  SubText,
} from './Accordion.styles';
import { Minus, Plus } from '../icons';

export type AccordionItemProps = {
  id: string;
  label: React.ReactNode;
  subText?: string;
  actionIndicator?: boolean;
  styleVariant: TAccordionStyleVariantsKey;
  contentBgColor?: string;
  size?: 'small' | 'large';
  isOpen?: boolean;
  children: React.ReactNode;
  icons?: { expanded: JSX.Element; minimized: JSX.Element };
};

/**
 * @param {string} [id] - id used for assigning accessibility control roles
 * @param {string} [label] - label for the button which toggles the accordion
 * @param {string} [subText] - subText for the button which toggles the accordion (optional)
 * @param {boolean} actionIndicator -  magenta dot next to the label. defaults to false (optional)
 * @param {TAccordionStyleVariantsKey} [styleVariant] - defaults to primary (optional)
 * @param {string} [contentBgColor] - background color for content
 * @param {'small' | 'large'} [size] - defaults to large (optional)
 * @param {boolean} isOpen -  defaults to false (optional)
 * @param {JSX.Element} [children] - shape of each accordion item
 * @param {{expanded: JSX.Element; minimized: JSX.Element}} [icons] - custom icons. not a part of the dsign system (optional)
 */

export function AccordionItem({
  id,
  label,
  subText,
  actionIndicator = false,
  styleVariant = PRIMARY_ACCORDION_STYLE_VARIANT,
  contentBgColor,
  isOpen = false,
  children,
  size = 'large',
  icons,
}: AccordionItemProps) {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const theme = useTheme();

  const iconColor =
    styleVariant === 'primary'
      ? theme.colors.textSystemInfoSecondary2100
      : theme.colors.textPrimarySecondary1100;

  useEffect(() => {
    setIsExpanded(isOpen);
  }, [isOpen]);

  const icon = isExpanded
    ? icons?.expanded || <Minus color={iconColor} size={size} />
    : icons?.minimized || <Plus color={iconColor} size={size} />;

  return (
    <AccordionItemStyled
      id={id}
      className="accordion-item"
      $styleVariant={styleVariant}
      $size={size}
      $isExpanded={isExpanded}
    >
      <AccordionItemButton
        id={`${id}-button`}
        aria-controls={`${id}-content`}
        aria-expanded={isExpanded ? 'true' : false}
        $isExpanded={isExpanded}
        $styleVariant={styleVariant}
        onClick={() => setIsExpanded(!isExpanded)}
        $size={size}
      >
        <LabelIconWrapper>
          <LabelWrapper>
            <Label as="h5" $styleVariant={styleVariant}>
              {label}
            </Label>
            {actionIndicator && (
              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse cx="2.97" cy="3" rx="2.97" ry="3" fill="#AF4379" />
              </svg>
            )}
          </LabelWrapper>
          {icon}
        </LabelIconWrapper>
        {subText && <SubText as="h5">{subText}</SubText>}
      </AccordionItemButton>
      <AccordionItemContent
        id={`${id}-content`}
        aria-labelledby={`${id}-button`}
        aria-hidden={isExpanded ? 'false' : 'true'}
        $isExpanded={isExpanded}
        $bgColor={contentBgColor}
        $size={size}
        $styleVariant={styleVariant}
      >
        {children}
      </AccordionItemContent>
    </AccordionItemStyled>
  );
}

export default AccordionItem;
