import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function DotIcon({ color }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle id="Ellipse 3" cx="4" cy="4.30078" r="4" fill={fillColor} />
    </svg>
  );
}

export default DotIcon;
