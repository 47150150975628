import React from 'react';
import { ChartFill } from './DayChart/AreaChart.types';

export function ChartLinearGradient({ fillColors }: { fillColors: ChartFill }) {
  return (
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(90)">
        <stop stopColor={fillColors.start} stopOpacity="0.9" />
        <stop offset=".5" stopColor={fillColors.middle} stopOpacity="0.45" />
        <stop offset="1" stopColor={fillColors.end} stopOpacity="0.15" />
      </linearGradient>
    </defs>
  );
}

export default ChartLinearGradient;
