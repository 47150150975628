import Text from 'shared/components/Text/Text';
import styled, { css } from 'styled-components';

export const VppCardTotalEarnedValue = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.textPrimarySecondary1100};
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    font-style: normal;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      font-size: 24px;
      line-height: 1.5;
    }
  `}
`;

export const VppCardTotalEarnedValueSuffix = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.textPrimarySecondary1100};
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    font-style: normal;
    padding: 0px 0px 1px 4px;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      padding: 0px 0px 4px 4px;
    }
  `}
`;

export const VppCardTotalEarnedTextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
