import React from 'react';
import { useTranslation } from 'react-i18next';

import { DateRange } from 'api/system/utils/DateRange';
import { format } from 'date-fns';
import { FlyoutProps } from 'victory';
import { TooltipIcon } from 'shared/components/icons';
import { useTheme } from 'styled-components';
import { useBreakpoints } from 'hooks/useWindowSize';
import { getTooltipPosition } from 'modules/system/utils/getTooltipPosition';
import { DateRangeZoomLevel } from 'api/types';
import { ToolTipTextContainer, Line, ToolTipkW, TooltipTime } from '../Chart.styles';
import { getChartStyles, getDayChartStyles } from '../getChartStyles';
import { Label, LabelValueContainer } from './ToolTip.styles';

export const valueFormatted = (value: number, unit: string) => `${value.toFixed(2)} ${unit}`;

export const timeFormatted = (time: Date, dateFormat: string = 'hh:mm a') =>
  format(time, dateFormat);

interface Props extends FlyoutProps {
  datum?: FlyoutProps['datum'] & {
    unit: string;
    value: number;
    time: Date;
    customTooltip?: {date: string; value: string};
    y: number;
    y0?: number;
    y_raw?: number;
    y0_raw?: number;
  };
  dateRange?: DateRange;
  tooltipDateFormatter?: (date: Date, dateRange: DateRange) => string;
  chartType: 'area' | 'bar';
  tooltipColor: string;
  flyoutLabels?: (t: (key: string) => string, y?: number) => { y: string; y0: string } | undefined;

}

export interface TooltipPosition {
  x: number;
  y: number;
}

export function ToolTip({ x, y, datum, dateRange, tooltipDateFormatter, chartType, tooltipColor, flyoutLabels, scale }: Props) {
  const theme = useTheme();
  const screenSize = useBreakpoints() || 'lg';

  const { time, unit, customTooltip,  } = datum!;
  const tooltipWidth = 210;

  const { t } = useTranslation();
  
  const labels = flyoutLabels && flyoutLabels(t, datum?.y );
  const shouldRenderY0 = Boolean(labels?.y0 && datum?.y0 !== undefined);

  const chartStyles =
    chartType === 'area'
      ? getDayChartStyles(theme, screenSize)
      : getChartStyles(theme, screenSize, dateRange?.zoomLevel || DateRangeZoomLevel.WEEK);

  const tooltipIconPosition = {
    x: x ? x - 14 : x,
    y: y ? y - 14 : y,
  };

  const yValue = customTooltip ? customTooltip.value : valueFormatted(datum?.y_raw || datum?.y!, unit);
  const y0Value = shouldRenderY0 ? valueFormatted(datum?.y0_raw || datum?.y0!, unit) : ''

  const yCharLength = yValue.length + (labels?.y.length || 0)
  const y0CharLength = y0Value.length + (labels?.y0.length || 0);

  const biggestCharLength = Math.max(yCharLength, y0CharLength)


  const tooltipTextPosition = getTooltipPosition({
    screenSize,
    dataPosition: { x: x || 0, y: y || 0 },
    chartType,
    hasLabels: Boolean(labels?.y || labels?.y0),
    tooltipCharWidth: biggestCharLength
  });

  // scale.y(0) keeps the tooltip at y = 0 
  if (shouldRenderY0 &&
  scale &&
  typeof scale === 'object' && // Ensure scale is an object, not a string
  'y' in scale && // Ensure 'y' exists in scale
  typeof scale.y === 'function' // Ensure scale.y is a function
  )  {
    const tooltipContainerOffset = screenSize === 'lg' || screenSize === 'xl' ? 59 : 54
    tooltipTextPosition.y = scale.y(0) - tooltipContainerOffset
  }


  return (
    <g style={{ top: 0, position: 'relative' }}>
      <Line x1={x} x2={x} y1={chartStyles.tooltip.lineHeight} y2={chartStyles.tooltip.y2} />
      <foreignObject
        x={tooltipTextPosition.x}
        y={tooltipTextPosition.y}
        width={tooltipWidth}
        height="44"
        style={{ overflow: 'visible' }}
      >
        <ToolTipTextContainer>
        {customTooltip ? 
           <ToolTipkW as="p" $color={tooltipColor}>{customTooltip.date}</ToolTipkW>
           : time && dateRange && tooltipDateFormatter && <TooltipTime as="p">{tooltipDateFormatter(time, dateRange)}</TooltipTime>
        }

        {shouldRenderY0 && (
          <LabelValueContainer>
            <Label as="p">{labels?.y0}</Label>
            <ToolTipkW as="p" $color={tooltipColor}>{y0Value}</ToolTipkW>
          </LabelValueContainer>
        )}
  
          <LabelValueContainer>
            {labels?.y && <Label as="p">{labels.y}</Label>}
            <ToolTipkW as="p" $color={tooltipColor}>{yValue}</ToolTipkW>
          </LabelValueContainer>
        </ToolTipTextContainer>
      </foreignObject>
      <foreignObject
        x={tooltipIconPosition.x}
        y={tooltipIconPosition.y}
        style={{ overflow: 'visible' }}
      >
        {!shouldRenderY0 && <TooltipIcon color={tooltipColor} />}
      </foreignObject>
    </g>
  );
}

export default ToolTip;
