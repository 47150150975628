import React, { useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';

import DataDogLogs from 'shared/logging/DataDogLogs';
import { submitWelcomeChecklist } from 'api/agreement';
import { Modal } from 'shared/components/Modal/Modal';
import useUser from 'hooks/useUser';
import { CtaButtons } from 'shared/components/Modal/types';
import { getPrimaryLanguage } from 'shared/utils/getPrimaryLanguage';
import { toast } from 'shared/components/Toast/Toast';
import { AgreementType } from 'api/types';
import { WarningText } from 'shared/components/UnsavedChangesModal/UnsavedChanges.styles';
import { WelcomeChecklistItemType, WelcomeChecklistResult } from '../../../types';
import { WelcomeChecklistHeader } from './WelcomeChecklistHeader';
import { Questions, SkippedQuestionsType } from './Questions';
import { ReviewAnswers } from './ReviewAnswers';

type Props = {
  agreement: AgreementType;
  checklistQuestions: WelcomeChecklistItemType[];
  isOpen: boolean;
  onCloseWelcomeChecklist: (welcomeChecklistResult?: WelcomeChecklistResult) => void;
};

export function WelcomeChecklist({
  agreement,
  isOpen,
  checklistQuestions,
  onCloseWelcomeChecklist,
}: Props) {
  const { userResult } = useUser();
  const [activePage, setActivePage] = useState(1);
  const [useQuestions, setUseQuestions] = useState<WelcomeChecklistItemType[]>([]);
  const [confirmed, setConfirmed] = useState(false);
  const [showAckRequired, setShowAckRequired] = useState(false);
  const [skippedQuestions, setSkippedQuestions] = useState<SkippedQuestionsType>({});
  const [message, setMessage] = useState<{ error: boolean; message: React.ReactNode }>({
    error: false,
    message: '',
  });
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: submitWelcomeChecklist,
    onSuccess: ({ data }) => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('welcomeChecklist.submitted'),
        theme,
      });

      if (data.result === WelcomeChecklistResult.PASS) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        onCloseWelcomeChecklist(data.result);
      } else {
        const callSupportCopy = t('welcomeChecklist.callSupport');
        setMessage({
          error: false,
          message: callSupportCopy ? <Trans>{callSupportCopy}</Trans> : '',
        });
      }
    },
    onError: (error: AxiosError) => {
      const { response } = error;
      const data: any = response?.data;
      let errorMessage = userResult?.impersonator
        ? "You're impersonating you can't do that"
        : t('welcomeChecklist.updatesNotSaved');

      if (response && response.status === 409 && data.detail) {
        errorMessage = data.detail;
      }

      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorMessage,
        theme,
      });

      DataDogLogs.error(
        'Status - WelcomeChecklist',
        {
          ...(userResult?.user ? { user: userResult?.user } : {}),
          agreement,
          additionalInfo: {
            welcomeChecklist: useQuestions,
          },
        },
        error,
      );
    },
  });

  useEffect(() => {
    setUseQuestions(checklistQuestions);
  }, [checklistQuestions]);

  useEffect(() => {
    const primaryLanguage = getPrimaryLanguage(agreement.primary_language);
    if (primaryLanguage === 'es') {
      i18n.changeLanguage('es');
    }
  }, [i18n, agreement]);

  const totalPages =
    useQuestions.reduce((lastPage, question) => Math.max(lastPage, question.page), 0) + 1;
  const lastPage = totalPages;

  const isReviewPage = activePage === lastPage;
  const hasContinueButton = activePage !== lastPage;

  const questions = useMemo(
    () => useQuestions.filter((question) => activePage === question.page),
    [activePage, useQuestions],
  );

  const nextPage = () => {
    const skipped: SkippedQuestionsType = {};
    let firstError: HTMLElement | null = null;

    questions.forEach((question, index) => {
      if (!question.answer) {
        firstError = document.getElementById(`checklist-item-${index}`);
        skipped[index] = question;
      }
    });

    if (firstError) {
      (firstError as HTMLElement).scrollIntoView();

      setSkippedQuestions(skipped);
    } else if (activePage < totalPages) {
      setSkippedQuestions({});
      setActivePage(activePage + 1);
    }
  };

  const handlePreviousPagePress = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleChangeAnswer = (activePageIndex: number, answer: string) => {
    setUseQuestions((items) => {
      const updatedQuestions = [...items];
      const questionsOnPreviousPages = updatedQuestions.filter(
        (question) => question.page < activePage,
      );
      const questionIndex = questionsOnPreviousPages.length + activePageIndex;
      updatedQuestions[questionIndex].answer = answer;
      return updatedQuestions;
    });
  };

  const handleChangeConfirmed = () => {
    setConfirmed((c) => !c);
  };

  const handleSubmit = async () => {
    if (confirmed) {
      setShowAckRequired(false);

      await mutate({
        agreement,
        welcomeChecklist: useQuestions,
      });
    } else {
      setShowAckRequired(true);
    }
  };

  const handleShowWarningModal = () => {
    const hasAnswers = !!useQuestions.find((q) => q.answer);
    if (hasAnswers) {
      setShowWarningModal(true);
    } else {
      onCloseWelcomeChecklist();
    }
  };

  const ctaButtons = () => {
    const buttons: CtaButtons = {
      tertiary: undefined,
      primary: undefined,
    };

    if (showWarningModal) {
      buttons.tertiary = {
        label: t('cta.cancel'),
        onClick: () => {
          setShowWarningModal(false);
        },
      };

      buttons.permanentPrimary = {
        label: t('unsavedChanges.leave'),
        onClick: onCloseWelcomeChecklist,
      };

      return buttons;
    }

    if (message.message) {
      buttons.primary = isError
        ? {
            label: t('cta.tryAgain'),
            onClick: () => {
              setMessage({ error: false, message: '' });
            },
          }
        : { label: t('cta.close'), onClick: onCloseWelcomeChecklist };

      buttons.tertiary = isError
        ? { label: t('cta.cancel'), onClick: onCloseWelcomeChecklist }
        : undefined;
    } else {
      buttons.primary = hasContinueButton
        ? { label: t('cta.continue'), onClick: nextPage }
        : { label: t('cta.submit'), onClick: handleSubmit, isLoading };

      buttons.tertiary =
        activePage > 1 ? { label: t('cta.back'), onClick: handlePreviousPagePress } : undefined;
    }

    return buttons;
  };

  let modalContent = isReviewPage ? (
    <ReviewAnswers
      checklist={useQuestions}
      confirmed={confirmed}
      showAckRequired={showAckRequired}
      onChangeConfirmed={handleChangeConfirmed}
      message={message.message}
      primaryLanguage={getPrimaryLanguage(agreement.primary_language)}
    />
  ) : (
    <Questions
      questions={questions}
      skippedQuestions={skippedQuestions}
      primaryLanguage={getPrimaryLanguage(agreement.primary_language)}
      onChangeAnswer={handleChangeAnswer}
    />
  );

  if (showWarningModal) {
    modalContent = <WarningText as="p">{t('unsavedChanges.changesMayBeLost')}</WarningText>;
  }

  return (
    <Modal
      title={
        showWarningModal
          ? t('unsavedChanges.unsavedChanges')
          : t('welcomeChecklist.welcomeChecklist')
      }
      contentLabel={
        showWarningModal
          ? t('unsavedChanges.unsavedChanges')
          : t('welcomeChecklist.welcomeChecklist')
      }
      isOpen={isOpen}
      onRequestClose={handleShowWarningModal}
      totalPages={totalPages - 1}
      activePage={message.message || showWarningModal ? undefined : activePage}
      isFullWidth
      isFullHeight
      isLoading={isLoading}
      subHeader={
        isReviewPage || Boolean(message.message) || showWarningModal ? null : (
          <WelcomeChecklistHeader />
        )
      }
      ctaButtons={ctaButtons()}
      styleVariant="tertiary"
      hasElevationEffect
    >
      {modalContent}
    </Modal>
  );
}

export default {};
