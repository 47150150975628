import React from 'react';
import { useTheme } from 'styled-components';
import { IIconProps } from '../TIcon';

export function MilestoneIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.iconIconPrimarySecondary1100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M13.0003 5.82996C12.8303 5.82996 12.6803 5.72996 12.6303 5.56996L12.0003 3.89996L10.3303 3.27996C10.1703 3.21996 10.0703 3.06996 10.0703 2.90996C10.0703 2.74996 10.1703 2.58996 10.3303 2.53996L12.0003 1.91996L12.6303 0.249956C12.7503 -0.0600439 13.2603 -0.0600439 13.3803 0.249956L14.0103 1.91996L15.6803 2.53996C15.8403 2.59996 15.9403 2.74996 15.9403 2.90996C15.9403 3.06996 15.8403 3.22996 15.6803 3.27996L14.0103 3.89996L13.3803 5.56996C13.3203 5.72996 13.1703 5.82996 13.0103 5.82996H13.0003ZM12.7503 3.61996L13.0003 4.28996L13.3103 3.44996C13.3503 3.33996 13.4403 3.25996 13.5403 3.21996L14.3803 2.90996L13.5403 2.59996C13.4303 2.55996 13.3503 2.46996 13.3103 2.36996L13.0003 1.52996L12.6903 2.36996C12.6503 2.47996 12.5603 2.55996 12.4603 2.59996L11.6203 2.90996L12.4603 3.21996C12.5703 3.25996 12.6503 3.34996 12.6903 3.44996L12.7503 3.61996Z"
        fill={color}
      />
      <path
        id="Vector_2"
        d="M5.95 13.81C5.76 13.81 5.58 13.7 5.5 13.52L3.85 9.94998L0.29 8.29998C0.11 8.21998 0 8.03998 0 7.84998C0 7.65998 0.11 7.47998 0.29 7.39998L3.86 5.74998L5.51 2.17998C5.67 1.82998 6.25 1.82998 6.42 2.17998L8.07 5.74998L11.64 7.39998C11.82 7.47998 11.93 7.65998 11.93 7.84998C11.93 8.03998 11.82 8.21998 11.64 8.29998L8.07 9.94998L6.42 13.52C6.34 13.7 6.16 13.81 5.97 13.81H5.95ZM1.21 7.85998L4.45 9.35998L5.95 12.6L7.45 9.35998L10.69 7.86998L7.45 6.36998L5.96 3.12998L4.46 6.36998L1.21 7.86998V7.85998Z"
        fill={color}
      />
      <path
        id="Vector_3"
        d="M13.0003 15.8301C12.8303 15.8301 12.6803 15.7301 12.6303 15.5701L12.0003 13.9001L10.3303 13.2701C10.1703 13.2101 10.0703 13.0601 10.0703 12.9001C10.0703 12.7401 10.1703 12.5801 10.3303 12.5301L12.0003 11.9001L12.6303 10.2301C12.7503 9.92006 13.2603 9.92006 13.3803 10.2301L14.0103 11.9001L15.6803 12.5301C15.8403 12.5901 15.9403 12.7401 15.9403 12.9001C15.9403 13.0601 15.8403 13.2201 15.6803 13.2701L14.0103 13.9001L13.3803 15.5701C13.3203 15.7301 13.1703 15.8301 13.0103 15.8301H13.0003ZM11.6103 12.9001L12.4503 13.2101C12.5603 13.2501 12.6403 13.3401 12.6903 13.4501L13.0003 14.2901L13.3103 13.4501C13.3503 13.3401 13.4403 13.2601 13.5503 13.2101L14.3903 12.9001L13.5503 12.5901C13.4403 12.5501 13.3603 12.4601 13.3103 12.3501L13.0003 11.5101L12.6903 12.3501C12.6503 12.4601 12.5603 12.5401 12.4503 12.5901L11.6103 12.9001Z"
        fill={color}
      />
    </svg>
  );
}

export default MilestoneIcon;
