/* eslint-disable class-methods-use-this */
import { differenceInCalendarDays } from 'date-fns';

import { DateRange } from 'api/system/utils/DateRange';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { MonitoringDatum } from 'api/types';
import { AggregatedData } from 'api/system/types';
import { GridChartDataProvider } from './GridChartDataProvider';
import { MonthChartDataProvider } from '../MonthChartDataProvider';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { ChartDataProvider } from '../ChartDataProvider';

export class GridMonthChartDataProvider
  extends GridChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 30.0;

  t: (key: string) => string;

  constructor(t: (key: string) => string) {
    super();
    this.t = t;
  }

  barWidth = MonthChartDataProvider.barWidth;

  shouldShowDateLabel = MonthChartDataProvider.shouldShowDateLabel;

  getDomain = (
    dateRange: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => {
    const monthOfFirstDate = dateRange.startDate.getMonth();
    const daysInMonth = new Date(
      dateRange.startDate.getFullYear(),
      monthOfFirstDate + 1,
      0,
    ).getDate();
    return {
      x: [0, daysInMonth - 1],
      y: [
        -1 * (maximumValue || GridMonthChartDataProvider.placeholderMaximumValue),
        maximumValue || GridMonthChartDataProvider.placeholderMaximumValue,
      ],
    };
  };

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach((props) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { export_kwh, import_kwh, time, unit } = props;
      if (!export_kwh || !import_kwh) {
        return;
      }
      const newValue = Math.max(Math.abs(export_kwh), Math.abs(import_kwh));
      largestProductionValue = Math.max(largestProductionValue, newValue);
      if (largestProductionValue === newValue) {
        largestProductionUnit = unit;
      }
      const dateNumber = differenceInCalendarDays(time, dateRange.startDate);
      if (dateNumber >= 0) {
        dailyProduction.set(dateNumber, {
          time,
          y0: import_kwh > 0.0 ? Math.abs(import_kwh) : 0.0,
          y: export_kwh > 0.0 ? -1 * export_kwh : 0.0,
          y0_raw: import_kwh,
          y_raw: export_kwh,
          unit,
        });
      }
    });

    const percentOfLargestProductionValue = 0.01;

    dailyProduction.forEach((_: any, key: number) => {
      const production = dailyProduction.get(key);
      if (!production) {
        return;
      }
      if (production.y0 === 0.0) {
        production.y0 = percentOfLargestProductionValue * largestProductionValue;
      }
      if (production.y === 0.0) {
        production.y = -1 * percentOfLargestProductionValue * largestProductionValue;
      }
    });

    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  tickXFormat = (day: number, range: DateRange) =>
    MonthChartDataProvider.tickXFormat(day, range, this.shouldShowDateLabel, this.t);

  domainPaddingX: PaddingType = MonthChartDataProvider.domainPaddingX;

  yTickLabelOffset = MonthChartDataProvider.yTickLabelOffset;

  getTickValuesXAxis = MonthChartDataProvider.getTickValuesXAxis;

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  getTickValuesYAxis = ChartDataProvider.getTickValuesYAxis(5);

  getYAxis = <T>(maximumValue: number | null, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(Math.abs(value), unit),
  });

  tooltipDateFormatter = MonthChartDataProvider.tooltipDateFormatter;

  getFlyoutLabels(t: (key: string) => string): { y: string; y0: string } {
    return {
      y0: t('system.labels.flyout.grid.imported'),
      y: t('system.labels.flyout.grid.exported'),
    };
  }

  tooltipIndicatorHidden?: boolean | undefined = true;
}

export default GridMonthChartDataProvider;
