import {
  MilestoneType,
  WelcomeChecklistStatusType,
  WelcomeChecklistType,
  AnswerNetVideoTPVStatusType,
} from 'modules/types';
import { PrimaryLanguage, RolesKey, SentEmailType, SentNotificationType } from 'modules/types/util';

export interface Contract {
  presigned_url: string;
}

export interface AccountType {
  id: string;
  homeowner_account_id: string;
  agreements: AgreementType[];
  archived: boolean;
}

export interface AddressType {
  id: string;
  street_1: string;
  street_2: string;
  city: string;
  state: string;
  zip_code: string;
  archived: boolean;
  confirmed: boolean;
}

export type BillingAddressType = Omit<AddressType, 'id' | 'archived' | 'confirmed'>;

export type AddressBodyType = Omit<AddressType, 'id' | 'confirmed'>;

export enum ContractType {
  LOA = 'LOA',
  LSE = 'LSE',
  PPA = 'PPA',
}

export interface AgreementType {
  id: string;
  current_milestone: MilestoneType;
  account_users_summaries: { id: string; email: string; name: string; claimed: boolean }[];
  users_tpv_statuses: { user_id: string; tpv_status: AnswerNetVideoTPVStatusType }[];
  ach_discount: number | undefined;
  ach_discount_eligible: boolean;
  ach_elected: boolean;
  amount_financed: number | null;
  buydown_amount: number;
  commencement_date: Date | null;
  contract_signed_date: string;
  contract_start_date: string;
  contract_term: number | undefined;
  contract_type: string;
  current_balance: number;
  first_payment_date: Date | null;
  homeowner_contract_id: string;
  homeowner_account_id: string;
  in_service_date: Date | null;
  initial_balance: number;
  interest_rate: number | null;
  is_ric: boolean;
  is_beam: boolean;
  great_america_application_ids: number[];
  monthly_payment: number | undefined;
  number_of_payments: number;
  platform_uuid: string;
  pto_date: Date | null;
  product_display_name: string;
  product_name: string;
  product_type: string;
  product_version: number | null;
  reamortization_dates: [Date];
  rate_contract: number | undefined;
  rate_escalator: number | null;
  system: SystemType;
  archived: boolean;
  welcome_checklists: WelcomeChecklistType[];
  welcome_checklist_upload_subtask_id: string | null;
  welcome_checklist_complete_subtask_id: string | null;
  welcome_checklist_status: WelcomeChecklistStatusType;
  organization: OrganizationType | null;
  primary_language: PrimaryLanguage;
  authoritative_copy_acknowledged: boolean;
  is_tpv_installer: boolean;
}

export interface BatteryType {
  id: string;
  manufacturer: string;
  model: string;
  capacity: number;
  power_rating: number;
  count: number;
  archived: boolean;
}

export type LoginRequestBody = {
  email: string;
  password: string;
};

export interface OrganizationType {
  id: string;
  organization_id: string;
  name: string;
  logo_image_url?: string;
  website_url: string;
}

export interface SystemType {
  id: string;
  panels: string | undefined;
  inverters: string | undefined;
  capacity: number | undefined;
  estimated_offset: string | undefined;
  system_mounting_type: string | undefined;
  inverter_manufacturer_id: string | undefined;
  site_id: string | undefined;
  vendor_id: number | undefined;
  install_cost: number;
  inverter_manufacturer_monitoring_id: string | undefined;
  address: AddressType;
  batteries: BatteryType[];
  archived: boolean;
  has_solar_monitoring: boolean;
  has_battery_monitoring: boolean;
  has_home_monitoring: boolean;
  has_grid_monitoring: boolean;
  has_ct: boolean;
  site_timezone: string | undefined;
  total_battery_capacity_kwh: number | null;
  total_battery_output_rating_kw: number | undefined;
}

export interface UserType {
  id: string;
  agreements: AgreementType[];
  address: AddressType;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: RolesKey;
  claimed_account: boolean;
  date_joined: string;
  accounts: AccountType[];
  archived: boolean;
  claimed_date: string;
  sent_emails: SentEmailType[];
  sent_notifications: SentNotificationType[];
  last_password_reset_date: string;
  last_login: string;
  research_opt_in: boolean;
  privacy_policy_accepted: boolean;
}

export interface UserFormType {
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  phone_number: string;
  role: RolesKey;
  address?: {
    street_1?: string;
    street_2?: string;
    city?: string;
    state?: string;
    zip_code?: string;
  };
}

export interface AnswerNetVideoTPVType {
  user: UserType;
  agreement: AgreementType;
  confirmation_code: string;
  status: AnswerNetVideoTPVStatusType;
  video_resource_id: string;
  video_uploaded: boolean;
}

export interface TagType {
  id: string;
  tag: string;
}

export interface FaqType {
  id: string;
  question: string;
  answer: string;
  hidden: boolean;
  order: number;
  tags: TagType[];
  url_slug: string;
}

export enum FlagName {
  webV2_1 = 'Web V2 (2.1) - Active',
  achModal = 'ACH Modal - Active',
  webDarkTheme = 'Web Dark Theme',
  surfly = 'Surfly',
  systemCategories = 'Web: System Categories',
  systemCategoriesBattery = 'Web: System Categories - Battery',
  systemCategoriesGrid = 'Web: System Categories - Grid',
  systemCategoriesHome = 'Web: System Categories - Home',
  systemCategoriesSolar = 'Web: System Categories - Solar',
  tpv = 'TPV',
  vpp_2024 = 'VPP Participation Web 2024',
}

export enum FlagKey {
  webV2_1 = 'web_login_v2',
  achModal = 'ach_modal',
  webDarkTheme = 'web_dark_mode',
  surfly = 'surfly',
  systemCategories = 'web_system_categories',
  systemCategoriesBattery = 'web_system_categories_battery',
  systemCategoriesGrid = 'web_system_categories_grid',
  systemCategoriesHome = 'web_system_categories_home',
  systemCategoriesSolar = 'web_system_categories_solar',
  tpv = 'tpv',
  vpp_2024 = 'vpp_participation_web_2024',
}

export type FeatureFlag = {
  archived: boolean;
  date_created: string;
  date_updated: string;
  enabled: boolean;
  id: string;
  name: FlagName;
  key: FlagKey;
};

export interface StatsCountType {
  value: string;
  count: number;
}
export interface AgreementStatsType {
  product_names: StatsCountType[];
  product_types: StatsCountType[];
  contract_types: StatsCountType[];
  states: StatsCountType[];
  current_milestones: StatsCountType[];
  welcome_checklist_results: StatsCountType[];
}

export interface SeriesData {
  date: string;
  count: number;
}
export interface UserStatsType {
  total_homeowners_invited: number;
  total_homeowner_accounts_claimed: number;
  claimed_offset: number;
  invited: SeriesData[];
  claimed: SeriesData[];
}

export interface ErrorResponse {
  detail: {};
}

export interface AgreementPreviewLink {
  confirmation_code: string;
  video_url: string;
  transcript_url: string;
}

export {
  DateRangeZoomLevel,
  BatteryStatusValue,
  BucketSize,
  ChartQueryType,
  GridStatusValue,
  SolarEdgeStatusValue,
} from './system/types';
export type {
  EnergyUnit,
  MonitoringDataParams,
  MonitoringDatum,
  PowerFlowDataResponse,
  PowerFlowDatum,
  PowerUnit,
  SiteTimezoneResponse,
} from './system/types';

export type {
  EnergyAllocationType,
  EnergySummaryType,
  MonitoringResponseType,
  RawMonitoringDatumType,
} from './system/monitoringTypes';

export type { InboxMessageType, InboxMessagesPaginatedResponseType } from './inboxMessage/types';

export { MessageCategory } from './inboxMessage/types';
