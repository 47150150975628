import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressType, AgreementType } from 'api/types';
import { Header } from 'shared/components/Header/Header';
import { OverHeaderWrapper } from './OverviewHeader.styles';
import { AddressSelect } from '../AddressSelect/AddressSelect';

type Props = {
  selectedAgreement: AgreementType;
  agreements: AgreementType[];
  userAddress: AddressType;
  onAgreementChange: (agreementId: string) => void;
};

export function OverviewHeader({
  selectedAgreement,
  userAddress,
  agreements,
  onAgreementChange,
}: Props) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const optionsArray: { id: string; address: AddressType }[] = [];
    agreements.forEach((agreement) => {
      optionsArray.push({ id: agreement.id, address: agreement.system.address });
    });

    return optionsArray;
  }, [agreements]);

  return (
    <OverHeaderWrapper>
      <Header pageName="OVERVIEW" title={t('overview.welcomeBack')} />

      <AddressSelect
        selectedOption={{ id: selectedAgreement.id, address: userAddress }}
        options={options}
        onSelectOption={onAgreementChange}
      />
    </OverHeaderWrapper>
  );
}

export default OverviewHeader;
