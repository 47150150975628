import React from 'react';

import { YAxisLabelStyled, YAxisLabelSubTitle, YAxisLabelTitle } from './YAxisLabel.styles';

export type Props = {
    labels: {
      title: string;
      subtitle: string;
    };
    titleColor: string;
  };
  
  
export function YAxisLabel({ labels: { title, subtitle }, titleColor }: Props) {
    return (
        <YAxisLabelStyled>
            <YAxisLabelTitle as="h5" $color={titleColor}>
                {title}
            </YAxisLabelTitle>
            <YAxisLabelSubTitle as="p">
                {subtitle}
            </YAxisLabelSubTitle>
      </YAxisLabelStyled>
    );
  }

export default YAxisLabel