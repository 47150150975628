import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AgreementTableStateType } from 'contexts/AdminContext';
import Table from 'shared/components/Table/Table';
import TableCellString from 'shared/components/Table/TableCell/TableCellString/TableCellString';
import TableCellUser from 'shared/components/Table/TableCell/TableCellUser/TableCellUser';
import { AgreementType } from 'api/types';
import TableCellButton from 'shared/components/Table/TableCell/TableCellButton/TableCellButton';

type Props = {
  agreementsTableState: AgreementTableStateType;
  setAgreementsTableState: React.Dispatch<any>;
};

function AgreementsTable({ agreementsTableState, setAgreementsTableState }: Props) {
  const { sortBy, direction, agreements } = agreementsTableState;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(`${pathname.replace('agreements', 'users')}/${id}`);
  };

  const handleClickHomeownerContractId = (id: string) => {
    navigate(`${pathname.replace('agreements', 'contract')}/${id}`);
  };

  const handleClickWorkflowId = (id: string) => {
    navigate(`${pathname.replace('agreements', 'workflow')}/${id}`);
  };

  const updateSort = (column: string) => {
    const toggledDirection = direction === 'ascending' ? 'descending' : 'ascending';
    setAgreementsTableState({
      ...agreementsTableState,
      direction: sortBy === column ? toggledDirection : direction,
      sortBy: column,
    });
  };

  const welcomeChecklistResult = (agreement: AgreementType) => {
    const welcomeChecklists = agreement.welcome_checklists;
    if (welcomeChecklists.length === 0) return null;

    // Get the last welcome checklist result
    return welcomeChecklists[welcomeChecklists.length - 1].result;
  };

  const columns = [
    { title: 'ID', handleSort: () => updateSort('homeowner_contract_id') },
    { title: 'Workflow ID', handleSort: () => updateSort('platform_uuid') },
    { title: 'Associated Users' },
    { title: 'Address', handleSort: () => updateSort('system__address') },
    { title: 'Current Milestone', handleSort: () => updateSort('current_milestone') },
    { title: 'Site ID', handleSort: () => updateSort('system__site_id') },
    { title: 'Org Name', handleSort: () => updateSort('organization__name') },
    { title: 'WC Status' },
    { title: 'Auth' },
    { title: 'Lang' },
  ];

  const rows = agreements.map((agreement) => {
    const {
      account_users_summaries: accountUsersSummaries,
      current_milestone: currentMilestone,
      system,
    } = agreement;
    const { address } = system;
    const agreementAddress = address
      ? `${address?.street_1}${address?.street_2 ? ` ${address.street_2}` : ''} ${address?.city}, ${address?.state} ${address?.zip_code}`
      : 'N/A';

    const checklistResult = welcomeChecklistResult(agreement);

    return {
      key: `table-${agreement.id}`,
      cells: [
        <TableCellButton
          onClick={() => handleClickHomeownerContractId(agreement.homeowner_contract_id)}
          text={agreement.homeowner_contract_id}
        />,

        <TableCellButton
          onClick={() => handleClickWorkflowId(agreement.platform_uuid)}
          text={`${agreement.platform_uuid}${agreement.archived ? ' [ARCHIVED]' : ''}`}
        />,

        <>
          {accountUsersSummaries.map((user) => (
            <TableCellUser
              key={user.id}
              onClick={() => handleClick(user.id)}
              name={user.name}
              email={user.email}
              claimed={user.claimed}
            />
          ))}
        </>,
        <TableCellString text={agreementAddress} />,
        <TableCellString text={currentMilestone || 'N/A'} />,
        <TableCellString text={system.site_id || 'N/A'} />,
        <TableCellString text={agreement.organization?.name || 'N/A'} />,
        <TableCellString
          text={checklistResult || 'N/A'}
          highlightRed={checklistResult === 'FAIL'}
        />,
        <TableCellString
          text={agreement.authoritative_copy_acknowledged ? 'Yes' : 'No'}
          highlightRed={!agreement.authoritative_copy_acknowledged}
        />,
        <TableCellString text={agreement.primary_language === 'SPANISH' ? 'ES' : 'Eng'} />,
      ],
    };
  });

  return <Table columns={columns} rows={rows} />;
}

export default AgreementsTable;
