import styled from 'styled-components';

export const SupportWrapper = styled.div`
  h1 {
    font-weight: 700;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 635px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: row;
    max-width: unset;
    gap: 48px;

    div {
      max-width: 635px;
    }
  }
`;

export const OmnidianFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 24px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin-bottom: 28px;
    order: 2;
  }
`;
