/* eslint-disable class-methods-use-this */
import { ChartTheme } from 'modules/system/components/Charts/DayChart/AreaChart.types';
import { TThemeColors } from 'shared/design-system/theme/colors/TThemeColors';
import { YAxisLabel } from '../AnyChartDataProvider';
import { ChartDataProvider } from '../ChartDataProvider';

export class BatteryChartDataProvider extends ChartDataProvider {
  getTheme: (color: TThemeColors) => ChartTheme = (color: TThemeColors) => ({
    fill: {
      lightTheme: {
        start: '#55B22D',
        middle: '#86E0AA',
        end: '#55B22D',
      },
      darkTheme: {
        start: '#86E0AA',
        middle: '#55B22D',
        end: '#86E0AA',
      },
    },
    line: color.chartChartGreenFunction540,
    tooltip: color.chartChartGreenFunction540,
    yAxisTitle: color.chartChartGreenFunction540,
  });

  cornerRadius = {
    top: 2,
    bottom: 2,
  };

  yAxisLabels(t: (key: string) => string): { top: YAxisLabel; bottom: YAxisLabel } {
    return {
      top: {
        title: t('system.labels.yaxis.battery.top.title'),
        subtitle: t('system.labels.yaxis.battery.top.subtitle'),
      },
      bottom: {
        title: t('system.labels.yaxis.battery.bottom.title'),
        subtitle: t('system.labels.yaxis.battery.bottom.subtitle'),
      },
    };
  }
}

export default BatteryChartDataProvider;
