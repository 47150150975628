import styled from 'styled-components';
import { breakpoints } from 'shared/design-system/theme/breakpoints';

export const footerHeight = {
  [breakpoints.small]: '76px',
  [breakpoints.medium]: '84px',
  [breakpoints.large]: '96px',
  [breakpoints.xLarge]: '96px',
};
export const FooterStyled = styled.header<{ $scrolledToBottom: boolean }>`
  ${({ theme, $scrolledToBottom }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 0 0 24px 24px;
  padding: 16px;
  background: ${theme.colors.cardElevationMidWhite};
  height: ${footerHeight[theme.breakpoints.small]};
  button {
    font-weight: 800;
  }
  box-shadow: ${!$scrolledToBottom ? theme.elevations.midInverse : ''};
  z-index: 10;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding: 16px 24px 24px 24px;
    height: ${footerHeight[theme.breakpoints.medium]};

  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: 20px 40px 32px 40px;
    height: ${footerHeight[theme.breakpoints.large]};

  }
`}
`;
