/* eslint-disable class-methods-use-this */
import { differenceInCalendarDays } from 'date-fns';

import { EnergyAllocationType, MonitoringDatum } from 'api/types';
import { DateRange } from 'api/system/utils/DateRange';
import { AggregatedData } from 'api/system/types';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { SolarChartDataProvider } from './SolarChartDataProvider';
import { WeekChartDataProvider } from '../WeekChartDataProvider';

export class SolarWeekChartDataProvider
  extends SolarChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 16;

  barWidth = 20;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const dailyProduction = new Map<number, AggregatedData<T>>();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      if (value === undefined) {
        return;
      }
      largestProductionValue = Math.max(largestProductionValue, value);
      if (largestProductionValue === value) {
        largestProductionUnit = unit;
      }
      const dateNumber = differenceInCalendarDays(time, dateRange.startDate);
      dailyProduction.set(dateNumber, { time, y: value, unit });
    });
    return [dailyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, WeekChartDataProvider.numberOfTicks - 1],
    y: [0, maximumValue || SolarWeekChartDataProvider.placeholderMaximumValue],
  });

  tickXFormat = WeekChartDataProvider.tickXFormat;

  getTickValuesXAxis = WeekChartDataProvider.getTickValuesXAxis;

  getXAxis = (range: DateRange): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (hours: number) => this.tickXFormat(hours, range),
  });

  yTickLabelOffset = WeekChartDataProvider.yTickLabelOffset;

  getTickValuesYAxis = (maximumValue: number) =>
    SolarChartDataProvider.solarGetTickValuesYAxis(maximumValue);

  domainPaddingX: PaddingType = WeekChartDataProvider.domainPaddingX;

  getYAxis = <EnergyUnit>(maximumValue: number, unit: EnergyUnit | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = WeekChartDataProvider.tooltipDateFormatter;

  total = (t: (key: string) => string, allocation: EnergyAllocationType) => ({
    label: t('system.summary.categories.SOLAR.total_production'),
    value: allocation.production_total_wh,
  });
}

export default SolarWeekChartDataProvider;
