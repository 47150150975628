import { DateRange } from './DateRange';
import { getAggregationLevel } from './getAggregationLevel';
import { MonitoringDataParams } from '../types';

export const getRequestParams = (
  dateRange: DateRange | undefined | null,
  includeEnergyAllocation: boolean = false,
): MonitoringDataParams | undefined => {
  if (!dateRange) {
    return undefined;
  }
  const baseParams = {
    start_time: dateRange.startDate.toISOString(),
    end_time: dateRange.endDate.toISOString(),
    aggregation_level: getAggregationLevel(dateRange.zoomLevel),
  };
  const params = includeEnergyAllocation
    ? {
        ...baseParams,
        include_energy_allocation: 'true',
      }
    : baseParams;

  return params;
};

export default getRequestParams;
