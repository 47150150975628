import styled from 'styled-components';

export const SystemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 1124px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 24px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    gap: 32px;
    margin-bottom: 32px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 48px;
    margin-bottom: 40px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.large}) {
    gap: 48px;
    margin-bottom: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cardElevationLowWhite};
  align-items: center;
  @media (width >= ${({ theme }) => theme.breakpoints.small}) {
    align-items: flex-start;
  }
`;

export const EnergySectionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  @media (width >= ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 14px;
  }
  @media (width >= ${({ theme }) => theme.breakpoints.medium}) {
    margin-top: 34px;
    margin-left: 12px;
  }
  @media (width >= ${({ theme }) => theme.breakpoints.large}) {
    margin-left: 0;
  }
`;

export default Container;
