import styled, { css, DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Text } from '../../../../shared/components/Text/Text';

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.cardElevationLowWhite};
    align-items: flex-start;
    flex-shrink: 0;
    @media screen and (min-width: ${theme.breakpoints.small}) {
      align-items: flex-start;
      }
    }
  `}
`;

export const VppDetailsBody = styled.div`
  ${({ theme }) => `
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    max-width: 555px;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    max-width: 636px;
  }
  }
 `}
`;

export const VppDetailsBodyText = styled(Text)`
  ${({ theme }) => css`
    display: flex;
    gap: 4px;
    color: ${theme.colors.textSecondaryGrey90};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
  `}
`;

export const VppDetailsSubHeaderText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.textSecondaryGrey90};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  `}
`;

export const VppDetailsHighlightLinkText = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.textLinkLinkSecondary2100};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  `}
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;
export const VppDetailsHighlightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
  svg {
    margin-top: 8px;
    flex-shrink: 0;
  }
`;
const highlightsTextColors = (theme: DefaultTheme) => ({
  green: theme.colors.chartChartGreenPressedFunction5100,
  teal: theme.colors.chartChartTealHoverAccent2120,
  blue: theme.colors.chartChartDuskHoverSecondary1100,
});

export const VppDetailsText = styled(Text)<{ $color: 'green' | 'teal' | 'blue' }>`
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export const VppDetailsHighlightsNumberText = styled(Text)<{ $color: 'green' | 'teal' | 'blue' }>`
  ${({ theme, $color }) => {
    const colors = highlightsTextColors(theme);
    return css`
      display: inline;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: ${colors[$color]};
    `;
  }}
`;

export const VppDetailsHighlightsText = styled(VppDetailsHighlightsNumberText)`
  font-size: 20px;
  line-height: 28px;
`;
