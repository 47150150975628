import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

import { breakpoints } from 'shared/design-system/theme/breakpoints';
import { ModalBodyType } from '../types';

export const headerHeight = {
  [ModalBodyType.FEED]: {
    [breakpoints.small]: '73px',
    [breakpoints.medium]: '90px',
    [breakpoints.large]: '108px',
    [breakpoints.xLarge]: '108px',
  },
  [ModalBodyType.DETAILS]: {
    [breakpoints.small]: '50px',
    [breakpoints.medium]: '58px',
    [breakpoints.large]: '82px',
    [breakpoints.xLarge]: '82px',
  },
};
export const HeaderStyled = styled.header<{ $modalType: ModalBodyType; $feedScrolling: boolean }>`
  ${({ theme, $modalType, $feedScrolling }) => `
  position: relative;
  display: flex;
  flex-direction: ${$modalType === ModalBodyType.DETAILS ? 'row' : 'column'};
  justify-content: space-between;
  align-items: ${$modalType === ModalBodyType.DETAILS ? 'normal' : 'flex-end'};
  width: 100%;
  border-radius: 24px 24px 0 0;
  padding: ${$modalType === ModalBodyType.DETAILS ? '4px 8px 0px 12px' : '16px 8px 8px 16px'};
  background: ${theme.colors.cardElevationMidWhite};
  height: ${headerHeight[$modalType][theme.breakpoints.small]};
  button {
    font-weight: 400;
  }
  z-index: 10;
  box-shadow: ${$feedScrolling ? theme.elevations.mid : ''};
  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding: ${$modalType === ModalBodyType.DETAILS ? '13px 16px 0px 20px' : '20px 16px 12px 24px'};
    height: ${headerHeight[$modalType][theme.breakpoints.medium]};

  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: ${$modalType === ModalBodyType.DETAILS ? '29px 32px 0px 36px' : '38px 32px 16px 40px'};
    height: ${headerHeight[$modalType][theme.breakpoints.large]};

  }
`}
`;

export const HeaderText = styled(Text)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;

  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;
