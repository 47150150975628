/* eslint-disable class-methods-use-this */
import { differenceInCalendarMonths } from 'date-fns';

import { MonitoringDatum } from 'api/types';
import { DateRange } from 'api/system/utils/DateRange';
import { AggregatedData } from 'api/system/types';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { Sizes } from 'shared/design-system/theme/grid';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { GridChartDataProvider } from './GridChartDataProvider';
import { YearChartDataProvider } from '../YearChartDataProvider';
import { ChartDataProvider } from '../ChartDataProvider';

export class GridYearChartDataProvider
  extends GridChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 300;

  static numberOfTicks = 12;

  barWidth = 10;

  shouldShowDateLabel = YearChartDataProvider.shouldShowDateLabel;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const monthlyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach((props) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { export_kwh, import_kwh, time, unit } = props;
      if (!export_kwh || !import_kwh) {
        return;
      }
      const newValue = Math.max(Math.abs(export_kwh), Math.abs(import_kwh));
      largestProductionValue = Math.max(largestProductionValue, newValue);
      if (largestProductionValue === newValue) {
        largestProductionUnit = unit;
      }
      const monthNumber = differenceInCalendarMonths(time, dateRange.startDate);
      monthlyProduction.set(monthNumber, {
        time,
        y0: import_kwh > 0.0 ? Math.abs(import_kwh) : 0.0,
        y: export_kwh > 0.0 ? -1 * export_kwh : 0.0,
        y0_raw: import_kwh,
        y_raw: export_kwh,
        unit,
      });
    });

    const percentOfLargestProductionValue = 0.01;

    monthlyProduction.forEach((_: any, key: number) => {
      const production = monthlyProduction.get(key);
      if (!production) {
        return;
      }
      if (production.y0 === 0.0) {
        production.y0 = percentOfLargestProductionValue * largestProductionValue;
      }
      if (production.y === 0.0) {
        production.y = -1 * percentOfLargestProductionValue * largestProductionValue;
      }
    });

    return [monthlyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, YearChartDataProvider.numberOfTicks - 1],
    y: [
      -1 * (maximumValue || GridYearChartDataProvider.placeholderMaximumValue),
      maximumValue || GridYearChartDataProvider.placeholderMaximumValue,
    ],
  });

  tickXFormat = YearChartDataProvider.tickXFormat;

  domainPaddingX: PaddingType = YearChartDataProvider.domainXPadding;

  yTickLabelOffset = YearChartDataProvider.yTickLabelOffset;

  getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(YearChartDataProvider.numberOfTicks).keys());

  getXAxis = (range: DateRange, screenSize: Sizes): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (month: number) => this.tickXFormat(month, range, screenSize),
  });

  getTickValuesYAxis = ChartDataProvider.getTickValuesYAxis(5);

  getYAxis = <T>(maximumValue: number | null, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(Math.abs(value), unit),
  });

  tooltipDateFormatter = YearChartDataProvider.tooltipDateFormatter;

  getFlyoutLabels(t: (key: string) => string): { y: string; y0: string } {
    return {
      y0: t('system.labels.flyout.grid.imported'),
      y: t('system.labels.flyout.grid.exported'),
    };
  }

  tooltipIndicatorHidden?: boolean | undefined = true;
}

export default GridYearChartDataProvider;
