import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function ElecticPoleIcon({ color: colorProp, filled }: IconProps & { filled: boolean }) {
  const theme = useTheme();
  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return filled ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2555_25923)">
        <path
          d="M8 0C8.55313 0 9 0.446875 9 1V2H12V1.75C12 1.33438 12.3344 1 12.75 1C13.1656 1 13.5 1.33438 13.5 1.75V2H14.5V1.75C14.5 1.33438 14.8344 1 15.25 1C15.6656 1 16 1.33438 16 1.75V3C16 3.55312 15.5531 4 15 4H13.4L9 6.93437V9V15C9 15.5531 8.55313 16 8 16C7.44688 16 7 15.5531 7 15V9V6.93437L2.6 4H1C0.446875 4 0 3.55312 0 3V1.75C0 1.33438 0.334375 1 0.75 1C1.16562 1 1.5 1.33438 1.5 1.75V2H2.5V1.75C2.5 1.33438 2.83437 1 3.25 1C3.66563 1 4 1.33438 4 1.75V2H7V1C7 0.446875 7.44688 0 8 0ZM4.4 4L7 5.73125V4H4.4ZM9 5.73125L11.6 4H9V5.73125Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2555_25923">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2924_6240)">
        <path
          d="M8.75 0.75C8.75 0.334375 8.41562 0 8 0C7.58437 0 7.25 0.334375 7.25 0.75V2.5H4V1.75C4 1.33438 3.66563 1 3.25 1C2.83437 1 2.5 1.33438 2.5 1.75V2.5H2.00625C2 2.5 1.99688 2.5 1.99063 2.5H1.5V1.75C1.5 1.33438 1.16562 1 0.75 1C0.334375 1 0 1.33438 0 1.75V2.75C0 3.44062 0.559375 4 1.25 4H2.6L7.25 7.1V9.25V15.25C7.25 15.6656 7.58437 16 8 16C8.41562 16 8.75 15.6656 8.75 15.25V9.25V7.1L13.4 4H14.75C15.4406 4 16 3.44062 16 2.75V1.75C16 1.33438 15.6656 1 15.25 1C14.8344 1 14.5 1.33438 14.5 1.75V2.5H14.0063C14 2.5 13.9969 2.5 13.9906 2.5H13.5V1.75C13.5 1.33438 13.1656 1 12.75 1C12.3344 1 12 1.33438 12 1.75V2.5H8.75V0.75ZM11.6 4L8.75 5.9V4H11.6ZM7.25 5.9L4.4 4H7.25V5.9Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2924_6240">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ElecticPoleIcon;
