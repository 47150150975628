import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

export const ErrorBodyWrapper = styled.div`
  ${({ theme }) => `
  flex-direction: column;
  align-items: center;
  gap: 16px;
  display: flex;
  justify-content: center;
  margin: 32px 16px 16px 16px;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    margin: 76px 24px 24px 24px;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    margin: 80px 40px 40px 40px;
  }
`}
`;
export const HeaderText = styled(Text)`
  ${({ theme }) => `
  color: ${theme.colors.textPrimarySecondary1100};
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: center;
  margin-top: 8px;
`}
`;

export const Body = styled(Text)`
  ${({ theme }) => `
  color: ${theme.colors.textSecondaryGrey90};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-botton: 8px;
  `}
`;
