import styled from 'styled-components';

export const TpvBaseContainer = styled.div`
  width: 416px;
`;

export const TpvModalContent = styled.div`
  ${({ theme }) => `
        display: flex-column;
        align-items: center;
        width: fit-content;
        height: fit-content;

        svg {
            display: block;
            margin: 20px auto 26px;
        }

        h2 {
            font-weight: 500;
            font-size: 24px;
            margin-bottom: 20px;
        }

        p {
            color: ${theme.colors.textSecondaryGrey90};
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
            strong {
                font-weight: 700;
            }
        }
        
        a {
            text-decoration: none;
            font-size: 16px;
            color: ${theme.colors.textLinkLinkSecondary2100};
            font-weight: 700;
        }
        
        @media screen and (max-width: ${theme.breakpoints.small}) {
            width: 100%;
        }
    `}
`;

export const TpvModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 16px;
  margin-top: 36px;
`;
