import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { Text } from '../Text/Text';

export const HeaderStyled = styled.header<{ $hasBreadCrumbs: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors.cardElevationLowWhite};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${({ $hasBreadCrumbs }) => {
      if ($hasBreadCrumbs) {
        return css`
          > :first-child {
            align-self: flex-start;
          }
        `;
      }
      return '';
    }}
  }
`;

export const AlertsAccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;
`;

export const TitleWrapper = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    order: -1;
  }
`;

export const Title = styled(Text)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
`;

export const TitleBold = styled(Title)`
  font-family: Avenir;
  font-weight: 800;
`;

export const TitleButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const AbsoluteRight = styled.div`
  position: absolute;
  right: 0;
`;

export const BreadCrumbs = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 18px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
  }
`;

export const LinkStyled = styled(Link)`
  font-weight: 400;
  line-height: 24px;
  text-decoration: unset;
  color: ${({ theme }) => theme.colors.buttonButtonPrimarySecondary2100};
`;

export const LogoutText = styled(Text)`
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px !important;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;
