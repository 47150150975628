import React from 'react';

import useUser from 'hooks/useUser';

import getMonitoringSystemInfo from 'modules/system/utils/getMonitoringSystemInfo';

import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';
import Header from 'shared/components/Header/Header';

import { Trans, useTranslation } from 'react-i18next';
import { resolvePath } from 'react-router';
import pages from 'pages';
import { useGetVppDetails } from 'api/system';
import EnvelopeIcon from 'shared/components/icons/EnvelopeIcon';
import SparklesIcon from 'shared/components/icons/SparklesIcon';
import HouseIcon from 'shared/components/icons/HouseIcon';
import TooltipText from 'shared/components/TooltipText/TooltipText';
import { LoadingSpinner } from 'shared/components/LoadingSpinner';
import {
  Container,
  Section,
  VppDetailsBody,
  VppDetailsBodyText,
  VppDetailsHighlightLinkText,
  VppDetailsHighlightsContainer,
  VppDetailsHighlightsText,
  VppDetailsSubHeaderText,
  VppDetailsHighlightsNumberText,
  VppDetailsText,
} from './VppDetails.styles';

export function VppDetails() {
  const { selectedAgreement } = useUser();
  const { t } = useTranslation();

  // get system with monitoring enabled
  const { system } = getMonitoringSystemInfo(selectedAgreement);

  const {
    data: vppDetails,
    isFetching,
    isError,
  } = useGetVppDetails({ systemId: system ? system.id : '' });
  if (isFetching) {
    return <LoadingSpinner isLoading />;
  }
  if (isError || !vppDetails?.vpp_participant) {
    return <ErrorFallback is404 />;
  }
  return (
    <Container>
      <Header
        pageName="VPP_DETAILS"
        title={t('vppDetails.yourRewards')}
        routeBreadCrumbs={[
          {
            label: 'System',
            link: resolvePath(`/${pages.DASHBOARD}/${pages.SYSTEM}`),
          },
          { label: t('vppDetails.vpp') },
        ]}
      />

      <VppDetailsBody>
        <Section>
          <VppDetailsBodyText as="p">{t('vppDetails.2024Participation')}</VppDetailsBodyText>
        </Section>
        <Section>
          <VppDetailsSubHeaderText as="p">{t('vppDetails.earnings')}</VppDetailsSubHeaderText>
          <VppDetailsHighlightsContainer>
            <EnvelopeIcon />
            <VppDetailsHighlightsNumberText $color="green" as="p">
              <Trans
                i18nKey="vppDetails.earningsAmount"
                values={{ earnings: vppDetails.total_revenue }}
                components={{
                  // TODO: Add link to actual details page
                  a: (
                    <VppDetailsHighlightLinkText
                      to={`/${pages.DASHBOARD}/${pages.SYSTEM}/${pages.VPP_DETAILS}`}
                    />
                  ),
                }}
              />
            </VppDetailsHighlightsNumberText>
          </VppDetailsHighlightsContainer>
        </Section>
        <Section>
          <VppDetailsSubHeaderText as="p">
            {t('vppDetails.collectiveImpact')}
          </VppDetailsSubHeaderText>
          <VppDetailsBodyText as="p">{t('vppDetails.together')}</VppDetailsBodyText>
          <VppDetailsHighlightsContainer>
            <SparklesIcon />

            <VppDetailsText as="p" $color="teal">
              <VppDetailsHighlightsNumberText as="h2" $color="teal">
                {t('vppDetails.393')}{' '}
              </VppDetailsHighlightsNumberText>
              <VppDetailsHighlightsText as="h3" $color="teal">
                {t('vppDetails.mwh')}
              </VppDetailsHighlightsText>
              {t('vppDetails.cleanEnergy')}
            </VppDetailsText>
          </VppDetailsHighlightsContainer>

          <VppDetailsHighlightsContainer>
            <HouseIcon />

            <VppDetailsText as="p" $color="blue">
              <VppDetailsHighlightsNumberText as="h2" $color="blue">
                {t('vppDetails.445')}
              </VppDetailsHighlightsNumberText>
              <VppDetailsHighlightsText as="h3" $color="blue">
                {t('vppDetails.home')}
              </VppDetailsHighlightsText>
              {t('vppDetails.worthOfPower')}{' '}
              <TooltipText
                id="houseTooltip"
                content={t('vppDetails.tooltip')}
                place="bottom"
                $margin="0 0 -2px 0"
              />
            </VppDetailsText>
          </VppDetailsHighlightsContainer>
        </Section>
        <Section>
          <VppDetailsBodyText as="p">{t('vppDetails.thankYou')}</VppDetailsBodyText>
        </Section>
        <Section>
          <VppDetailsBodyText as="p">{t('vppDetails.lookForward')}</VppDetailsBodyText>
        </Section>
      </VppDetailsBody>
    </Container>
  );
}

export default VppDetails;
