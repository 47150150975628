import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

export const FeedBodyWrapper = styled.div`
  ${({ theme }) => `
  flex-direction: column;
  align-items: center;
  display: flex;
  overflow: auto;
  padding: 0 16px;
  max-height: 100%;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding: 0 24px;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: 0 40px;
  }
`}
`;

export const NoAlertsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 65vh;
  overflow: unset;
`;

export const NoAlertsHeader = styled(Text)`
  ${({ theme }) => `
  color: ${theme.colors.textPrimarySecondary1100};
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  text-align: center;
  `}
`;

export const NoAlertsDescription = styled(Text)`
  ${({ theme }) => `
  color: ${theme.colors.textSecondaryGrey90};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
  text-align: center;
  `}
`;

export const LoadingContainer = styled.div<{
  $firstLoad: boolean;
}>`
  ${({ $firstLoad }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  height: ${$firstLoad ? '75vh' : '10%'};
  width: 100%;
  `}
`;
