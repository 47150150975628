/* eslint-disable class-methods-use-this */
import { differenceInCalendarMonths } from 'date-fns';

import { EnergyAllocationType, MonitoringDatum } from 'api/types';
import { DateRange } from 'api/system/utils/DateRange';
import { AggregatedData } from 'api/system/types';
import { AxisType, PaddingType, Tuple } from 'modules/types';
import { Sizes } from 'shared/design-system/theme/grid';
import { AnyChartDataProvider } from '../AnyChartDataProvider';
import { HomeChartDataProvider } from './HomeChartDataProvider';
import { YearChartDataProvider } from '../YearChartDataProvider';

export class HomeYearChartDataProvider
  extends HomeChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 300;

  static numberOfTicks = 12;

  barWidth = 10;

  shouldShowDateLabel = YearChartDataProvider.shouldShowDateLabel;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const monthlyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData
      // data sanitization: backend has yet to filter out days with negative values
      .filter(({ value }) => value && value > 0.0)
      .forEach(({ value, time, unit }) => {
        if (!value) {
          return;
        }
        largestProductionValue = Math.max(largestProductionValue, value);
        if (largestProductionValue === value) {
          largestProductionUnit = unit;
        }
        const monthNumber = differenceInCalendarMonths(time, dateRange.startDate);
        monthlyProduction.set(monthNumber, { time, y: value, unit });
      });
    return [monthlyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, YearChartDataProvider.numberOfTicks - 1],
    y: [0, maximumValue || HomeYearChartDataProvider.placeholderMaximumValue],
  });

  tickXFormat = YearChartDataProvider.tickXFormat;

  domainPaddingX: PaddingType = YearChartDataProvider.domainXPadding;

  yTickLabelOffset = YearChartDataProvider.yTickLabelOffset;

  getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(HomeYearChartDataProvider.numberOfTicks).keys());

  getXAxis = (range: DateRange, screenSize: Sizes): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (month: number) => this.tickXFormat(month, range, screenSize),
  });

  getTickValuesYAxis = HomeChartDataProvider.homeGetTickValuesYAxis;

  getYAxis = <T>(maximumValue: number | null, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = YearChartDataProvider.tooltipDateFormatter;

  total = (t: (key: string) => string, allocation: EnergyAllocationType) => ({
    label: t('system.summary.categories.HOME.total_consumption'),
    value: allocation.consumption_total_wh,
  });
}

export default HomeYearChartDataProvider;
