import {
  SupportedDeeplinks,
  SupportedPostPtoDeeplinks,
  SupportedPrePtoDeeplinks,
  deeplinks,
} from 'api/inboxMessage/types';

type SetBodyScrollingValuesProps = {
  isFetchingNextPage: boolean;
  isFetching: boolean;
  updateScrolling: (isScrolling: boolean) => void;
  updateScrolledToBottom: (scrolledToBottom: boolean) => void;
  bodyRef: React.RefObject<HTMLDivElement>;
};
export function setBodyScrollingValues({
  isFetching,
  isFetchingNextPage,
  updateScrolledToBottom,
  updateScrolling,
  bodyRef,
}: SetBodyScrollingValuesProps) {
  const setScrollingValues = (element: HTMLDivElement) => {
    const { scrollTop, scrollHeight, clientHeight } = element;
    if (!isFetchingNextPage && !isFetching) {
      updateScrolledToBottom(scrollTop + clientHeight >= scrollHeight);
    } else if (isFetchingNextPage) {
      updateScrolledToBottom(false);
    }
    updateScrolling(scrollTop > 0);
  };
  const handleScroll = () => {
    if (bodyRef.current) {
      setScrollingValues(bodyRef.current);
    }
  };

  const feedBodyElement = bodyRef.current;
  if (feedBodyElement) {
    feedBodyElement.addEventListener('scroll', handleScroll);

    setScrollingValues(feedBodyElement);
  }

  return () => {
    if (feedBodyElement) {
      feedBodyElement.removeEventListener('scroll', handleScroll);
    }
  };
}

export function getSupportedDeeplinkPath(
  value: SupportedDeeplinks | string | null | undefined,
  postPto: boolean,
): string | null {
  if (value === null || value === undefined) {
    return null;
  }
  if (postPto) {
    return value in deeplinks.postPto
      ? deeplinks.postPto[value as SupportedPostPtoDeeplinks]
      : null;
  }
  return value in deeplinks.prePto ? deeplinks.prePto[value as SupportedPrePtoDeeplinks] : null;
}
