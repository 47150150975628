import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

type Props = IIconProps & {
  filled?: boolean;
  hasUnreadAlerts?: boolean;
};

export function AlertIcon({ color: colorProp, filled = false, hasUnreadAlerts = false }: Props) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;
  const dotColor = theme.colors.chartChartMagentaAccent1100;
  if (filled) {
    return hasUnreadAlerts ? (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99952 1C6.99952 0.446875 7.4464 0 7.99952 0C8.55265 0 8.99952 0.446875 8.99952 1V1.09714C8.15599 1.73619 7.61108 2.7488 7.61108 3.88867C7.61108 5.82167 9.17809 7.38867 11.1111 7.38867C11.8066 7.38867 12.4547 7.1858 12.9995 6.83601V7.0875C12.9995 8.55937 13.5401 9.975 14.5151 11.075L14.7464 11.3344C15.0089 11.6281 15.0745 12.05 14.912 12.4094C14.7495 12.7688 14.3933 13 13.9995 13H1.99952C1.60577 13 1.2464 12.7688 1.08702 12.4094C0.927649 12.05 0.990149 11.6281 1.25265 11.3344L1.4839 11.075C2.4589 9.975 2.99952 8.55625 2.99952 7.0875V6.5C2.99952 4.08125 4.71827 2.0625 6.99952 1.6V1ZM9.13675 1.62985C11.2635 2.12407 12.8706 3.97804 12.9921 6.22583C12.4776 6.64046 11.8233 6.88867 11.1111 6.88867C9.45423 6.88867 8.11108 5.54553 8.11108 3.88867C8.11108 2.98785 8.50812 2.17976 9.13675 1.62985ZM9.99952 14C9.99952 14.5312 9.79015 15.0406 9.41515 15.4156C9.04015 15.7906 8.53077 16 7.99952 16C7.46827 16 6.9589 15.7906 6.5839 15.4156C6.2089 15.0406 5.99952 14.5312 5.99952 14H7.99952H9.99952Z"
          fill={color}
        />
        <path
          d="M14.0001 4C14.0001 5.65685 12.657 7 11.0001 7C9.34327 7 8.00012 5.65685 8.00012 4C8.00012 2.34315 9.34327 1 11.0001 1C12.657 1 14.0001 2.34315 14.0001 4Z"
          fill={dotColor}
        />
        <path
          d="M14.0001 4C14.0001 5.65685 12.657 7 11.0001 7C9.34327 7 8.00012 5.65685 8.00012 4C8.00012 2.34315 9.34327 1 11.0001 1C12.657 1 14.0001 2.34315 14.0001 4Z"
          fill={dotColor}
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.99952 0C7.4464 0 6.99952 0.446875 6.99952 1V1.6C4.71827 2.0625 2.99952 4.08125 2.99952 6.5V7.0875C2.99952 8.55625 2.4589 9.975 1.4839 11.075L1.25265 11.3344C0.990149 11.6281 0.927649 12.05 1.08702 12.4094C1.2464 12.7688 1.60577 13 1.99952 13H13.9995C14.3933 13 14.7495 12.7688 14.912 12.4094C15.0745 12.05 15.0089 11.6281 14.7464 11.3344L14.5151 11.075C13.5401 9.975 12.9995 8.55937 12.9995 7.0875V6.5C12.9995 4.08125 11.2808 2.0625 8.99952 1.6V1C8.99952 0.446875 8.55265 0 7.99952 0ZM9.41515 15.4156C9.79015 15.0406 9.99952 14.5312 9.99952 14H7.99952H5.99952C5.99952 14.5312 6.2089 15.0406 6.5839 15.4156C6.9589 15.7906 7.46827 16 7.99952 16C8.53077 16 9.04015 15.7906 9.41515 15.4156Z"
          fill={color}
        />
      </svg>
    );
  }
  return hasUnreadAlerts ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97187 0C7.725 0 7.47187 0.223875 7.47187 0.5L7.5 1.02469C4.97187 1.27562 3 3.40625 3 6V6.90938C3 8.275 2.45719 9.58438 1.49125 10.5531L1.40531 10.6375C1.14581 10.8969 1 11.2219 1 11.6156C1 12.3813 1.61969 13 2.38375 13H13.6156C14.3812 13 15 12.3813 15 11.6156C15 11.2219 14.8531 10.8969 14.5937 10.6375L14.5094 10.5531C13.7357 9.77698 13.2329 8.78219 13.0635 7.715C12.7594 7.84505 12.4335 7.93393 12.0928 7.97462C12.3099 9.21068 12.9017 10.3604 13.8031 11.2594L13.8875 11.3438C13.9594 11.4156 14 11.5156 14 11.6156C14 11.8281 13.8281 12 13.6156 12H2.38375C2.17187 12 1.97187 11.8281 1.97187 11.6156C1.97187 11.5156 2.04031 11.4156 2.1125 11.3438L2.19844 11.2594C3.35187 10.1062 3.97187 8.54063 3.97187 6.90938V6C3.97187 3.79063 5.79062 2 7.97187 2C8.31793 2 8.6533 2.04283 8.97301 2.12342C9.23175 1.8234 9.53976 1.56707 9.88448 1.367C9.45016 1.19031 8.98533 1.07286 8.5 1.02469V0.5C8.5 0.223875 8.275 0 7.97187 0ZM10.9782 1.9826C10.6025 2.08745 10.2617 2.27598 9.97722 2.52684C11.1893 3.21634 12 4.51337 12 6V6.90938C12 6.93236 12.0001 6.95534 12.0004 6.9783C12.363 6.93101 12.7017 6.80771 13 6.62492V6C13 4.35343 12.2053 2.89349 10.9782 1.9826ZM8.94375 14.3344C8.80625 14.7219 8.43437 15 7.97187 15C7.56562 15 7.19375 14.7219 7.05625 14.3344C6.96562 14.0719 6.67812 13.9094 6.41875 14.0281C6.15937 14.1219 6.02187 14.4062 6.1125 14.6656C6.3875 15.4437 7.12812 16 7.97187 16C8.87187 16 9.6125 15.4437 9.8875 14.6656C9.97812 14.4062 9.84062 14.1219 9.58125 14.0281C9.32187 13.9094 9.03437 14.0719 8.94375 14.3344Z"
        fill={color}
      />
      <path
        d="M15.2222 3.99978C15.2222 5.718 13.8293 7.11089 12.1111 7.11089C10.3929 7.11089 9 5.718 9 3.99978C9 2.28156 10.3929 0.888672 12.1111 0.888672C13.8293 0.888672 15.2222 2.28156 15.2222 3.99978Z"
        fill={dotColor}
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.47188 0.5C7.47188 0.223875 7.725 0 7.97188 0C8.275 0 8.5 0.223875 8.5 0.5V1.02469C11.0281 1.27562 13 3.40625 13 6V6.90938C13 8.275 13.5437 9.58438 14.5094 10.5531L14.5938 10.6375C14.8531 10.8969 15 11.2219 15 11.6156C15 12.3813 14.3813 13 13.6156 13H2.38375C1.61969 13 1 12.3813 1 11.6156C1 11.2219 1.14581 10.8969 1.40531 10.6375L1.49125 10.5531C2.45719 9.58438 3 8.275 3 6.90938V6C3 3.40625 4.97187 1.27562 7.5 1.02469L7.47188 0.5ZM7.97188 2C5.79063 2 3.97187 3.79063 3.97187 6V6.90938C3.97187 8.54063 3.35187 10.1062 2.19844 11.2594L2.1125 11.3438C2.04031 11.4156 1.97188 11.5156 1.97188 11.6156C1.97188 11.8281 2.17188 12 2.38375 12H13.6156C13.8281 12 14 11.8281 14 11.6156C14 11.5156 13.9594 11.4156 13.8875 11.3438L13.8031 11.2594C12.6469 10.1062 12 8.54063 12 6.90938V6C12 3.79063 10.2094 2 7.97188 2ZM7.97188 15C8.43438 15 8.80625 14.7219 8.94375 14.3344C9.03438 14.0719 9.32188 13.9094 9.58125 14.0281C9.84062 14.1219 9.97812 14.4062 9.8875 14.6656C9.6125 15.4437 8.87188 16 7.97188 16C7.12813 16 6.3875 15.4437 6.1125 14.6656C6.02188 14.4062 6.15937 14.1219 6.41875 14.0281C6.67812 13.9094 6.96563 14.0719 7.05625 14.3344C7.19375 14.7219 7.56563 15 7.97188 15Z"
        fill={color}
      />
    </svg>
  );
}

export default AlertIcon;
