import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

export default IconContainer;
