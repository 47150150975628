/* eslint-disable class-methods-use-this */
import { differenceInCalendarMonths } from 'date-fns';

import { AxisType, PaddingType, Tuple } from 'modules/types';
import { EnergyAllocationType, MonitoringDatum } from 'api/types';
import { DateRange } from 'api/system/utils/DateRange';
import { AggregatedData } from 'api/system/types';
import { Sizes } from 'shared/design-system/theme/grid';
import { YearChartDataProvider } from '../YearChartDataProvider';
import { SolarChartDataProvider } from './SolarChartDataProvider';
import { AnyChartDataProvider } from '../AnyChartDataProvider';

export class SolarYearChartDataProvider
  extends SolarChartDataProvider
  implements AnyChartDataProvider
{
  static placeholderMaximumValue = 300;

  static barWidth = 10;

  shouldShowDateLabel = YearChartDataProvider.shouldShowDateLabel;

  aggregateData<T>(
    monitoringData: MonitoringDatum<T>[],
    dateRange: DateRange,
  ): [Map<number, AggregatedData<T>>, number | null, T | null] {
    const monthlyProduction = new Map();
    let largestProductionValue = 0;
    let largestProductionUnit: T | null = null;

    monitoringData.forEach(({ value, time, unit }) => {
      if (value === undefined) {
        return;
      }
      largestProductionValue = Math.max(largestProductionValue, value);
      if (largestProductionValue === value) {
        largestProductionUnit = unit;
      }
      const monthNumber = differenceInCalendarMonths(time, dateRange.startDate);
      monthlyProduction.set(monthNumber, { time, y: value, unit });
    });
    return [monthlyProduction, largestProductionValue, largestProductionUnit];
  }

  getDomain = (
    _: DateRange,
    maximumValue: number | null,
  ): { x: Tuple<number>; y: Tuple<number> } => ({
    x: [0, YearChartDataProvider.numberOfTicks - 1],
    y: [0, maximumValue || SolarYearChartDataProvider.placeholderMaximumValue],
  });

  tickXFormat = YearChartDataProvider.tickXFormat;

  domainPaddingX: PaddingType = YearChartDataProvider.domainXPadding;

  yTickLabelOffset = YearChartDataProvider.yTickLabelOffset;

  getTickValuesXAxis = (_: DateRange): number[] =>
    Array.from(Array(YearChartDataProvider.numberOfTicks).keys());

  getXAxis = (range: DateRange, screenSize: Sizes): AxisType => ({
    tickValues: this.getTickValuesXAxis(range),
    tickFormat: (month: number) => this.tickXFormat(month, range, screenSize),
  });

  getTickValuesYAxis = (maximumValue: number) =>
    SolarChartDataProvider.solarGetTickValuesYAxis(maximumValue);

  getYAxis = <T>(maximumValue: number | null, unit: T | null): AxisType => ({
    tickValues: this.getTickValuesYAxis(maximumValue || 0.0),
    tickFormat: (value: number) => this.tickYFormat(value, unit),
  });

  tooltipDateFormatter = YearChartDataProvider.tooltipDateFormatter;

  total = (t: (key: string) => string, allocation: EnergyAllocationType) => ({
    label: t('system.summary.categories.SOLAR.total_production'),
    value: allocation.production_total_wh,
  });
}

export default SolarYearChartDataProvider;
