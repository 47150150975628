import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

export const DetailBodyWrapper = styled.div`
  ${({ theme }) => `
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  overflow: auto;
  max-height: 100%;
  padding: 0 16px 0px 16px;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding: 0 24px 4px 24px;
    max-height: 100%;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding: 0 40px 20px 40px;
    max-height: 100%;
  }
`}
`;
export const Subject = styled(Text)`
  ${({ theme }) => `
  padding-top: 8px;
  color: ${theme.colors.textPrimarySecondary1100};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  @media screen and (min-width: ${theme.breakpoints.small}) {
    padding-top: 12px;
  }
  @media screen and (min-width: ${theme.breakpoints.medium}) {
    padding-top: 16px;
  }
`}
`;

export const MarkdownBodyWrapper = styled(Text)`
  ${({ theme }) => `
  color: ${theme.colors.textSecondaryGrey90};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  p {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  ul, ol {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0px;
    margin-bottom: 12px;

  }
  li {
    margin: 0;
  }
  ul, ol {

    display: flex;
    flex-direction: column;
  }
  `}
`;
