import { DateRangeZoomLevel, SystemType } from 'api/types';
import { SystemCategory } from 'api/system/monitoringTypes';
import { AnyChartDataProvider } from './AnyChartDataProvider';

import { BatteryDayChartDataProvider } from './battery/BatteryDayChartDataProvider';
import { BatteryMonthChartDataProvider } from './battery/BatteryMonthChartDataProvider';
import { BatteryWeekChartDataProvider } from './battery/BatteryWeekChartDataProvider';
import { BatteryYearChartDataProvider } from './battery/BatteryYearChartDataProvider';

import { SolarDayChartDataProvider } from './solar/SolarDayChartDataProvider';
import { SolarWeekChartDataProvider } from './solar/SolarWeekChartDataProvider';
import { SolarMonthChartDataProvider } from './solar/SolarMonthChartDataProvider';
import { SolarYearChartDataProvider } from './solar/SolarYearChartDataProvider';

import { GridDayChartDataProvider } from './grid/GridDayChartDataProvider';
import { GridWeekChartDataProvider } from './grid/GridWeekChartDataProvider';
import { GridMonthChartDataProvider } from './grid/GridMonthChartDataProvider';
import { GridYearChartDataProvider } from './grid/GridYearChartDataProvider';

import { HomeDayChartDataProvider } from './home/HomeDayChartDataProvider';
import { HomeMonthChartDataProvider } from './home/HomeMonthChartDataProvider';
import { HomeWeekChartDataProvider } from './home/HomeWeekChartDataProvider';
import { HomeYearChartDataProvider } from './home/HomeYearChartDataProvider';

export const getDataProvider = (
  system: SystemType,
  category: SystemCategory,
  zoomLevel: DateRangeZoomLevel,
  t: (key: string) => string,
): AnyChartDataProvider | undefined => {
  let provider: AnyChartDataProvider | undefined;

  switch (category) {
    case SystemCategory.SOLAR:
      switch (zoomLevel) {
        case DateRangeZoomLevel.DAY:
          provider = new SolarDayChartDataProvider(system);
          break;
        case DateRangeZoomLevel.WEEK:
          provider = new SolarWeekChartDataProvider(system);
          break;
        case DateRangeZoomLevel.MONTH:
          provider = new SolarMonthChartDataProvider(t);
          break;
        case DateRangeZoomLevel.YEAR:
          provider = new SolarYearChartDataProvider(system);
          break;
        default:
          break;
      }
      break;
    case SystemCategory.BATTERY:
      switch (zoomLevel) {
        case DateRangeZoomLevel.DAY:
          provider = new BatteryDayChartDataProvider(system);
          break;
        case DateRangeZoomLevel.WEEK:
          provider = new BatteryWeekChartDataProvider(system);
          break;
        case DateRangeZoomLevel.MONTH:
          provider = new BatteryMonthChartDataProvider(t);
          break;
        case DateRangeZoomLevel.YEAR:
          provider = new BatteryYearChartDataProvider(system);
          break;
        default:
          break;
      }
      break;

    case SystemCategory.GRID:
      switch (zoomLevel) {
        case DateRangeZoomLevel.DAY:
          provider = new GridDayChartDataProvider(system);
          break;
        case DateRangeZoomLevel.WEEK:
          provider = new GridWeekChartDataProvider(system);
          break;
        case DateRangeZoomLevel.MONTH:
          provider = new GridMonthChartDataProvider(t);
          break;
        case DateRangeZoomLevel.YEAR:
          provider = new GridYearChartDataProvider(system);
          break;
        default:
          break;
      }
      break;

    case SystemCategory.HOME:
      switch (zoomLevel) {
        case DateRangeZoomLevel.DAY:
          provider = new HomeDayChartDataProvider(system);
          break;
        case DateRangeZoomLevel.WEEK:
          provider = new HomeWeekChartDataProvider(system);
          break;
        case DateRangeZoomLevel.MONTH:
          provider = new HomeMonthChartDataProvider(t);
          break;
        case DateRangeZoomLevel.YEAR:
          provider = new HomeYearChartDataProvider(system);
          break;
        default:
          break;
      }
      break;

    default:
      break;
  }
  return provider;
};

export default getDataProvider;
