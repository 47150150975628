import styled from 'styled-components';

import Text from 'shared/components/Text/Text';

export const MessageDate = styled(Text)`
  ${({ theme }) => `
  margin-left: 24px;
  color: ${theme.colors.textSecondarySubtleGrey80};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
  `}
`;

export default MessageDate;
