import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import { Modal } from '../Modal/Modal';
import { BodyText } from './ExpiringSessionModal.styles';

const WARNING_TIME_MILLISECONDS = 28 * 60 * 1000; // 28 minutes
const SIGN_OUT_TIME_MILLISECONDS = WARNING_TIME_MILLISECONDS + 2 * 60 * 1000; // 2 minutes

type Props = {
  isOpen?: boolean; // only used for storybook
  showUnableToSave?: boolean; // only used for storybook
};

export function ExpiringSessionModal({
  isOpen: isOpenPropForStorybook = false,
  showUnableToSave: showUnableToSaveForStorybook = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(isOpenPropForStorybook);
  const [showUnableToSave, setShowUnableToSave] = useState(showUnableToSaveForStorybook);

  // NIST provides a few guidelines for when to expire inactive user sessions.
  // 30 minutes is a good threshold.

  const { t } = useTranslation();
  const warningTimeoutRef = useRef(0);
  const signOutTimeoutRef = useRef(0);

  const { logout, billingForm, clearUserState } = useUser();

  const warnExpiring = () => {
    setIsOpen(true);
  };

  const handleLogout = useCallback(
    (keepBillingFormState: boolean = false) => {
      logout({ keepBillingFormState: billingForm.isEditing && !keepBillingFormState });

      if (billingForm.isEditing) {
        setShowUnableToSave(true);
      }
    },
    [billingForm.isEditing, logout],
  );

  const setTimeouts = useCallback(() => {
    warningTimeoutRef.current = window.setTimeout(warnExpiring, WARNING_TIME_MILLISECONDS);
    signOutTimeoutRef.current = window.setTimeout(handleLogout, SIGN_OUT_TIME_MILLISECONDS);
  }, [handleLogout]);

  const clearTimeouts = () => {
    clearTimeout(warningTimeoutRef.current);
    clearTimeout(signOutTimeoutRef.current);
  };

  useEffect(() => {
    const resetTimeouts = () => {
      clearTimeouts();
      setTimeouts();
    };

    setTimeouts();
    window.addEventListener('click', resetTimeouts);
    window.addEventListener('scroll', resetTimeouts);
    window.addEventListener('keydown', resetTimeouts);

    return () => {
      window.removeEventListener('click', resetTimeouts);
      window.removeEventListener('scroll', resetTimeouts);
      window.removeEventListener('keydown', resetTimeouts);
      clearTimeouts();
    };
  }, [setTimeouts]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        showUnableToSave ? t('expiringSession.unableToSave') : t('expiringSession.inactiveSession')
      }
      contentLabel={`${showUnableToSave ? t('expiringSession.unableToSave') : t('expiringSession.inactiveSession')} Modal`}
      shouldCloseOnOverlayClick={false}
      showCloseButton={!showUnableToSave}
      shouldCloseOnEsc={false}
      isFullWidth
      styleVariant="tertiary"
      onRequestClose={() => setIsOpen(false)}
      ctaButtons={{
        tertiary: showUnableToSave
          ? undefined
          : {
              label: t('expiringSession.logOut'),
              onClick: () => handleLogout(false),
            },
        primary: showUnableToSave
          ? {
              label: t('expiringSession.logOut'),
              onClick: clearUserState,
            }
          : {
              label: t('expiringSession.imStillHere'),
              onClick: () => setIsOpen(false),
            },
      }}
    >
      <BodyText as="p">
        {showUnableToSave
          ? t('expiringSession.signInAgain')
          : t('expiringSession.areYouStillThere')}{' '}
      </BodyText>
    </Modal>
  );
}

export default ExpiringSessionModal;
