import React from 'react';
import { useTheme } from 'styled-components';

import ArrowLeftIcon from 'shared/components/icons/ArrowLeftIcon';

import { CloseIcon } from 'shared/components/icons/CloseIcon';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Button/Button';
import { HeaderStyled, HeaderText } from './MessageCenterHeader.styles';

import { ModalBodyType } from '../types';

type HeaderProps = {
  modalType: ModalBodyType;
  handleClose: () => void;
  handleBackClick?: () => void;
  feedScrolling: boolean;
};

export function MessageCenterHeader({
  modalType,
  handleClose,
  handleBackClick,
  feedScrolling,
}: HeaderProps) {
  const theme = useTheme();
  const closeIcon = () => (
    <CloseIcon size="large" color={theme.colors.iconIconPrimarySecondary1100} />
  );
  const arrowLeftIcon = () => (
    <ArrowLeftIcon color={theme.colors.backgroundSystemCtaSecondary2100} height={16} width={16} />
  );
  const { t } = useTranslation();

  if (modalType === ModalBodyType.DETAILS) {
    return (
      <HeaderStyled $modalType={modalType} $feedScrolling={feedScrolling}>
        <Button
          styleVariant="tertiary"
          label="Back"
          Icon={arrowLeftIcon}
          onClick={handleBackClick}
          iconCenterAlign
        />
        <Button styleVariant="tertiary" Icon={closeIcon} onClick={handleClose} iconRight />
      </HeaderStyled>
    );
  }
  return (
    <HeaderStyled $modalType={modalType} $feedScrolling={feedScrolling}>
      <Button styleVariant="tertiary" Icon={closeIcon} onClick={handleClose} iconRight />
      <HeaderText as="h2">{t('inbox.header')}</HeaderText>
    </HeaderStyled>
  );
}
export default MessageCenterHeader;
